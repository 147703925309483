import { createContext, useContext, useMemo } from 'react';
import { IndexedDBCache, NOOP_CACHE, type IRefDataCache } from './RefDataCache';

export interface RefDataCacheContextProps {
  cache: IRefDataCache;
  isEnabled: boolean;
}

export const RefDataCacheContext = createContext<RefDataCacheContextProps>({
  cache: new IndexedDBCache(),
  isEnabled: false,
});
RefDataCacheContext.displayName = 'RefDataCacheContext';

export function useRefDataCache() {
  const context = useContext(RefDataCacheContext);
  if (context === undefined) {
    throw new Error('Missing RefDataCache.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
}

// Version is assumed by convention to be a string integer but it doesn't really matter.
export const RefDataCacheProvider = function RefDataCacheProvider({
  enableCaching,
  version,
  ...props
}: React.PropsWithChildren<{
  enableCaching: boolean;
  version: string;
}>) {
  const cache = useMemo(() => {
    if (!enableCaching) {
      return NOOP_CACHE;
    }
    const rdc = new IndexedDBCache();
    try {
      rdc.init(version);
      return rdc;
    } catch (e) {
      // RefDataCache might fail to initialize if the control is blocked by a browser. If this happens we want to fall
      // back to a no-op cache. We are not logging here as this is not actually a bug.
      return NOOP_CACHE;
    }
  }, [enableCaching, version]);

  return (
    <RefDataCacheContext.Provider value={{ cache, isEnabled: enableCaching }}>
      {props.children}
    </RefDataCacheContext.Provider>
  );
};
