import {
  Checkmark,
  Crossmark,
  CustomerMarketAccountSelector,
  EMPTY_ARRAY,
  InlineFormattedNumber,
  MixpanelEvent,
  NotificationVariants,
  OrdStatusEnum,
  OrderFormSides,
  QuoteStatusEnum,
  RFQForm,
  Side,
  SideEnum,
  Spinner,
  SpinnerContainer,
  Toasts,
  useBalancesByMarketAccountCurrency,
  useMixpanel,
  useObservable,
  useObservableValue,
  useSecurity,
  useToasts,
  useWLCustomerMarketAccountContext,
  useWLHomeCurrency,
  useWLOrdersProvider,
  useWLOrgConfigContext,
  useWLSymbol,
  type CustomerMarketAccount,
  type CustomerOrder,
  type CustomerQuote,
  type FormState,
  type FormTouchedState,
} from '@talos/kyoko';
import { findLast } from 'lodash';
import type React from 'react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { map } from 'rxjs/operators';
import { RFQ_FORM, useOMSStateContext, useOMSUtilsContext, useQuotesProvider } from '../../../providers';
import { BalanceDetails } from '../BalanceDetails/BalanceDetails';
import { OrderSummary } from '../OrderSummary/OrderSummary';
import { useFormValidation } from '../useFormValidation';
import { FeedbackMessage, OrderFormsWrapper, Overlay, ToastWrapper } from './styles';

const SENDING = 'sending';
const REQUESTING = 'requesting';
const REJECTED = 'rejected';
const CONFIRMED = 'confirmed';
const QUOTE_REJECTED = 'quote rejected';
type LoadingStates =
  | typeof CONFIRMED
  | typeof SENDING
  | typeof REJECTED
  | typeof REQUESTING
  | typeof QUOTE_REJECTED
  | undefined;

export const RFQOrderForm: React.FC = () => {
  const [touched, setTouched] = useState<FormTouchedState>({});
  const [loadingState, setLoadingState] = useState<LoadingStates>();
  const [clOrdID, setClOrdID] = useState<string>();
  const [quoteReqID, setQuoteReqID] = useState<string>();
  const { orderForm } = useOMSStateContext();
  const { symbol, setSymbol } = useWLSymbol();
  const { setOrderForm, clearOrderForm, setRFQView, setSymbol: setFormSymbol } = useOMSUtilsContext();
  const { acceptQuote, cancelQuote, requestQuote, openQuotes } = useQuotesProvider();
  const { recentOrdersSub: ordersSub } = useWLOrdersProvider();
  const { homeCurrency } = useWLHomeCurrency();
  const security = useSecurity(symbol);
  // can use EMPTY_ARRAY here since RFQ does not have any strategies.
  const errors = useFormValidation(RFQ_FORM, orderForm, EMPTY_ARRAY, security);
  const mixpanel = useMixpanel();
  const { toasts, add: addToast, remove: removeToast } = useToasts();
  const { customerMarketAccountsList } = useWLCustomerMarketAccountContext();
  const { config } = useWLOrgConfigContext();
  const tIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    setTouched({});
  }, [security]);

  const handleFormClear = useCallback(() => {
    setLoadingState(undefined);
    setClOrdID(undefined);
    setQuoteReqID(undefined);
    setTouched({});
    clearOrderForm();
  }, [clearOrderForm]);

  const orders = useObservableValue<CustomerOrder[]>(() => ordersSub.pipe(map(json => json.data)), [ordersSub]);
  /**
   * Our incoming messages on this channel will sometimes send multiple records at once,
   * e.g. if it both confirms/rejects an order at the same time for insufficient balance.
   * Rather than displaying a "Trade Confirmed" immediately followed by a "Trade Rejected"
   * message, we should find the last record in the incoming message, with the most up to
   * date status for the current order, and only display that as the update to the end user.
   */
  const activeOrder =
    clOrdID != null ? findLast(orders, (order: CustomerOrder) => order.ClOrdID === clOrdID) : undefined;
  /**
   * Pulling out OrdStatus here, because activeOrder doesn't seem to be changing identity
   * across OrdStatus updates, so the below useEffect doesn't necessarily run an extra time
   * if, say, the initial order record comes through with PendingNew status, and then in a
   * separate socket message, gets an update to Rejected, which means the user never gets
   * an updated message. Adding OrdStatus to the list of hook dependencies fixes this, and
   * we also now need a way to track and clear the timeoutId from the CONFIRMED branch to
   * the REJECTED branch, to prevent bad timings from immediately clearing the status after
   * it may have been toggled to REJECTED after having previously been set to CONFIRMED.
   */
  const { OrdStatus } = activeOrder ?? {};
  useEffect(() => {
    if (activeOrder != null) {
      if (tIdRef.current) {
        clearTimeout(tIdRef.current);
        tIdRef.current = null;
      }
      if (OrdStatus === OrdStatusEnum.Rejected) {
        addToast({
          variant: NotificationVariants.Negative,
          text: activeOrder.Text,
          timeout: 5000,
          dismissable: true,
        });
        setLoadingState(REJECTED);
        tIdRef.current = setTimeout(() => {
          setLoadingState(undefined);
        }, 1500);
      } else {
        setLoadingState(CONFIRMED);
        tIdRef.current = setTimeout(() => {
          setClOrdID(undefined);
          setQuoteReqID(undefined);
          setLoadingState(undefined);
          handleFormClear();
        }, 1500);
        if (OrdStatus !== OrdStatusEnum.PendingNew) {
          const action = activeOrder.Side === SideEnum.Buy ? 'Bought' : 'Sold';
          const qtyIncrement =
            activeOrder.Currency === security?.BaseCurrency
              ? security?.DefaultSizeIncrement
              : security?.DefaultPriceIncrement;
          addToast({
            variant: NotificationVariants.Positive,
            text: (
              <div>
                <Side side={activeOrder.Side}>{action}</Side>{' '}
                <InlineFormattedNumber
                  number={activeOrder.CumQty}
                  currency={activeOrder.Currency}
                  increment={qtyIncrement}
                />{' '}
                at{' '}
                <InlineFormattedNumber
                  number={activeOrder.Price}
                  currency={security?.QuoteCurrency}
                  increment={security?.DefaultPriceIncrement}
                />
              </div>
            ),
            timeout: 5000,
            dismissable: true,
          });
          setTouched({});
        }
      }
    }
  }, [clearOrderForm, activeOrder, addToast, OrdStatus, security, handleFormClear]);

  const handleRfqFormSubmit = useCallback(
    ({ Side, OrderQty, Currency, MarketAccount }: FormState) => {
      mixpanel.track(MixpanelEvent.SendRfq);
      const QuoteReqID = requestQuote({ Side, OrderQty, Currency, Symbol: symbol!, MarketAccount });
      setQuoteReqID(QuoteReqID);
      setLoadingState(REQUESTING);
    },
    [symbol, requestQuote, mixpanel]
  );

  const { AllowedSlippage, MarketAccount } = orderForm;

  const handleAcceptQuote = useCallback(
    (quote: CustomerQuote, side: SideEnum) => {
      mixpanel.track(MixpanelEvent.AcceptRfqQuote);
      const clOrdID = acceptQuote({
        quote: AllowedSlippage ? { ...quote, AllowedSlippage } : quote,
        side,
      });
      setClOrdID(clOrdID);
      setLoadingState(SENDING);
    },
    [acceptQuote, AllowedSlippage, mixpanel]
  );

  const quoteObs = useObservable<CustomerQuote | undefined>(
    () => openQuotes.pipe(map(quotes => quotes?.find(q => q.QuoteReqID === quoteReqID))),
    [openQuotes, quoteReqID]
  );
  const quote = useObservableValue<CustomerQuote | undefined>(() => quoteObs, [quoteObs]);

  const handleCancelQuote = useCallback(() => {
    if (quote != null) {
      cancelQuote(quote.RFQID, quoteReqID);
    }
    mixpanel.track(MixpanelEvent.CancelRfq);
    handleFormClear();
  }, [quoteReqID, cancelQuote, handleFormClear, quote, mixpanel]);

  useEffect(() => {
    if (
      loadingState === REQUESTING &&
      quote != null &&
      (quote.QuoteStatus === QuoteStatusEnum.Rejected || quote.QuoteStatus === QuoteStatusEnum.Canceled)
    ) {
      setLoadingState(QUOTE_REJECTED);
      addToast({
        variant: NotificationVariants.Negative,
        text: quote.Text,
        timeout: 10000,
        dismissable: true,
      });
      setQuoteReqID(undefined);
      setTimeout(() => {
        setLoadingState(undefined);
      }, 1500);
      return;
    }
    if (loadingState === REQUESTING && quote?.QuoteStatus === QuoteStatusEnum.Open) {
      setLoadingState(undefined);
    }
    if (quote?.QuoteStatus === QuoteStatusEnum.Canceled) {
      setTimeout(() => {
        setQuoteReqID(undefined);
        setLoadingState(undefined);
      }, 1500);
    }
  }, [quote, loadingState, addToast]);

  const balancesByMarketAccountCurrency = useBalancesByMarketAccountCurrency();
  const marketAccountBalances = useMemo(() => {
    return (MarketAccount && balancesByMarketAccountCurrency?.get(MarketAccount)) || undefined;
  }, [MarketAccount, balancesByMarketAccountCurrency]);

  const handleSymbolChange = useCallback(
    (symbol: string) => {
      mixpanel.track(MixpanelEvent.ChangeSymbol);
      setSymbol(symbol);
      setFormSymbol(symbol);
    },
    [mixpanel, setFormSymbol, setSymbol]
  );

  useEffect(() => {
    // Set initial state
    setRFQView(config.enableTwoWayQuotes ? OrderFormSides.Twoway : OrderFormSides.Buy);
  }, [setRFQView, config.enableTwoWayQuotes]);

  const handleMarketAccountUpdate = useCallback(
    (newMktAcc?: CustomerMarketAccount) => {
      setOrderForm(prev => ({ ...prev, MarketAccount: newMktAcc?.SourceAccountID }));
    },
    [setOrderForm]
  );

  const ready = security != null && customerMarketAccountsList != null;
  const sending = loadingState === SENDING;
  const requesting = loadingState === REQUESTING;
  const rejected = loadingState === REJECTED;
  const quoteRejected = loadingState === QUOTE_REJECTED;
  const confirmed = loadingState === CONFIRMED;

  useEffect(() => {
    // If there is only 1 active Market Account, select it
    // The Customer Market Account Selector will be hidden.
    if (customerMarketAccountsList?.length === 1 && MarketAccount == null) {
      handleMarketAccountUpdate(customerMarketAccountsList[0]);
    }
  }, [MarketAccount, handleMarketAccountUpdate, customerMarketAccountsList]);

  return (
    <OrderFormsWrapper>
      {sending && (
        <Overlay>
          <Spinner />
          <FeedbackMessage>Sending order</FeedbackMessage>
        </Overlay>
      )}
      {confirmed && (
        <Overlay>
          <Checkmark />
          <FeedbackMessage>Order submitted</FeedbackMessage>
        </Overlay>
      )}
      {rejected && (
        <Overlay>
          <Crossmark />
          <FeedbackMessage>Order rejected</FeedbackMessage>
        </Overlay>
      )}
      {quoteRejected && (
        <Overlay>
          <Crossmark />
          <FeedbackMessage>Request rejected</FeedbackMessage>
        </Overlay>
      )}

      {ready ? (
        <RFQForm
          form={orderForm}
          errors={errors}
          requesting={requesting}
          sending={sending}
          onFormChange={setOrderForm}
          onFormClear={handleFormClear}
          onFormSubmit={handleRfqFormSubmit}
          onCancelQuote={handleCancelQuote}
          onAcceptQuote={handleAcceptQuote}
          onSymbolChange={handleSymbolChange}
          homeCurrency={homeCurrency}
          security={security}
          balances={marketAccountBalances}
          quote={quote}
          touched={touched}
          enableCurrencyPairSelector={true}
          showTwowaySpread={config.showSpreadOnRFQs}
          enableTwoWayQuotes={config.enableTwoWayQuotes ?? false}
          useColoredButtonsForTwoWayQuotes={config.useColoredButtonsForTwoWayQuotes}
          setTouched={setTouched}
          marketAccountSelector={
            customerMarketAccountsList.length > 1 ? (
              <CustomerMarketAccountSelector
                accountID={MarketAccount}
                security={security}
                availableCustomerMarketAccounts={customerMarketAccountsList}
                onChangeMarketAccount={handleMarketAccountUpdate}
                error={touched.MarketAccount ? errors?.MarketAccount : undefined}
                disabled={!!quoteReqID}
                touched={!!touched.MarketAccount}
              />
            ) : undefined
          }
          showOrderSlippage={config.allowOrderSlippage}
          balanceDetails={<BalanceDetails accountID={MarketAccount} security={security} />}
          orderSummary={<OrderSummary />}
        />
      ) : (
        <SpinnerContainer>
          <Spinner />
        </SpinnerContainer>
      )}
      <ToastWrapper>
        <Toasts toasts={toasts} remove={removeToast} />
      </ToastWrapper>
    </OrderFormsWrapper>
  );
};
