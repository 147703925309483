import type { CellValueChangedEvent, ColDef, IRowNode, RowDoubleClickedEvent } from 'ag-grid-community';
import type { GridApi, GridOptions } from 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import { cloneDeep, get } from 'lodash';
import { memo, useCallback, useEffect, useMemo, useRef, useState, type MutableRefObject, type ReactNode } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Prompt } from 'react-router-dom';
import { v1 as uuid } from 'uuid';
import { useConstant, useDynamicCallback } from '../../hooks';

import { isGridApiReady } from 'components/BlotterTable/utils';
import {
  AG_GRID_CURRENCY_CELL_RENDERER_KEY,
  AgGridAmountInput,
  AgGridButton,
  AgGridCurrency,
  AgGridDateFilter,
  AgGridFilterPermissionActionHeader,
  AgGridFormattedNumber,
  AgGridHamburgerMenu,
  AgGridIconButton,
  AgGridInput,
  AgGridLoadingOverlay,
  AgGridMeter,
  AgGridMultiSelectDropdown,
  AgGridNoRowsOverlay,
  AgGridPrice,
  AgGridProcessStep,
  AgGridSecurity,
  AgGridSize,
  AgGridToggle,
  AgGridTooltipHeader,
} from '../AgGrid';
import { AgGridSearchSelectDropdown } from '../AgGrid/AgGridSearchSelectDropdown';
import {
  safeGridApi,
  useBlotterTableContext,
  useColumnDefs,
  useGetDefaultContextMenuItems,
  type BlotterDensity,
  type BlotterTableContextProps,
  type Column,
  type ColumnDef,
} from '../BlotterTable';
import type { UseBlotterTable, UseBlotterTableProps } from '../BlotterTable/useBlotterTable/types';
import { AgGridStyleWrapper, GlobalStyle } from './styles';
import { FormRowStatus, type FormRow, type FormRowErrors, type FormRowGridItem } from './types';

/** Ava's Ag-Grid Custom Context plus Extra props for forms */
export type FormBlotterTableContextProps<R = any> = BlotterTableContextProps &
  Pick<UseFormTable<R>, 'getRow' | 'getRows' | 'addRow' | 'reset'>;
const messages = defineMessages({
  unsavedChangesPrompt: {
    defaultMessage: 'You have unsaved changes. Are you sure you want to leave?',
    id: 'FormTable.unsavedChangesPrompt',
  },
});

export { FormRowStatus } from './types';
export type { FormRow } from './types';

const DEFAULT_ROW_HEIGHT = 40;

export const FormTable = memo(function FormTable({
  gridOptions,
  background,
  gridApi,
  promptIfUnsavedChanges = true,
  onRowDoubleClicked,
  onFilterChanged,
  ...formTable
}: {
  gridOptions: GridOptions | null;
  background?: string;
  isDirty: boolean;
  gridApi: GridApi | undefined;
  onRowDoubleClicked?: (params: RowDoubleClickedEvent) => void;
  onFilterChanged(): void;
  promptIfUnsavedChanges?: boolean;
}) {
  const { formatMessage } = useIntl();
  const defaultColDef = useMemo(
    () =>
      ({
        suppressMovable: true,
        resizable: false,
        cellDataType: false,
        tooltipComponent: 'customTooltip',
        tooltipValueGetter: params => {
          if (!isGridApiReady(params.api) || !params.data) {
            return;
          }
          const id = params.api.getColumn(params.colDef as ColDef)?.getColId();
          const isInvalid = !(id == null || params.data.formRow.errors?.[id] == null);
          return isInvalid ? params.data.formRow.errors?.[id] : undefined;
        },
        cellClassRules: {
          invalid: params => {
            if (!isGridApiReady(params.api) || !params.data) {
              return false; // this should not be processed either way
            }
            const id = params.api.getColumn(params.colDef)?.getColId();
            return !(id == null || params.data.formRow.errors?.[id] == null);
          },
          editable: params => {
            if (isGridApiReady(params.api) && typeof params.colDef.editable === 'function') {
              // EditableCallback from https://www.ag-grid.com/react-data-grid/cell-editing/
              const column = params.api.getColumn(params.colDef);
              return column == null ? false : params.colDef.editable({ ...params, column });
            }
            return Boolean(params.colDef.editable);
          },
        },
      } satisfies ColDef<FormRowGridItem<unknown>>),
    []
  );

  const components = useConstant({
    buttonColumn: AgGridButton,
    dateFilter: AgGridDateFilter,
    hamburgerMenuColumn: AgGridHamburgerMenu,
    iconButtonColumn: AgGridIconButton,
    loadingOverlay: AgGridLoadingOverlay,
    noRowsOverlay: AgGridNoRowsOverlay,
    meterColumn: AgGridMeter,
    priceColumn: AgGridPrice,
    formattedNumberColumn: AgGridFormattedNumber,
    processStepColumn: AgGridProcessStep,
    sizeColumn: AgGridSize,
    iconButton: AgGridIconButton,
    input: AgGridInput,
    toggle: AgGridToggle,
    amountInput: AgGridAmountInput,
    searchSelectDropdown: AgGridSearchSelectDropdown,
    multiSelectDropdown: AgGridMultiSelectDropdown,
    [AG_GRID_CURRENCY_CELL_RENDERER_KEY]: AgGridCurrency,
    securityColumn: AgGridSecurity,
    filterPermissionActionHeader: AgGridFilterPermissionActionHeader,
    tooltipHeader: AgGridTooltipHeader,
  });

  const rowClassRules = useMemo(
    () =>
      ({
        ...gridOptions?.rowClassRules,
        [FormRowStatus.Added]: params => params.data?.formRow.status === FormRowStatus.Added,
        [FormRowStatus.Updated]: params => params.data?.formRow.status === FormRowStatus.Updated,
        [FormRowStatus.Removed]: params => params.data?.formRow.status === FormRowStatus.Removed,
      } satisfies GridOptions<FormRowGridItem<unknown>>['rowClassRules']),
    [gridOptions?.rowClassRules]
  );

  const getContextMenuItems = useGetDefaultContextMenuItems();

  if (gridOptions == null) {
    return null;
  }

  return (
    <AgGridStyleWrapper {...formTable} className="ag-theme-balham-dark" background={background}>
      {promptIfUnsavedChanges && (
        <Prompt when={formTable.isDirty} message={formatMessage(messages.unsavedChangesPrompt)} />
      )}
      <GlobalStyle />
      <AgGridReact
        rowHeight={DEFAULT_ROW_HEIGHT}
        stopEditingWhenCellsLoseFocus={true}
        suppressCellFocus={true}
        singleClickEdit={true}
        tooltipShowDelay={0}
        columnMenu="legacy"
        enableBrowserTooltips={true}
        defaultColDef={defaultColDef}
        rowClassRules={rowClassRules}
        noRowsOverlayComponent="noRowsOverlay"
        components={components}
        popupParent={document.body}
        getContextMenuItems={getContextMenuItems}
        onRowDoubleClicked={onRowDoubleClicked}
        {...gridOptions}
      />
    </AgGridStyleWrapper>
  );
});

export type UseFormTableProps<R> = {
  readonly data: R[] | undefined;
  readonly dirtyRowIds?: string[];
  readonly deletedRowIds?: string[];
  readonly columns: ColumnDef<R>[] | Column[];
  readonly rowID: Parameters<typeof get<R>>[1];
  readonly rowHeight?: number;
  readonly domLayout?: 'normal' | 'autoHeight' | 'print';
  onChange?(formRow: FormRow<R>): string | void;
  renderEmpty?: () => ReactNode;
  density?: BlotterDensity;
  clientLocalFilter?: (data: IRowNode<R>) => boolean;
  quickSearchParams?: UseBlotterTableProps<R>['quickSearchParams'];
};

export interface UseFormTable<R> {
  readonly gridOptions: GridOptions | null;
  readonly isTouched: boolean;
  readonly isDirty: boolean;
  readonly gridApi: GridApi | undefined;
  readonly isMounted: boolean;
  readonly density?: BlotterDensity;
  readonly dirtyRows: Set<unknown>;
  addRow(data: R, rowStatus?: FormRowStatus): void;
  reset(newData?: R[], dirtyRowIds?: string[], deletedRowIds?: string[]): void;
  getRow(id: string): FormRow<R> | undefined;
  getRows(): FormRow<R>[];
  onFilterChanged(): void;
  blotterTableFiltersProps: Omit<
    UseBlotterTable<R>['blotterTableFiltersProps'],
    'pause' | 'paused' | 'resume' | 'showPauseButton' // we dont support pausing in the FormTable currently
  >;
}

export function useFormTable<R>({
  data,
  dirtyRowIds,
  deletedRowIds,
  rowID,

  columns,
  rowHeight,
  density,
  domLayout,
  renderEmpty,
  onChange,
  clientLocalFilter,
  quickSearchParams,
}: UseFormTableProps<R>): UseFormTable<R> {
  /** Type that the FormTable transaction processes apply */
  const [gridApi, setGridApi] = useState<GridApi<FormRowGridItem<R>>>();
  const gridReady = isGridApiReady(gridApi);
  const isMounted = gridReady;

  const [isTouched, setTouched] = useState(false);

  const [dirtyRows, setDirtyRows] = useState(new Set());
  const isDirty = dirtyRows.size > 0;

  const onGridReady = useCallback<NonNullable<GridOptions['onGridReady']>>(event => setGridApi(event.api), []);
  const getRowId = useCallback<NonNullable<GridOptions<FormRowGridItem<R>>['getRowId']>>(
    ({ data }) => get(data, rowID) ?? data.formRow.id,
    [rowID]
  );

  const addRow = useCallback(
    (data: R, rowStatus?: FormRowStatus) => {
      if (!gridReady || !isGridApiReady(gridApi)) {
        return;
      }
      const id = uuid();
      const status = rowStatus ?? FormRowStatus.Added;
      gridApi.applyTransaction({
        add: [
          {
            ...data,
            formRow: {
              status,
              id,
            },
          },
        ],
        addIndex: 0,
      });
      setTouched(true);
      if (status !== FormRowStatus.None) {
        setDirtyRows(prev => new Set([...prev, get(data, rowID) ?? id]));
      }
    },
    [gridApi, gridReady, rowID]
  );

  const reset = useDynamicCallback(
    (
      newData: R[] | undefined = data,
      newDirtyRowIds: string[] = dirtyRowIds ?? [],
      newDeletedRowIds: string[] = deletedRowIds ?? []
    ) => {
      const getRowStatus = (row: R) => {
        const id = get(row, rowID);
        if (newDeletedRowIds.includes(id)) {
          return FormRowStatus.Removed;
        }
        if (newDirtyRowIds.includes(id)) {
          return FormRowStatus.Updated;
        }
        return FormRowStatus.None;
      };

      if (gridReady && isGridApiReady(gridApi)) {
        const rowData: FormRowGridItem<R>[] = [];
        if (newData) {
          for (const d of newData) {
            rowData.push({
              ...d,
              formRow: { status: getRowStatus(d) },
            });
          }
        }
        setTimeout(() => {
          gridApi.setGridOption('rowData', rowData);
        }, 0);

        setTouched(newDirtyRowIds.length > 0);
        setDirtyRows(new Set(newDirtyRowIds));
      }
    }
  );

  const getRow: (id: string) => FormRow<R> | undefined = useCallback(
    id => {
      if (!gridReady || !isGridApiReady(gridApi)) {
        return undefined;
      }
      const node = gridApi.getRowNode(id);
      if (node == null || node.data == null) {
        return undefined;
      }

      const { formRow, ...rest } = node.data;
      const data = cloneDeep(rest);
      return {
        data: data as R,
        status: formRow.status,
        errors: formRow.errors,
        setErrors: (errors?: FormRowErrors) => {
          if (!isGridApiReady(gridApi) || !node.data) {
            return;
          }
          const { formRow, ...rest } = node.data;
          node.setData({ ...(rest as R), formRow: { ...formRow, errors } });
        },
        setData: newData => {
          if (!isGridApiReady(gridApi)) {
            return;
          }
          if (formRow.status === FormRowStatus.Added && get(newData, rowID) == null) {
            throw new Error('Newly added row does not include a [rowID] property');
          }
          node.setData({ ...newData, formRow: { ...formRow, status: FormRowStatus.None } });
          setDirtyRows(prev => {
            prev.delete(node.id);
            return new Set(prev);
          });
        },
        remove: (force = false) => {
          if (!isGridApiReady(gridApi) || !node.data) {
            return;
          }

          if (node.data.formRow.status === FormRowStatus.Updated || node.data.formRow.status === FormRowStatus.None) {
            node.setData({ ...node.data, formRow: { status: FormRowStatus.Removed } });
            setDirtyRows(prev => new Set([...prev, node.id]));
            return;
          }

          if (
            node.data.formRow.status === FormRowStatus.Added ||
            node.data.formRow.status === FormRowStatus.Removed ||
            force
          ) {
            gridApi.applyTransaction({ remove: [node.data] });
            setDirtyRows(prev => {
              prev.delete(node.id);
              return new Set(prev);
            });
            return;
          }
        },
      };
    },
    [gridApi, gridReady, rowID]
  );

  const getRows = useCallback(() => {
    const rows: FormRow<R>[] = [];
    if (!gridReady || !isGridApiReady(gridApi)) {
      return rows;
    }
    gridApi.forEachNode(node => {
      if (node.id != null) {
        const row = getRow(node.id);
        if (row != null) {
          rows.push(row);
        }
      }
    });
    return rows;
  }, [getRow, gridApi, gridReady]);

  // Load initial data
  const [didInitialReset, setDidInitialReset] = useState(false);
  useEffect(() => {
    if (!didInitialReset && data != null && gridReady && isGridApiReady(gridApi)) {
      reset();
      setDidInitialReset(true);
    }
  }, [didInitialReset, data, gridReady, reset, gridApi]);

  // Setup context
  const blotterTableContext = useBlotterTableContext();
  const context = useRef<FormBlotterTableContextProps | null>(null);

  useEffect(() => {
    context.current = {
      ...blotterTableContext,
      getRow,
      getRows,
      addRow,
      reset,
    } satisfies FormBlotterTableContextProps;
    setTimeout(() => {
      if (gridReady && isGridApiReady(gridApi)) {
        gridApi.refreshHeader();
        gridApi.refreshCells({ force: true });
      }
    });
  }, [gridReady, blotterTableContext, getRow, getRows, addRow, reset, gridApi]);

  // Create columns
  const columnDefs = useColumnDefs<R>(columns);
  useEffect(() => {
    if (gridReady && isGridApiReady(gridApi)) {
      setTimeout(() => {
        gridApi.setGridOption('columnDefs', columnDefs);
      }, 0);
    }
  }, [columnDefs, gridApi, gridReady]);

  // Add event listeners
  useEffect(() => {
    if (!gridReady || !isGridApiReady(gridApi)) {
      return;
    }
    function handleCellValueChanged({ node }: CellValueChangedEvent) {
      let status = node.data.formRow.status;
      if (node.data.formRow.status === FormRowStatus.None || node.data.formRow.status === FormRowStatus.Removed) {
        status = FormRowStatus.Updated;
      }
      const row: FormRow<R> = { ...node.data, formRow: { ...node.data.formRow, status } };
      node.setData(row);
      onChange?.(getRow(node.id!)!);
      setTouched(true);
      setDirtyRows(prev => new Set([...prev, node.id]));
    }
    safeGridApi(gridApi)?.addEventListener('cellValueChanged', handleCellValueChanged);
    return () => {
      safeGridApi(gridApi)?.removeEventListener('cellValueChanged', handleCellValueChanged);
    };
  }, [gridReady, onChange, getRow, gridApi]);

  const onFilterChanged = useCallback(() => {
    if (!isGridApiReady(gridApi)) {
      return;
    }
    gridApi.onFilterChanged();
  }, [gridApi]);

  useEffect(() => {
    // Whenever clientLocalFilter changes, we tell the blotter that filters have changed
    // onFilterChange called in timeout to allow updates to filter function to get picked up correctly by grid
    const timeout = setTimeout(() => {
      onFilterChanged && onFilterChanged();
    });
    return () => {
      clearTimeout(timeout);
    };
  }, [clientLocalFilter, onFilterChanged]);

  const [quickFilterText, setQuickFilterText] = useState('');
  useEffect(() => {
    if (isGridApiReady(gridApi)) {
      // We allow the implementer to control the filterText if they want. Otherwise, we hold the state.
      gridApi.setGridOption('quickFilterText', quickSearchParams?.filterText ?? quickFilterText);
    }
  }, [gridApi, quickSearchParams?.filterText, quickFilterText]);

  const gridOptions = useMemo(() => {
    return {
      getRowId,
      onGridReady,
      domLayout,
      context: context as MutableRefObject<FormBlotterTableContextProps>,
      rowHeight: rowHeight ?? DEFAULT_ROW_HEIGHT,
      noRowsOverlayComponentParams: { renderEmpty },
      isExternalFilterPresent: () => clientLocalFilter !== undefined,
      doesExternalFilterPass: node => {
        if (node.data == null) {
          return false;
        }
        if (node.data.formRow.status !== FormRowStatus.None) {
          // Allow newly added or modified rows to bypass the external filter
          return true;
        }

        return clientLocalFilter?.(node) ?? true;
      },
      cacheQuickFilter: true,
    } satisfies GridOptions<FormRowGridItem<R>>;
  }, [getRowId, onGridReady, domLayout, rowHeight, renderEmpty, clientLocalFilter]);

  return {
    gridOptions,
    onFilterChanged,
    gridApi,
    isMounted,
    isTouched,
    isDirty,
    density,
    addRow,
    getRow,
    getRows,
    reset,
    dirtyRows,
    blotterTableFiltersProps: {
      quickFilterText,
      onQuickFilterTextChanged: setQuickFilterText,
    },
  };
}
