import { MARKET_ACCOUNT_LEDGER_EVENT, SUB_ACCOUNT_LEDGER_EVENT } from '../tokens';
import { formattedDate, toBig } from '../utils';
import {
  type ExternalTypeEnum,
  type ILedgerEvent,
  type LedgerAccountTypeEnum,
  type LedgerUpdateStatusEnum,
  LedgerUpdateTypeEnum,
} from './types';

export class LedgerEvent {
  static MarketAccountStreamName = MARKET_ACCOUNT_LEDGER_EVENT;
  static SubAccountStreamName = SUB_ACCOUNT_LEDGER_EVENT;

  TransactTime!: string;
  RequestID?: string;

  Type!: LedgerUpdateTypeEnum;
  /** Depending on the Type, AccountName will be either a MarketAccount.Name or a SubAccount.Name */
  AccountName!: string;

  AccountID!: number;
  Asset!: string;
  Amount!: string;
  Price!: string;
  PriceCurrency!: string;
  AvgCost!: string;
  AvgCostCurrency!: string;
  Status!: LedgerUpdateStatusEnum;
  Revision!: number;
  Timestamp!: string;
  TradeID?: string;
  ExternalID?: string;
  ResultingAmount!: string;
  AccountType!: LedgerAccountTypeEnum;
  ExternalType!: ExternalTypeEnum;
  Fee!: string;
  FeeCurrency!: string;
  AmountAsset?: string;
  Comments?: string;

  get amountAssetWithFallback() {
    return this.AmountAsset ?? this.Asset;
  }

  get rowID() {
    return `${this.AccountType}-${this.AccountID}-${this.Asset}-${this.Revision}`;
  }

  get dayString() {
    return formattedDate(this.TransactTime, '{yyyy}-{MM}-{dd}');
  }

  get hourString() {
    return formattedDate(this.TransactTime, '{yyyy}-{MM}-{dd} {HH}:{mm}');
  }

  /** What the amount was before this LedgerEvent */
  get startingAmount() {
    const amountBig = toBig(this.Amount);
    const resultingAmountBig = toBig(this.ResultingAmount);
    if (amountBig == null || resultingAmountBig == null) {
      return undefined;
    }

    return resultingAmountBig.minus(amountBig).toFixed();
  }

  constructor(data: ILedgerEvent | LedgerEvent) {
    Object.assign(this, data);
  }
}

const LEDGER_UPDATE_TYPE_TEXT_MAPPING: { [key in LedgerUpdateTypeEnum]: string } = {
  [LedgerUpdateTypeEnum.Balance]: 'Balance',
  [LedgerUpdateTypeEnum.Trade]: 'Trade',
  [LedgerUpdateTypeEnum.TradingFee]: 'Trading Fee',
  [LedgerUpdateTypeEnum.TradingFeeSet]: 'Trading Fee Set',
  [LedgerUpdateTypeEnum.BalanceDelta]: 'Balance Delta',
  [LedgerUpdateTypeEnum.TradeCancel]: 'Trade Cancel',
  [LedgerUpdateTypeEnum.TradeAmend]: 'Trade Amend',
  [LedgerUpdateTypeEnum.Allocation]: 'Allocation',
  [LedgerUpdateTypeEnum.Unsettled]: 'Unsettled',
  [LedgerUpdateTypeEnum.UnsettledDelta]: 'Unsettled Delta',
  [LedgerUpdateTypeEnum.FundingPnL]: 'Funding PnL',
  [LedgerUpdateTypeEnum.FundingPnLDelta]: 'Funding PnL Delta',
  [LedgerUpdateTypeEnum.RealizedPnL]: 'Realized PnL',
  [LedgerUpdateTypeEnum.RealizedPnLDelta]: 'Realized PnL Delta',
  [LedgerUpdateTypeEnum.ReconAdjustment]: 'Recon Adjustment',
  [LedgerUpdateTypeEnum.Deposit]: 'Deposit',
  [LedgerUpdateTypeEnum.Withdrawal]: 'Withdrawal',
  [LedgerUpdateTypeEnum.TransferFee]: 'Transfer Fee',
  [LedgerUpdateTypeEnum.Delivery]: 'Delivery',
};

const ledgerUpdateTypeEnums = Object.values<string>(LedgerUpdateTypeEnum);

export function isLedgerUpdateTypeEnum(value: string): value is LedgerUpdateTypeEnum {
  return ledgerUpdateTypeEnums.includes(value);
}

export function prettifyLedgerUpdateTypeEnum(type: LedgerUpdateTypeEnum | string): string {
  // we allow the user to pass in a string here just for simplicity. If it happens to not be a ledger update type, just return the passed string as-is
  if (isLedgerUpdateTypeEnum(type)) {
    return LEDGER_UPDATE_TYPE_TEXT_MAPPING[type];
  }

  return type;
}
