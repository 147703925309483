import { useMemo } from 'react';
import { getAgGridColId } from '../components/BlotterTable/columns/getAgGridColId';
import type { Column, ColumnDef } from '../components/BlotterTable/columns/types';
import { useDefaultColumns } from '../components/BlotterTable/useDefaultColumns';
import type { ExpectTrue } from '../tests';
import { EMPTY_ARRAY } from '../utils/empty';
import type { PricingParameters } from './CustomerOrder';
import type { CustomerDealSummary } from './CustomerOrderSummary';
import type {
  CustomerExecutionStrategyEnum,
  CustomerWorkflowEnum,
  CxlRejReasonEnum,
  DecisionStatusEnum,
  ExecTypeEnum,
  ICustomerExecutionReport,
  OrdRejReasonEnum,
  OrdStatusEnum,
  OrdTypeEnum,
  OrderAdminActionEnum,
  PricingModeEnum,
  SideEnum,
} from './types';

// ICustomerExecutionReport / CustomerExecutionReport4224
export class CustomerExecutionReport {
  static readonly rowID = 'ExecID';
  static readonly defaultColumns = [
    'SubmitTime',
    'Side',
    'Symbol',
    'OrderQty',
    'CumQty',
    'LeavesQty',
    'Price',
    'AvgPx',
    'CumFee',
    'Strategy',
    'SubAccount', // TODO this will be problematic with allocations
    'Group',
    'User',
    'OrdStatus',
    'ExecID',
    'OrderID',
    'ClOrdID',
  ] satisfies (keyof CustomerExecutionReport | Column)[];

  Timestamp!: string;

  MessageID?: string;

  User?: string;

  Symbol!: string;

  OrderID!: string;

  ClOrdID?: string;

  Counterparty?: string;

  OrigClOrdID?: string;

  SubmitTime!: string;

  ExecID!: string;

  Side?: SideEnum;

  TransactTime?: string;

  ExecType!: ExecTypeEnum;

  MarketAccount!: string;

  OrdStatus!: OrdStatusEnum;

  OrdType?: OrdTypeEnum;

  OrderQty?: string;

  Price?: string;

  Currency!: string;

  LeavesQty?: string;

  CumQty?: string;

  AvgPx?: string;

  AvgPxAllIn?: string;

  TimeInForce?: string;

  LastPx?: string;

  LastQty?: string;

  LastAmt?: string;

  LastFee?: string;

  LastFeeCurrency?: string;

  CumAmt?: string;

  CumFee?: string;

  FeeCurrency?: string;

  OrdRejReason?: OrdRejReasonEnum;

  CxlRejReason?: CxlRejReasonEnum;

  DecisionStatus?: DecisionStatusEnum;

  PricingParameters?: PricingParameters;

  QuoteID?: string;

  AmountCurrency?: string;

  SessionID?: string;

  CancelSessionID?: string;

  ExpectedFillPrice?: string;

  ExpectedFillQty?: string;

  SubAccount?: string;

  Strategy?: string;

  RFQID?: string;

  AllowedSlippage?: string;

  Text?: string;

  Group?: string;

  Parameters?: { [key: string]: string };

  summary?: CustomerDealSummary;

  CustomerUser?: string;

  Workflow?: CustomerWorkflowEnum;

  PricingMode?: PricingModeEnum;
  PricingReference?: string | undefined;

  InternalOrdRejReason?: string;
  AdminFillPrice?: string;
  DealerAction?: OrderAdminActionEnum;
  InternalText?: string;
  Comments?: string;
  FixingDetails?: {
    Index?: string;
    Fixing?: string;
  };
  ExecutionStrategy?: CustomerExecutionStrategyEnum;
  constructor(data: CustomerExecutionReport) {
    Object.assign(this, data);
  }
}

interface UseCustomerExecutionReportColumns {
  defaultColumns?: (keyof CustomerExecutionReport | Partial<Column>)[];
}

export function useCustomerExecutionReportColumns({
  defaultColumns = EMPTY_ARRAY,
}: UseCustomerExecutionReportColumns): Column[] {
  const defaultVisibleColumns = useMemo(
    () =>
      new Map(
        (
          [
            { field: 'Timestamp', type: 'date', sort: '-' },
            { field: 'ExecType', type: 'text' },
            { field: 'ExecID', type: 'id' },
            { field: 'Counterparty', title: 'Customer', type: 'text' },
            { field: 'Symbol', type: 'security' },
            { field: 'OrderQty', type: 'size', params: { currencyField: 'Currency' } },
            { field: 'Price', type: 'price', params: { assetField: 'Symbol' } },
            { field: 'OrderID', type: 'id' },
            { field: 'OrdStatus', type: 'orderStatus' },
          ] satisfies ColumnDef<CustomerExecutionReport>[]
        ).map(c => [getAgGridColId(c), c])
      ),
    []
  );
  const defaultHiddenColumns = useMemo(() => {
    return new Map(
      (
        [
          { field: 'AdminFillPrice', type: 'price', params: { assetField: 'Symbol' } },
          { field: 'AllowedSlippage', type: 'text' },
          { field: 'AvgPx', type: 'price', params: { assetField: 'Symbol' } },
          { field: 'CancelSessionID', type: 'text' },
          { field: 'ClOrdID', type: 'id' },
          { field: 'Comments', type: 'text' },
          { field: 'CumAmt', type: 'size', params: { currencyField: 'AmountCurrency' } },
          { field: 'CumFee', type: 'size', params: { currencyField: 'FeeCurrency' } },
          { field: 'CumQty', type: 'size', params: { currencyField: 'Currency' } },
          { field: 'MarketAccount', title: 'Customer Market Account', type: 'text' },
          { field: 'CustomerUser', type: 'user' },
          { field: 'CxlRejReason', type: 'text' },
          { field: 'DealerAction', type: 'text' },
          { field: 'DecisionStatus', type: 'text' },
          {
            field: 'ExpectedFillPrice',
            type: 'price',
            params: { assetField: 'Symbol' },
          },
          { field: 'ExpectedFillQty', type: 'size', params: { currencyField: 'Currency' } },
          { field: 'Group', type: 'text' },
          { field: 'InternalOrdRejReason', type: 'text' },
          { field: 'InternalText', type: 'text' },
          { field: 'LastAmt', type: 'size', params: { currencyField: 'AmountCurrency' } },
          { field: 'LastFee', type: 'size', params: { currencyField: 'LastFeeCurrency' } },
          { field: 'LastPx', type: 'price', params: { assetField: 'Symbol' } },
          { field: 'LastQty', type: 'size', params: { currencyField: 'Currency' } },
          { field: 'LeavesQty', type: 'size', params: { currencyField: 'Currency' } },
          { field: 'MessageID', type: 'text' },
          { field: 'OrdRejReason', type: 'text' },
          { field: 'OrdType', type: 'text' },
          { field: 'OrigClOrdID', type: 'id' },
          { field: 'Parameters', type: 'text' },
          { field: 'PricingParameters', type: 'text' },
          { field: 'QuoteID', type: 'id' },
          { field: 'RFQID', type: 'id' },
          { field: 'SessionID', type: 'text' },
          { field: 'Side', type: 'side' },
          { field: 'Strategy', type: 'strategy' },
          { field: 'SubAccount', type: 'text' },
          { field: 'SubmitTime', type: 'date' },
          { field: 'Text', type: 'text' },
          { field: 'TimeInForce', type: 'text' },
          { field: 'TransactTime', type: 'date' },
          { field: 'User', type: 'user' },
        ] satisfies (false | ColumnDef<CustomerExecutionReport>)[]
      ).map(c => [getAgGridColId(c), { ...c, hide: true }])
    );
  }, []);

  const columnDefinitions = useMemo(() => {
    return new Map(
      (
        [
          ...defaultVisibleColumns.values(),
          ...defaultHiddenColumns.values(),
        ] satisfies ColumnDef<CustomerExecutionReport>[] as Column[]
      ).map(c => [getAgGridColId(c), c])
    );
  }, [defaultVisibleColumns, defaultHiddenColumns]);
  return useDefaultColumns(defaultColumns, columnDefinitions);
}

// TYPE LEVEL TESTS
type _Expect_CustomerExecutionReport_To_Only_Have_Keys_From_ICustomerExecutionReport = ExpectTrue<
  {
    [K in keyof CustomerExecutionReport & string]: K extends keyof ICustomerExecutionReport ? true : K;
  }[Exclude<keyof CustomerExecutionReport, 'summary'>]
>;
type _Expect_All_Keys_In_ICustomerExecutionReport_To_Be_In_CustomerExecutionReport = ExpectTrue<
  {
    [K in keyof ICustomerExecutionReport & string]: K extends keyof CustomerExecutionReport ? true : K;
  }[Exclude<keyof ICustomerExecutionReport, never>]
>;
