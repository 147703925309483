import type { Mixpanel } from 'mixpanel-browser';
import { createContext, useContext, useMemo } from 'react';
import { MixpanelEventProperty, type MixpanelEvent, type MixpanelEventSource } from '../tokens';
import { MixpanelSourceContext } from './MixpanelSourceContext';

function NOOP(funcName: string, ...args: unknown[]) {
  if (import.meta.env.VITE_AVA_ENV === 'local') {
    // eslint-disable-next-line no-console
    console.debug('Mixpanel', funcName, ...args);
  }
}

export const GET_MIXPANEL_MOCK = (source?: string): MixpanelInstance => {
  return {
    set_group: function (...args: unknown[]) {
      NOOP('set_group', ...args);
    },

    add_group: function (...args: unknown[]) {
      NOOP('add_group', ...args);
    },

    remove_group: function (...args: unknown[]) {
      NOOP('remove_group', ...args);
    },
    identify: function (...args: unknown[]) {
      NOOP('identify', ...args);
    },
    register: function (...args: unknown[]) {
      NOOP('register', ...args);
    },

    time_event: function (...args: unknown[]) {
      NOOP('time_event', ...args);
    },
    track: function (eventName: string, properties?: Record<string, unknown>, ...args: unknown[]) {
      if (!properties) {
        properties = {};
      }
      if (source && !properties[MixpanelEventProperty.Source]) {
        properties[MixpanelEventProperty.Source] = source;
      }

      NOOP('track', eventName, properties, ...args);
    },
  };
};
export interface MixpanelInstance
  extends Pick<Mixpanel, 'set_group' | 'add_group' | 'remove_group' | 'identify' | 'register' | 'time_event'> {
  track: TrackWithTypedDict;
}

type TypedDict = { [K in MixpanelEventProperty]?: unknown };

type TrackWithTypedDict = Mixpanel['track'] extends (
  eventName: infer E,
  properties?: infer P,
  ...rest: infer Rest
) => infer R
  ? (eventName: MixpanelEvent, properties?: TypedDict, ...rest: Rest) => R
  : never;

export const MixpanelContext = createContext<(source?: MixpanelEventSource) => MixpanelInstance>(GET_MIXPANEL_MOCK);
MixpanelContext.displayName = 'MixpanelContext';

export const useMixpanel = (customSource?: MixpanelEventSource) => {
  const getMixpanelInstance = useContext(MixpanelContext);
  const sourceContext = useContext(MixpanelSourceContext);

  const mixpanel = useMemo(
    () => getMixpanelInstance(customSource ?? sourceContext),
    [customSource, sourceContext, getMixpanelInstance]
  );

  if (mixpanel === undefined) {
    throw new Error('Missing MixpanelContext.Provider further up in the tree. Did you forget to add it?');
  }
  return mixpanel;
};
