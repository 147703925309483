import { InputsAndDropdownsDrawer } from '../Drawer/InputsAndDropdownsDrawer';
import { ModifyJsonDrawer } from '../Drawer/ModifyJsonDrawer';
import type { EntityAdminPageProps } from './EntityAdminPage';
import type { EntityPageRecord } from './types';

export enum GenericDrawerTypeEnum {
  JSON = 'JSON',
  InputsAndDropdowns = 'InputsAndDropdowns',
}

type EntityAdminDrawerProps<TRecord extends EntityPageRecord, TDrawerRecord extends TRecord> = Pick<
  EntityAdminPageProps<TRecord, TDrawerRecord>,
  | 'drawerOptions'
  | 'selectedEntity'
  | 'handleOnSaveEntity'
  | 'handleOnDeleteEntity'
  | 'allowAddEntity'
  | 'allowEditEntity'
  | 'allowDeleteEntity'
  | 'addingChildEntity'
  | 'drawerType'
>;

export const EntityAdminDrawer = <TRecord extends EntityPageRecord, TDrawerRecord extends TRecord>({
  drawerOptions,
  selectedEntity,
  handleOnSaveEntity,
  handleOnDeleteEntity,
  allowAddEntity,
  allowEditEntity,
  allowDeleteEntity,
  addingChildEntity,
  drawerType,
}: EntityAdminDrawerProps<TRecord, TDrawerRecord>) => {
  if (!allowAddEntity && !allowEditEntity) {
    // No permissions to add or edit entities using the drawer
    return null;
  }

  return (
    <>
      {drawerType === GenericDrawerTypeEnum.JSON && (
        <ModifyJsonDrawer
          selectedEntity={selectedEntity}
          onSave={handleOnSaveEntity}
          allowDeleteEntity={allowDeleteEntity}
          onDelete={handleOnDeleteEntity}
        />
      )}
      {drawerType === GenericDrawerTypeEnum.InputsAndDropdowns && drawerOptions != null && (
        <InputsAndDropdownsDrawer
          selectedEntity={selectedEntity}
          drawerOptions={drawerOptions}
          onSave={handleOnSaveEntity}
          allowDeleteEntity={allowDeleteEntity}
          onDelete={handleOnDeleteEntity}
          isEditing={selectedEntity != null && !addingChildEntity}
        />
      )}
    </>
  );
};
