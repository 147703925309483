import { useMemo } from 'react';
import { defineMessages } from 'react-intl';
import { useSecuritiesContext } from '../../../../contexts';
import { useIntl } from '../../../../hooks';
import { IconName } from '../../../Icons';
import type { FilterableProperty } from '../types';

const messages = defineMessages({
  symbol: {
    defaultMessage: 'Symbol',
    id: 'Filters.FilterBuilder.filters.symbol',
  },
});

/**
 * This hook creates the filter property definition for Symbols properties.
 *
 * @returns Filter Property definition to use for Symbols filters
 * @param availableSecurities - List of filtered securities for the filter. If not provided, it will use all the securities from the Securities Context.
 */

export function useSymbolsFilter(availableSecurities?: { Symbol: string; DisplaySymbol?: string }[]) {
  const { formatMessage } = useIntl();
  const { securitiesList: _securitiesList } = useSecuritiesContext();
  const securitiesList = availableSecurities ?? _securitiesList;
  const securitiesBySymbol = useMemo(() => new Map(securitiesList.map(sec => [sec.Symbol, sec])), [securitiesList]);
  return useMemo(
    () =>
      ({
        key: 'Symbols',
        field: 'Symbol',
        label: formatMessage(messages.symbol),
        icon: IconName.CurrencyDollar,
        options: securitiesList.map(sec => sec.Symbol),
        getOptionLabel: (option: string) => securitiesBySymbol.get(option)?.DisplaySymbol || option,
      } as const satisfies FilterableProperty),
    [formatMessage, securitiesList, securitiesBySymbol]
  );
}
