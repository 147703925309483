import type { AnyStyledComponent, StyledComponent, StyledComponentProps } from 'styled-components';
export type Primitive = null | undefined | boolean | number | string | symbol | bigint;

export type PropType<T, Path extends string> = string extends Path
  ? unknown
  : Path extends keyof T
  ? T[Path]
  : Path extends `${infer Prop}.${infer Rest}`
  ? Prop extends keyof T
    ? PropType<T[Prop], Rest>
    : unknown
  : unknown;

/**
 * Cleanup a type definition to show the properties in that type rather than an intersection of types.
 *
 * WARNING: Perhaps use sparingly, this may slightly slowdown typechecking.
 *
 * @example
 * // Hover-over / auto-completions / etc. will show this type as `{ Prop1: number } & { Prop2: string } & { Prop3: any }`
 * type Test = { Prop1: number } & { Prop2: string } & { Prop3: any };
 *
 * // But hover-over / auto-completions / etc. will show this type as `{ Prop1: number; Prop2: string; Prop3: any }`
 * type Test2 = ReMap<Test>;
 *
 * @param TSource - The source type to remap
 * @param Recursive - If true, will recursively re-map the type (default is false)
 */
// eslint-disable-next-line @typescript-eslint/ban-types
export type ReMap<TSource, Recursive = false> = {} & {
  [K in keyof TSource]: true extends Recursive
    ? // Recursive branch ...
      TSource[K] extends object
      ? ReMap<TSource[K]>
      : TSource[K] extends any[] | undefined
      ? ReMap<Exclude<TSource[K], undefined>[number]>
      : TSource[K]
    : // Non-recursive branch
      TSource[K];
};

/**
 * Defines a new type with the same properties, but all properties have a new property type
 *
 * @template T The source type
 * @template V New property type for all properties
 */
export type MapTo<T, V> = { [K in keyof T]: V };

/**
 * A type that is potentially null or undefined
 * @template T The source type
 */
export type Nullish<T> = T | null | undefined;

/**
 * Defines a new type with the same properties, but all properties names begin with a lowercase letter.
 *
 * @example
 * ```
 * CamelCased<{OrderID: string, CustId: number}> === {orderID: string, custId: number};
 * ```
 */
export type CamelCased<T, recursive extends true | false = true> = {
  [K in keyof T as `${Uncapitalize<K & string>}`]: T[K] extends any[] | undefined
    ? recursive extends true
      ? CamelCased<Exclude<T[K], undefined>[number]>[]
      : Exclude<T[K], undefined>[number][]
    : T[K] extends Primitive
    ? T[K]
    : recursive extends true
    ? CamelCased<T[K]>
    : T[K];
};

/**
 * Defines a new type with the same properties, but all properties names begin with an uppercase letter.
 *
 * @example
 * ```
 * UpperCased<{orderID: string, custId: number}> === {OrderID: string, CustId: number};
 * ```
 */
export type UpperCased<T> = {
  [K in keyof T as `${Capitalize<K & string>}`]: T[K];
};

/**
 * Provides access to all the props for a styled component, by passing the generic Styled Component type.
 *
 * @example
 * ```
 * type ForwardedProps = StyledComponentAllProps<typeof SelectWrapper>;
 * ```
 */
export type StyledComponentAllProps<TComponent extends AnyStyledComponent> = TComponent extends StyledComponent<
  infer C,
  infer T,
  infer O,
  infer A
>
  ? StyledComponentProps<C, T, O, A>
  : never;

/**
 * Returns all the keys of an object that begin with the given prefix.
 *
 * @example
 * ```
 * type CurrencyIconProps = {
 *    // Allow choosing `colorText`, `colorButtonBorder`, etc. but not `color` itself
 *    color?: Exclude<KeysBeginningWith<DefaultTheme, 'color'>, 'color'>;
 * };
 * ```
 */
export type KeysBeginningWith<T, Prefix extends string> = {
  [K in keyof T]: K extends `${Prefix}${infer _Variant}` ? K : never;
}[keyof T];

/**
 * Returns all the "variants" of an object that begin with the given prefix.
 *
 * @example
 * ```
 * type CurrencyIconProps = {
 *    // Allow choosing `Small`, `Large`, etc.
 *    size?: VariantsBeginningWith<DefaultTheme, 'fontSize'>;
 * };
 * ```
 */
export type VariantsBeginningWith<T, Prefix extends string> = {
  [K in keyof T]: K extends `${Prefix}${infer Variant}` ? Variant : never;
}[keyof T];

// The below types are taken from https://stackoverflow.com/a/58436959

/**
 * Join two string | number with each other with a dot in-between
 * @example
 * Join<"a","b.c"> // -> "a.b.c"
 * @example
 *  Join<"a",""> // -> "a"
 */
export type JoinHelper<K, P> = K extends string | number
  ? P extends string | number
    ? `${K}${'' extends P ? '' : '.'}${P}`
    : never
  : never;

// Used for recursion depth-limiting for the typing
type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]];

/**
 * Steps through object T recursively and collects all steps along the way until depth D (defaults to 10).
 * @example
 * Paths<{ a: string; b: { c: string } }> // -> "a" | "b" | "b.c"
 */
export type Paths<T> = PathsHelper<T, 5>;
type PathsHelper<T, D extends number> = [D] extends [never]
  ? never
  : T extends Date
  ? ''
  : T extends object
  ? {
      [K in keyof T]-?: K extends string | number ? `${K}` | JoinHelper<K, PathsHelper<T[K], Prev[D]>> : never;
    }[keyof T]
  : '';

/**
 * Steps through object T recursively and collects all leaves along the way until depth D (defaults to 10).
 * Discards all intermediate steps and only includes leaf nodes.
 * Note: Potentially undefined paths are counted as leafs, see second example.
 * @example
 * Leaves<{ a: string; b: { c: string } }> // -> "a" | "b.c"
 * @example // with undefined intermediate path
 * Leaves<{ a: string; b?: { c: string } }> // -> "a" | "b" | "b.c"
 */
export type Leaves<T> = LeavesHelper<T, 10>;
export type LeavesHelper<T, D extends number> = [D] extends [never]
  ? never
  : T extends Date
  ? ''
  : T extends unknown[]
  ? '' // | { [K in keyof T]-?: JoinHelper<K, LeavesHelper<T[K], Prev[D]>> }[keyof T]
  : T extends object
  ? { [K in keyof T]-?: JoinHelper<K, LeavesHelper<T[K], Prev[D]>> }[keyof T]
  : '';

// https://stackoverflow.com/questions/49242232/constraining-type-in-typescript-generic-to-be-one-of-several-types
// OneOf<T, V> is the main event:
// take a type T and a tuple type V, and return the type of
// T widened to relevant element(s) of V:
export type OneOf<T, V extends any[], NK extends keyof V = Exclude<keyof V, keyof any[]>> = {
  [K in NK]: T extends V[K] ? V[K] : never;
}[NK];

/** Applies the & operator to the supplied types */
export type IntersectAll<T extends any[]> = T extends [infer Type, ...infer Rest] ? Type & IntersectAll<Rest> : unknown;

/** Applies the | operator to the supplied types */
export type UnionAll<T extends any[]> = T extends [infer Type, ...infer Rest] ? Type | UnionAll<Rest> : never;

/**
 * Picks all properties off T where T[keyof T] resolves to Value
 */
export type PickByType<T, Value> = {
  [P in keyof T as T[P] extends Value ? P : never]: T[P];
};

/**
 * Use this higher order type when narrowing type for nullable/undefined properties.
 * ```ts
 * type Dog = {
 *  name: string
 *  tag?: string
 * }
 * type NaivelyTaggedDog = Dog & { tag: NonNullable<Dog['tag']>}
 * // equals to:
 * type TaggedDog =  RequiredProperties<Dog,'tag'>
 * ```
 */
export type RequiredProperties<T extends object, K extends keyof T> = Required<Pick<T, K>> &
  T & { [k in K]: NonNullable<T[k]> };

/**
 * Use this higher order type to make specific properties optional.
 * ```ts
 * type Dog = {
 *  name: string
 *  tag: string
 * }
 * type MaybeTaggedDog = Dog & { tag?: Dog['tag'] }
 * // equals to:
 * type MaybeTaggedDog =  OptionalProperties<Dog,'tag'>
 * ```
 */
export type OptionalProperties<T extends object, K extends keyof T> = Partial<Pick<T, K>> & Omit<T, K>;

/** A deep Partial<T> */
export type DeepPartial<T> = T extends object
  ? {
      [P in keyof T]?: DeepPartial<T[P]>;
    }
  : T;

/** For filtering empty values from arrays. Makes sure value<T> is defined and not null. */
export function notEmpty<T>(value: T | null | undefined): value is T {
  if (value === null || value === undefined) {
    return false;
  }
  return true;
}

/**
 * The value type of a collection type (Array, Map, Set).
 */
export type Entry<T> = T extends Array<infer ArrayElementType>
  ? ArrayElementType
  : T extends Map<any, infer MapValueType>
  ? MapValueType
  : T extends Set<infer SetValueType>
  ? SetValueType
  : never;

/**
 * Extract the base values of all entries in an object (key helper for deprecating enum usage)
 * - Sadly you can't extract string values from actual TS enums (for usages in fixtures, as an example)
 * - Enums need to be replaced with 'as const' objects to work with this type.
 *
 * ```ts
 * // you could have done this with arrays or string literals, but they don't support JsDoc comments
 * const MyNewEnum = {
 *   A: 'apple',
 *   B: 'ball',
 *   C: 'car'
 * } as const;
 * type MyEnumValues = ValueOf<typeof MyNewEnum> // 'apple' | 'ball' | 'car'
 * ```
 */
export type ValueOf<T> = T[keyof T];

/** Get the Keys of a type only matching a certain value type
 * @template T - The type to get the keys from
 * @template V - The type to match
 *
 * @example
 * ```
 * type Test = { a: string; b: number; c: string };
 * type Result = KeysMatching<Test, string>; // 'a' | 'c'
 * ```
 */
export type KeysMatching<T, V> = {
  [K in keyof T]-?: T[K] extends V ? K : never;
}[keyof T];

/**
 * Typed version of Object.keys
 * - NOTE: the outputs are not automatically typesafe to use due to potentially additional properties (and why it's not standard TS library)
 * - See https://alexharri.com/blog/typescript-structural-typing for a great explanation and discussion
 *
 * PLEASE always check the output keys you're using this with are expected, and adjust as needed
 */
export function getTypedKeys<T extends object>(obj: T): (keyof T)[] {
  return Object.keys(obj) as (keyof T)[];
}

/**
 * Typed version of Object.entries
 * - NOTE: similarly to {@link getTypedKeys} the outputs are not automatically typesafe to use due to potentially additional object properties, but
 * for many cases it's a good enough solution which is better than the default.
 *
 * PLEASE always check the output mappings coming out of this are expected, and adjust as needed
 */
export function getTypedEntries<T extends object>(obj: T): [keyof T, T[keyof T]][] {
  return Object.entries(obj) as [keyof T, T[keyof T]][];
}

/**
 * Typed version of Object.values
 * - NOTE: similarly to {@link getTypedKeys} the outputs are not automatically typesafe to use due to potentially additional object properties, but
 * for many cases it's a good enough solution which is better than the default.
 *
 * PLEASE always check the output mappings coming out of this are expected, and adjust as needed
 */
export function getTypedValues<T extends object>(obj: T): T[keyof T][] {
  return Object.values(obj) as T[keyof T][];
}

/**
 * Typeguard helper for checking if a key is in an object (as Typescript doesn't support this out of the box)
 * https://stackoverflow.com/questions/64616994/typescript-type-narrowing-not-working-for-in-when-key-is-stored-in-a-variable
 * @param key property to test
 * @param obj object that key should be in
 */
export function isKeyIn<T extends object>(key: PropertyKey, obj: T): key is keyof T {
  return key in obj;
}
