import { useMemo } from 'react';
import { getAgGridColId } from '../components/BlotterTable/columns/getAgGridColId';
import type { Column, ColumnDef } from '../components/BlotterTable/columns/types';
import { useDefaultColumns } from '../components/BlotterTable/useDefaultColumns';
import { toBig } from '../utils';
import { EMPTY_ARRAY } from '../utils/empty';
import { getCreditUsage } from '../utils/getCreditUsage';
import type { ExposureLimitSideTypeEnum, ExposureStatusEnum, IExposureView, ModeEnum, UpdateActionEnum } from './types';

/** An enum used to distinguish between the two different types of exposures we rely on internally */
export enum ExposureTypeEnum {
  Exposure = 'Exposure',
  MarketExposure = 'MarketExposure',
}

/**
 * This interface aims to provide a uniform api for interacting with both entities of type Exposure and of type MarketExposure
 */
export interface IUniformExposure {
  exposure: string;
  limit: string | undefined;
  counterpartyExposure: string | undefined;
  counterpartyExposureLimit: string | undefined;
  talosExposure?: string | undefined;
  talosExposureLimit?: string | undefined;
  usage: ReturnType<typeof getCreditUsage>;
  currency: string | undefined;
  ExposureSide: ExposureLimitSideTypeEnum;
  Text?: string;
  Status: ExposureStatusEnum;
  Timestamp: string;
  MarketAccount: string;
  ExposureDefinition: string;
  ExposureCurrency: string;
  type: ExposureTypeEnum;
  rowID: string;
}

export class Exposure implements IUniformExposure {
  Mode!: ModeEnum;
  Counterparty!: string;
  MarketAccount!: string;
  Qty!: string;
  ExposureCurrency!: string;
  ExposureDefinition!: string;
  ExposureLimitID?: string;
  MarketExposure?: string;
  MarketLimit?: string;
  Status!: ExposureStatusEnum;
  Text?: string;
  Timestamp!: string;
  Exposure!: string;
  ExposureSide!: ExposureLimitSideTypeEnum;
  ExposureLimit?: string;

  get type() {
    return ExposureTypeEnum.Exposure;
  }

  /** Grabs the effective exposure of this entity. Prefers MarketExposure, but uses Exposure if that does not exist. */
  get exposure() {
    // We treat a nullish exposure from the backend as 0
    return this.MarketExposure ?? this.Exposure ?? '0';
  }

  /** Grabs the effective exposure limit of this entity. Grabs the limit which is closest to 0 (and thus would be enforced first). */
  get limit() {
    const marketLimitBig = toBig(this.MarketLimit)?.abs();
    const talosLimitBig = toBig(this.ExposureLimit)?.abs();

    // If both limits are defined, then return the one closest to 0
    if (marketLimitBig && talosLimitBig) {
      return marketLimitBig.lt(talosLimitBig) ? marketLimitBig.toFixed() : talosLimitBig.toFixed();
    }

    // Else only one of them is defined, just return whichever is defined, or undefined of course
    return marketLimitBig?.toFixed() ?? talosLimitBig?.toFixed();
  }

  get counterpartyExposure() {
    return this.MarketExposure;
  }

  get counterpartyExposureLimit() {
    return this.MarketLimit;
  }

  get talosExposure() {
    return this.Exposure;
  }

  get talosExposureLimit() {
    return this.ExposureLimit;
  }

  get usage() {
    return getCreditUsage(this.exposure, this.limit, this.ExposureCurrency);
  }

  get currency() {
    return undefined;
  }

  get rowID() {
    return getExposureID(this);
  }

  constructor(data: Exposure) {
    Object.assign(this, data);
  }
}

export function getExposureID(exposure: {
  MarketAccount?: string;
  ExposureDefinition?: string;
  Currency?: string;
}): string {
  return `${exposure.MarketAccount}-${exposure.ExposureDefinition}-${exposure.Currency}`;
}

export interface ExposureLimit {
  Mode: ModeEnum;
  Counterparty?: string;
  MarketAccount: string;
  Qty: string;
  ExposureCurrency: string;
  ExposureDefinition: string;
  ExposureLimitID: string;
  UpdateAction?: UpdateActionEnum;
}

export interface CreditExposureWithSides extends CreditExposure {
  longExposure?: string;
  longExposureLimit?: string;
  shortExposure?: string;
  shortExposureLimit?: string;
}

export function generateCreditExposureRowID(exposure: CreditExposure) {
  return `${exposure.MarketAccount}-${exposure.Currency}`;
}

export class EnrichedCreditExposure {
  Currency?: string;

  Timestamp!: string;

  Exposure!: string;

  ExposureLimit!: string;

  ExposureCurrency!: string;

  // This field *should* be required, but if it's not present, should be defaulted to ExposureSideEnum.Both
  ExposureSide!: ExposureLimitSideTypeEnum;

  MarketAccount!: string;

  Status!: ExposureStatusEnum;

  // Custom for unique Row ID
  protected get rowID() {
    return generateCreditExposureRowID(this);
  }

  shortExposure = '';

  shortExposureLimit = '';

  longExposure = '';

  longExposureLimit = '';

  constructor(data: CreditExposureWithSides) {
    Object.assign(this, data);
  }
}

interface UseEnrichedCreditExposureColumns {
  defaultColumns?: (keyof EnrichedCreditExposure | Partial<Column>)[];
}

export function useEnrichedCreditExposureColumns({
  defaultColumns = EMPTY_ARRAY,
}: UseEnrichedCreditExposureColumns): Column[] {
  const defaultVisibleColumns = useMemo(
    () =>
      new Map(
        (
          [
            {
              field: 'Currency',
              type: 'currency',
            },
            {
              field: 'Timestamp',
              type: 'text',
            },
            {
              field: 'Exposure',
              type: 'size',
              params: { currencyField: 'ExposureCurrency' },
            },
            {
              field: 'ExposureLimit',
              type: 'size',
              params: { currencyField: 'ExposureCurrency' },
            },
            {
              field: 'ExposureCurrency',
              type: 'currency',
            },
            {
              field: 'ExposureSide',
              type: 'text',
            },
            {
              field: 'MarketAccount',
              type: 'marketAccount',
            },
            {
              field: 'Status',
              type: 'text',
            },
            {
              field: 'shortExposure',
              type: 'size',
              params: { currencyField: 'ExposureCurrency' },
            },
            {
              field: 'shortExposureLimit',
              type: 'size',
              params: { currencyField: 'ExposureCurrency' },
            },
            {
              field: 'longExposure',
              type: 'size',
              params: { currencyField: 'ExposureCurrency' },
            },
            {
              field: 'longExposureLimit',
              type: 'size',
              params: { currencyField: 'ExposureCurrency' },
            },
          ] satisfies ColumnDef<EnrichedCreditExposure>[]
        ).map(c => [getAgGridColId(c), c])
      ),
    []
  );
  const columnDefinitions = useMemo(() => {
    return new Map(
      ([...defaultVisibleColumns.values()] satisfies ColumnDef<EnrichedCreditExposure>[] as Column[]).map(c => [
        getAgGridColId(c),
        c,
      ])
    );
  }, [defaultVisibleColumns]);
  return useDefaultColumns(defaultColumns, columnDefinitions);
}

export interface CreditExposure {
  Currency?: string;
  Timestamp: string;
  Exposure: Exposure['Exposure'];
  ExposureLimit: Exposure['ExposureLimit'];
  ExposureCurrency: Exposure['ExposureCurrency'];
  // This field *should* be required, but if it's not present, should be defaulted to ExposureSideEnum.Both
  ExposureSide: Exposure['ExposureSide'];
  MarketAccount: Exposure['MarketAccount'];
  MarketExposure?: IExposureView['MarketExposure'];
  MarketLimit?: IExposureView['MarketLimit'];
  AvailableSell?: IExposureView['AvailableSell'];
  Balance?: IExposureView['Balance'];
  Status: Exposure['Status'];
}
