import {
  MixpanelEvent,
  MixpanelEventSource,
  Spinner,
  SymbolSelector,
  SymbolSelectorAppearance,
  TradingViewChart,
  useMixpanel,
  useSecuritiesContext,
  useWLOrgConfigContext,
  useWLSymbol,
} from '@talos/kyoko';
import { useCallback, useEffect, useState } from 'react';
import { CandleSticksWrapper, CandleSticksWrapperWithSymbolSelector } from './styles';

export interface CandlesticksProps {
  hasOwnSymbolSelector?: boolean;
}

export const CandlesticksWithOMSSymbol = () => {
  const { symbol } = useWLSymbol();
  const { config } = useWLOrgConfigContext();

  if (!symbol) {
    return <Spinner />;
  }

  return (
    <TradingViewChart
      chartId={`candlesticks/${symbol}`}
      key={symbol}
      symbol={symbol}
      apiEndpoint={import.meta.env.VITE_API_ENDPOINT!}
      exchangeName={config.title}
    />
  );
};

export const CandlesticksWithOwnSymbolSelector = () => {
  const mixpanel = useMixpanel();
  const { symbol: defaultSymbol } = useWLSymbol();
  const [symbol, setSymbol] = useState<string | undefined>(defaultSymbol);
  const { config } = useWLOrgConfigContext();

  const handleSymbolChange = useCallback(
    (symbol?: string) => {
      mixpanel.track(MixpanelEvent.ChangeSymbol, { source: MixpanelEventSource.CandleSticks });
      if (symbol) {
        setSymbol(symbol);
      }
    },
    [mixpanel, setSymbol]
  );
  const { securitiesBySymbol, securitiesListSortedByRank } = useSecuritiesContext();

  // Handle case where selected symbol is not in the list of available securities (can happen if the symbol gets disabled)
  useEffect(() => {
    if (symbol && !securitiesBySymbol.has(symbol)) {
      setSymbol(securitiesListSortedByRank.at(0)?.Symbol);
    }
  }, [symbol, setSymbol, securitiesBySymbol, securitiesListSortedByRank]);

  if (!symbol) {
    return <Spinner />;
  }

  return (
    <>
      <TradingViewChart
        chartId={`candlesticks/${symbol}`}
        key={symbol}
        symbol={symbol}
        apiEndpoint={import.meta.env.VITE_API_ENDPOINT!}
        exchangeName={config.title}
      />
      <CandleSticksWrapperWithSymbolSelector>
        <SymbolSelector
          appearance={SymbolSelectorAppearance.Ghost}
          symbol={symbol}
          onSymbolChanged={handleSymbolChange}
          dropdownWidth="200px"
          showSelectedSymbolDescription={false}
        />
      </CandleSticksWrapperWithSymbolSelector>
    </>
  );
};

export const Candlesticks = ({ hasOwnSymbolSelector }: CandlesticksProps) => {
  const Component = hasOwnSymbolSelector ? CandlesticksWithOwnSymbolSelector : CandlesticksWithOMSSymbol;
  return (
    <CandleSticksWrapper>
      <Component />
    </CandleSticksWrapper>
  );
};
