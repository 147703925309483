import styled, { css, type DefaultTheme } from 'styled-components';

import { darken, transparentize } from 'polished';
import { hoverColor, isAdjustableColor, parseColor, setAlpha } from '../../styles';
import { WARNING_ROW_CLASSNAME } from '../AgGrid/types';
import { Button } from '../Button';
import { Box, VStack } from '../Core';
import { FormRowStatus } from '../FormTable/types';
import { NumberWrapper } from '../FormattedNumber';
import { BlotterDensity } from './types';

const getFontSize = (theme: DefaultTheme, density?: BlotterDensity) =>
  density === BlotterDensity.Compact ? theme.fontSizeBlotterTableCompact : theme.fontSizeBlotterTableDefault;

const getSpacing = (theme: DefaultTheme, density?: BlotterDensity) =>
  density === BlotterDensity.Compact
    ? theme.spacingSmall
    : density === BlotterDensity.Comfortable
    ? theme.spacingMedium
    : theme.spacingDefault;

export const blotterStyles = (theme: DefaultTheme, density?: BlotterDensity, background?: string) => {
  const parsedColor = background && background !== 'transparent' ? parseColor(theme, background) : undefined;
  const backgroundColor = parsedColor ? parseColor(theme, parsedColor) : theme.backgroundBlotterTableRow;
  const hoverBackgroundColor = parsedColor ? hoverColor(theme, parsedColor) : theme.backgroundBlotterTableRowHover;
  const borderColor = parsedColor ? darken(0.05, parsedColor) : theme.borderColorBlotterTable;

  const { columnDividerColor, columnDividerCss } = calculateDividerStyles(theme);

  return css`
    font-family: ${theme.fontFamily};
    font-size: ${getFontSize(theme, density)}rem;
    height: 100%; // Combats Sticky Headers not working, by allowing us to remove domLayout="autoHeight"

    // Root
    --ag-size: 3px; // Not working?
    --ag-border-radius: 0px;
    --ag-foreground-color: ${theme.colorTextImportant};
    --ag-data-color: ${theme.colorTextImportant};
    --ag-secondary-foreground-color: ${theme.colorTextSubtle};
    --ag-disabled-foreground-color: ${theme.colorTextMuted};
    --ag-background-color: ${backgroundColor};
    --ag-font-size: ${getFontSize(theme, density)}rem;
    --ag-header-column-separator-color: ${columnDividerColor};
    --hoverBackgroundColor: ${hoverBackgroundColor};

    // Row group indentation
    --ag-row-group-indent-size: calc((var(--spacing16) + var(--spacing6)) * 1px);
    --ag-cell-widget-spacing: calc(var(--spacing6) * 1px);

    // Row group icons
    --ag-icon-font-family-tree-open: 'helixicons';
    --ag-icon-font-family-tree-closed: 'helixicons';
    --ag-icon-font-code-tree-open: '\\e92c';
    --ag-icon-font-code-tree-closed: '\\e92e';
    --ag-icon-font-color-tree-closed: rgb(from var(--colorTextImportant) r g b / 56%);
    --ag-icon-font-color-tree-open: rgb(from var(--colorTextImportant) r g b / 56%);
    .ag-group-contracted,
    .ag-group-expanded {
      &:hover,
      &:active,
      &:focus {
        --ag-icon-font-color-tree-closed: var(--colorTextImportant);
        --ag-icon-font-color-tree-open: var(--colorTextImportant);
      }
    }

    // Hide border around grid
    .ag-root-wrapper {
      border: 0;
    }

    // Allow elements in NoRowsOverlay to be clicked
    .ag-overlay {
      background: var(--ag-background-color);
      pointer-events: all;
    }

    .ag-tooltip-custom {
      position: absolute;
    }

    .ag-tooltip {
      max-width: 25rem;
    }

    // Header
    --ag-header-foreground-color: ${theme.colorBlotterTableHeader};
    --ag-header-background-color: ${backgroundColor};
    --ag-border-color: ${borderColor};
    --ag-subheader-background-color: transparent;
    --ag-subheader-toolbar-background-color: transparent;
    --ag-header-cell-moving-background-color: ${hoverBackgroundColor};

    .ag-header-cell.hide-header-label {
      .ag-header-cell-text {
        visibility: hidden;
      }
    }

    .ag-header-cell,
    .ag-header-group-cell,
    .ag-header-cell-group {
      font-size: ${theme.fontSizeBlotterTableHeader}rem;
      text-transform: ${theme.blotterTableHeaderTextTransform};
      font-weight: ${({ theme }) => theme.blotterTableHeaderFontWeight};
      padding: 0 ${getSpacing(theme, density)}px;

      &.ag-header-cell-sortable:hover {
        color: ${({ theme }) => theme.colorTextImportant};
      }
      .ag-header-cell-menu-button {
        cursor: pointer;
        color: ${({ theme }) => theme.colorTextImportant};
      }
    }

    .ag-header-cell-sorted-desc,
    .ag-header-cell-sorted-asc {
      color: ${({ theme }) => theme.colorTextImportant};
    }

    // For columns that are not sortable, hide the sort arrow since they render anyway (bug in ag-grid)
    .ag-custom-column-group:not(.ag-header-cell-sortable) {
      .hi-arrow-up,
      .hi-arrow-down {
        visibility: hidden;
      }
    }

    .ag-header-cell.no-handle:after {
      display: none;
    }

    // Show column divider on hover, before and after
    ${columnDividerCss}

    .ag-header-cell {
      .ag-header-cell-menu-button {
        flex: 0 1 1px;
        overflow: hidden;
        transition: flex 200ms ease 500ms, opacity 200ms ease 500ms;
        opacity: 0;
      }

      &.ag-header-active,
      &.ag-column-menu-visible {
        .ag-header-cell-menu-button {
          flex: 0 0 16px;
          opacity: 1;
          transition: flex 200ms ease, opacity 200ms ease;
        }
      }
    }

    .ag-keyboard-focus {
      .ag-header-cell {
        &:hover:after,
        &:hover:before {
          background-color: transparent;
        }
      }
    }

    .ag-header-no-title {
      .ag-header-cell-comp-wrapper {
        height: 100%;
        display: flex;
        justify-content: center;
      }
      .ag-header-cell-text {
        display: none;
      }
      padding: 0;
      .ag-header-cell-label .ag-sort-indicator-icon {
        margin-left: 0;
        padding-left: 0;
      }
    }

    // This can be referenced by ColDef.headerClass.
    // Used for when you want to render a custom talos <Checkbox> component yourself in an aggrid header cell.
    .ag-header-custom-checkbox {
      .ag-header-cell-text {
        display: none;
      }
      padding: 0;
    }

    .ag-column-drop-horizontal {
      background-color: inherit;
    }

    .ag-column-drop-cell {
      background: ${theme.backgroundDefaultButton};
      height: 20px;
      color: ${theme.colorTextDefaultButton};
    }

    // Rows
    --ag-row-border-color: ${borderColor};
    --ag-row-hover-color: ${hoverBackgroundColor};

    --ag-odd-row-background-color: ${backgroundColor
      ? isAdjustableColor(backgroundColor)
        ? setAlpha(0.5, backgroundColor)
        : backgroundColor
      : theme.backgroundBlotterTableRow};
    --ag-value-change-value-highlight-background-color: ${theme.backgroundPrimarySubtle};
    .ag-row {
      transition: background 250ms;

      &.ag-row-hover.ag-row-selected::before {
        background-image: none;
      }
    }

    // Imitate a filler column at the end to cover the entire width
    .ag-center-cols-container {
      min-width: 100%;
    }

    .ag-row.${FormRowStatus.Added}, .ag-row.${FormRowStatus.Added}:hover {
      background: ${theme.backgroundPositiveMuted};
      font-style: italic;
      opacity: 1;
    }

    .ag-row.${FormRowStatus.Updated}, .ag-row.${FormRowStatus.Updated}:hover {
      background: ${theme.backgroundPrimaryMuted};
      font-style: italic;
      opacity: 1;
    }

    .ag-row.${FormRowStatus.Removed}, .ag-row.${FormRowStatus.Removed}:hover {
      background: ${theme.backgroundNegativeMuted};
      font-style: italic;
      opacity: 1;
    }

    // Widgets
    --ag-modal-overlay-background-color: ${(typeof background === 'string'
      ? parseColor(theme, background)
      : background) ?? theme.backgroundContent};

    // Groups
    .ag-row-group {
      align-items: center;
    }

    .ag-row-level-1,
    .ag-row-level-2,
    .ag-row-level-3,
    .ag-row-level-4,
    .ag-row-level-5 {
      .ag-row-group-indent-1,
      .ag-row-group-indent-2,
      .ag-row-group-indent-3,
      .ag-row-group-indent-4,
      .ag-row-group-indent-5 {
        &.ag-row-group-leaf-indent {
          margin-left: 0;
        }
        &:not(.ag-cell-expandable) {
          &::before {
            font-family: helixicons;
            font-size: var(--ag-icon-size);
            line-height: var(--ag-icon-size);
            content: '\\ea08';
            color: rgb(from var(--colorTextDefault) r g b / 32%);
            display: inline-block;
            padding-right: calc(var(--spacing6) * 1px);
          }
        }
      }
    }

    .ag-cell,
    .ag-header-cell {
      &.ag-center-aligned-cell .ag-header-cell-label,
      &.ag-center-aligned-cell,
      &.ag-center-aligned-cell .ag-react-container {
        justify-content: center;
      }
      &.ag-right-aligned-cell .ag-header-cell-label,
      &.ag-right-aligned-cell,
      &.ag-right-aligned-cell .ag-react-container {
        justify-content: flex-end;
      }
      &.ag-custom-column-group.ag-custom-column-group {
        background: ${({ theme }) => theme.colors.gray['030']};
        :hover {
          background: ${({ theme }) => theme.colors.gray['040']};
        }
      }
    }

    --ag-range-selection-border-color: transparent;
    --ag-selected-row-background-color: ${theme.backgroundBlotterTableRowSelected};
    .ag-cell {
      // - 1 to take invisible border into account
      padding: 0 ${getSpacing(theme, density) - 1}px;
      line-height: 1;

      overflow: hidden;
      display: flex;
      align-items: center;

      // For number cells that are aligned right
      &.ag-right-aligned-cell {
        :has(> ${NumberWrapper}) {
          &:hover {
            overflow: visible;
            ${NumberWrapper} {
              // show the full number on hover, with a background color as well as some spacing to the left
              // to ensure that (any possible) number to the left bleed into this cell.
              padding-left: ${theme.spacingTiny}px;
              background-color: var(--hoverBackgroundColor);
            }
          }
        }
      }

      // Remove left side border of right pinned cells
      &.ag-cell-first-right-pinned:not(.ag-cell-range-left):not(.ag-cell-range-single-cell) {
        border-left: transparent;
      }

      // Remove right side border of left pinned cells
      &.ag-cell.ag-cell-last-left-pinned:not(.ag-cell-range-right):not(.ag-cell-range-single-cell) {
        border-right: transparent;
      }

      // Increase left padding for first cell in a row
      .ag-cell-value[aria-colindex='1'],
      .ag-header-cell[aria-colindex='1'] {
        padding-left: ${({ theme }) => theme.spacingMedium}px;
      }

      &.editable {
        transition: background 150ms;

        &:hover {
          background: ${theme.colors.gray['030']};
          cursor: pointer;
        }
      }

      &.invalid {
        border-color: ${({ theme }) => theme.colors.red.lighten};
      }

      // Allow cell values to overflow when hovering to support showing all decimals of a number
      .ag-cell-value:hover {
        overflow: visible;
        z-index: 1;
      }

      // Allow InlineFormattedNumber to inherit background when hovering
      .${NumberWrapper} {
        background: inherit;
      }
    }

    // Checkbox styling
    .ag-checkbox-input-wrapper.ag-checked::after {
      // Needed some additional specificness for this specific var
      --ag-checkbox-checked-color: ${theme.colors.white.default};
    }
    --ag-checkbox-indeterminate-color: ${theme.colors.white.default};
    --ag-checkbox-unchecked-color: ${theme.colorTextDefault};
    .ag-checkbox-input-wrapper {
      box-shadow: none;
      border: 1px solid ${theme.borderColorCheckbox};
      background: ${theme.backgroundCheckbox};

      &:focus-within {
        border: 1px solid ${theme.borderColorCheckboxFocus};
      }

      &:hover {
        background: ${theme.backgroundCheckboxHover};
      }

      &.ag-checked {
        background: ${({ theme }) => theme.backgroundCheckboxChecked};
        border: 1px solid ${({ theme }) => theme.borderColorCheckboxChecked};
        &:after {
          opacity: 1;
        }
      }

      &.ag-indeterminate {
        background: ${({ theme }) => theme.backgroundCheckboxChecked};
        border: 1px solid ${({ theme }) => theme.borderColorCheckboxChecked};
        &:after {
          content: '\\e996';
          opacity: 1;
        }
      }

      &:after {
        font-family: helixicons;
        content: '\\e92b';
        font-size: ${({ theme }) => theme.fontSizeDefault}rem;
        opacity: 0;
        transition: opacity 200ms ease;
      }
    }

    // Gradient for pinned column to indicate more content
    .ag-pinned-right-cols-container {
      box-shadow: 0 0 15px ${({ theme }) => backgroundColor}, 0 0 3px ${({ theme }) => backgroundColor};
    }
    .ag-pinned-right-header {
      box-shadow: 0 0 15px ${({ theme }) => backgroundColor}, 0 0 3px ${({ theme }) => backgroundColor};
    }

    // Inputs
    .ag-react-container:not(.ag-tooltip-custom) {
      display: flex;
      align-items: center;
      height: 100%;
      width: 100%;
    }

    .${WARNING_ROW_CLASSNAME} {
      background-color: ${({ theme }) => transparentize(1 - 0.12, theme.colors.red.default)};
      --ag-row-hover-color: ${({ theme }) => transparentize(1 - 0.24, theme.colors.red.default)};
      --ag-selected-row-background-color: ${({ theme }) => transparentize(1 - 0.36, theme.colors.red.default)};
    }

    .ag-cell-warning {
      padding-left: 0;
      padding-right: 0;
      > .ag-react-container {
        align-items: stretch;
      }
    }

    .ag-cell-inline-editing {
      border: none;
      background: transparent;
      box-shadow: none;
      height: auto;
      padding: 0;
    }

    // Context menu
    .ag-menu {
      background: ${({ theme }) => theme.backgroundPopover};
    }

    .ag-menu-option-active {
      background-color: ${({ theme }) => darken(0.03)(theme.backgroundPopover)};
    }

    .ag-rich-select .ag-rich-select-list {
      width: auto !important;
    }

    .ag-aria-description-container {
      /* Remove helpful aria text above the blotter */
      display: none;
    }
  `;
};

/**
 * We have separate styles for blotter popups. This became necessary to inject styles with greater specificity in order to override the default styles applied by aggrid.
 */
export const blotterPopupStyles = (theme: DefaultTheme, background?: string) => {
  const parsedColor = background && background !== 'transparent' ? parseColor(theme, background) : undefined;
  const backgroundColor = parsedColor ? parseColor(theme, parsedColor) : theme.backgroundBlotterTableRow;
  const hoverBackgroundColor = parsedColor ? hoverColor(theme, parsedColor) : theme.backgroundBlotterTableRowHover;
  const borderColor = parsedColor ? darken(0.05, parsedColor) : theme.borderColorBlotterTable;

  return css`
    --ag-foreground-color: ${theme.colorTextImportant};
    --ag-background-color: ${backgroundColor};

    // Header
    --ag-header-foreground-color: ${theme.colorBlotterTableHeader};
    --ag-header-background-color: ${backgroundColor};
    --ag-border-color: ${borderColor};
    --ag-subheader-background-color: transparent;
    --ag-subheader-toolbar-background-color: transparent;
    --ag-header-cell-moving-background-color: ${hoverBackgroundColor};

    // Checkbox styling
    --ag-checkbox-checked-color: ${theme.colors.white.default};
    --ag-checkbox-indeterminate-color: ${theme.colors.white.default};
    --ag-checkbox-unchecked-color: ${theme.colorTextDefault};
  `;
};

export const BlotterTableWrapper = styled.div<{
  density?: BlotterDensity;
  background?: string;
  pinnedLeftOverlapping?: boolean;
  pinnedRightBorderHidden?: boolean;
  hidden?: boolean;
}>`
  height: 100%;
  width: 100%;

  p {
    color: ${({ theme }) => theme.colorTextAttention};
    margin: 0;
    padding: 0;
  }

  ${({ hidden }) =>
    hidden &&
    css`
      display: none;
    `}

  &.ag-theme-balham-dark {
    // See https://www.ag-grid.com/javascript-grid/themes-customising/#full-list-of-theme-parameters

    ${({ theme, density, background }) => blotterStyles(theme, density, background)}

    // Avoid showing the default ag-grid pinned border (unless scrolled)
    .ag-pinned-left-header {
      border-right: transparent;
    }

    /** By default we always show a border-left on right-pinned grid sections 
    * - for cases like Watchlist, which has a hover-triggered button display on the right 
    * pinned section, we want to hide this border.
    */
    ${({ pinnedRightBorderHidden }) =>
      !pinnedRightBorderHidden &&
      css`
        .ag-pinned-right-cols-container,
        .ag-pinned-right-sticky-top,
        .ag-pinned-right-header {
          border-left: 1px solid var(--ag-border-color);
        }
      `}

    // Only show the left-pinned border when scrolled
    // - ideally the right-pinned border should be shown as well, but that is tough with the current ag-grid API
    // and not part of the current requirements.
    // https://stackoverflow.com/q/73910201/11076115
    ${({ pinnedLeftOverlapping, theme }) =>
      pinnedLeftOverlapping &&
      css`
        .ag-pinned-left-cols-container,
        .ag-pinned-left-sticky-top,
        .ag-pinned-left-header {
          border-right: 1px solid var(--ag-border-color);
        }
      `}
  }

  // We separately override the ag-popup related styles too.
  .ag-theme-balham-dark.ag-popup {
    ${({ theme, background }) => blotterPopupStyles(theme, background)}
  }
`;

export const CustomerRolePillWrapper = styled(Box)`
  background: ${({ theme }) => theme.colors.gray['030']};
  padding: ${({ theme }) => `${theme.spacingSmall}px ${theme.spacingDefault}px`};
  border-radius: ${({ theme }) => theme.borderRadiusDefault}px;
  white-space: nowrap;
`;

export const BlotterMenuWrapper = styled(VStack)`
  align-items: flex-start;
  gap: ${({ theme }) => theme.spacingDefault}px;

  ${Button} {
    justify-content: flex-start;
    width: 100%;
  }
`;

/**
 * Calculate the divider styles for the Grid column dividers
 */
function calculateDividerStyles(theme: DefaultTheme): {
  /** The color of the divider (used in the base Ag-grid styles) */
  columnDividerColor: string;
  /** The css for the column divider hover behavior */
  columnDividerCss: ReturnType<typeof css> | string;
} {
  const singleHeaderDividerPercent = 50;
  const doubleHeaderDividerPercent = 35;
  const columnDividerCss = css`
    .ag-header-cell,
    .ag-header-group-cell-with-group {
      // overflow: visible and :before left: -1px allows the left
      // divider to visually overlap the previous column
      // set before bgColor to transparent so we don't get doubled alpha transparency
      overflow: visible;
      &:before {
        left: -1px;
        background-color: transparent;
      }
      &:after {
        right: 0;
        background-color: ${theme.columnDividerDefault};
      }
      &:before,
      &:after {
        content: '';
        position: absolute;
        z-index: 1;
        display: block;
        width: 1px;
        // set normal header divider height and center it vertically
        height: ${singleHeaderDividerPercent}%;
        top: ${50 - singleHeaderDividerPercent / 2}%;
      }
      &:hover:before,
      &:hover:after {
        background-color: ${theme.columnDividerHover};
      }
    }

    // for taller header cells (those alongsize column group), give a bit more space
    // for the divider, and center it vertically
    .ag-header-cell.ag-header-span-height {
      &:before,
      &:after {
        height: ${doubleHeaderDividerPercent}%;
        top: ${50 - doubleHeaderDividerPercent / 2}%;
      }
    }
  `;
  return { columnDividerColor: theme.columnDividerHover, columnDividerCss };
}
