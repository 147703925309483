import type { ReactNode } from 'react';
import type {
  AutocompleteGroupSorterFunc,
  AutocompleteResultsProps,
  UseAutocompleteOutput,
} from '../Autocomplete/types';
import type { UseDropdownPopperOutput, UseDropdownPopperProps } from '../Dropdown';
import type { DropdownProps } from '../Dropdown/Dropdown';

export type AutocompleteDropdownProps<T> = UseDropdownPopperOutput &
  Omit<DropdownProps, 'children'> &
  AutocompleteResultsProps<T> &
  Pick<UseAutocompleteOutput<T>, 'tabs'> & {
    childrenAboveResults?: ReactNode;
    /** Whether or not to show the group tabs at the top of the dropdown */
    showGroupTabs?: boolean;
    groupsPrefix?: ReactNode;
    groupSorter?: AutocompleteGroupSorterFunc;
  };
// Expose a subset of the props for now
// also cast the <T> to any since we're not using it

export type ExposedAutocompleteDropdownProps<T = any> = Pick<
  AutocompleteDropdownProps<T>,
  'portalize' | 'maxHeight' | 'groupMaxHeight' | 'RenderGroupHeader' | 'suffix' | 'showGroupTabs'
> &
  Pick<UseDropdownPopperProps, 'dropdownPlacement' | 'dropdownWidth'>;

export const ALL_TAB = {
  group: 'All',
};
