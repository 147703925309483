import type { ICellRendererParams } from 'ag-grid-enterprise';
import { first } from 'lodash';
import { defineMessages } from 'react-intl';
import { useDynamicCallback } from '../../../hooks';
import { useGlobalToasts } from '../../../providers/GlobalToastsProvider';
import { copyText } from '../../../utils';
import type { AgGridHamburgerMenuProps } from '../../AgGrid/AgGridHamburgerMenu';
import { Button } from '../../Button';
import { Divider } from '../../Divider';
import { FormControlSizes } from '../../Form/types';
import { FormattedMessage } from '../../Intl';
import { NotificationVariants } from '../../Notification';
import { agGridGetCSV, getAllLeafNodesAfterFilter } from '../utils';
import { baseColumn } from './baseColumn';
import type { ColDefFactory, Column } from './types';

const messages = defineMessages({
  copiedToClipboard: {
    defaultMessage: 'Copied to clipboard',
    id: 'BlotterTable.columns.copiedToClipboard',
  },
  copyAsFormat: {
    defaultMessage: 'Copy as {isJSON, select, true {JSON} other {CSV}}',
    id: 'BlotterTable.columns.copyAsFormat',
  },
  showJson: {
    defaultMessage: 'Show JSON',
    id: 'BlotterTable.columns.showJson',
  },
});

/**
 * Show JSON menu item for use in ag-grid hamburger menu.
 * When clicked, will call the {@see onShowJson} callback with the data for the current row.
 * If the current row is a group row, the data will be an array of all the leaf nodes under this row.
 *
 * @param onShowJson Callback which will be called with the JSON object to show.
 * @param params cellRendererParams from ag-grid
 */
export function ShowJson({
  onShowJson,
  ...params
}: ICellRendererParams & {
  onShowJson(data: object | object[]): void;
}) {
  const onClickHandler = useDynamicCallback(() => {
    const rowNodes: object[] = getAllLeafNodesAfterFilter(params.node).map(row => row.data);
    const jsonData = rowNodes.length > 1 ? rowNodes : first(rowNodes);
    if (jsonData) {
      onShowJson(jsonData);
    }
  });
  if (!params.node) {
    return <></>;
  }

  return (
    <Button size={FormControlSizes.Small} ghost onClick={onClickHandler} width="100%">
      <FormattedMessage {...messages.showJson} />
    </Button>
  );
}

/**
 * Copy CSV menu item for use in ag-grid hamburger menu.
 * When clicked, will copy the CSV data from the current row to the clipboard.
 * If the current row is a group row, the leaf nodes under this row will be copied instead.
 *
 * @param params cellRendererParams from ag-grid
 */
export function CopyCsv(params: ICellRendererParams) {
  const { add: addToast } = useGlobalToasts();

  const handleCopy = useDynamicCallback(() => {
    const rowNodes = new Set(getAllLeafNodesAfterFilter(params.node).map(row => row.id ?? ''));
    copyText(agGridGetCSV(params, rowNodes));
    addToast({
      text: <FormattedMessage {...messages.copiedToClipboard} />,
      variant: NotificationVariants.Positive,
    });
  });

  return (
    <Button size={FormControlSizes.Small} ghost onClick={handleCopy} width="100%">
      <FormattedMessage {...messages.copyAsFormat} values={{ isJSON: false }} />
    </Button>
  );
}

/**
 * Copy JSON menu item for use in ag-grid hamburger menu.
 * When clicked, will copy the JSON data from the current row to the clipboard.
 * If the current row is a group row, the leaf nodes under this row will be copied instead.
 *
 * @param params cellRendererParams from ag-grid
 */
export function CopyJson(params: ICellRendererParams) {
  const { add: addToast } = useGlobalToasts();

  const handleCopy = useDynamicCallback(() => {
    const rowNodes: object[] = getAllLeafNodesAfterFilter(params.node).map(row => row.data);
    const jsonData = JSON.stringify(rowNodes.length > 1 ? rowNodes : first(rowNodes), null, 2);

    copyText(jsonData);
    addToast({
      text: <FormattedMessage {...messages.copiedToClipboard} />,
      variant: NotificationVariants.Positive,
    });
  });

  return (
    <Button size={FormControlSizes.Small} ghost onClick={handleCopy} width="100%">
      <FormattedMessage {...messages.copyAsFormat} values={{ isJSON: true }} />
    </Button>
  );
}

export const hamburgerMenu: ColDefFactory<Column<AgGridHamburgerMenuProps>> = column => ({
  width: 60,
  ...baseColumn({ ...column, exportable: false }),
  headerName: '',
  suppressHeaderMenuButton: true,
  suppressColumnsToolPanel: true,
  pinned: 'right',
  lockVisible: true,
  lockPinned: true,
  cellRenderer: 'hamburgerMenuColumn',
  cellRendererParams: column.params,
  type: 'rightAligned',
});

/**
 * Default hamburger menu for our blotters, use this unless you have extra items in the menu.
 */
export function HamburgerMenu<TData extends object | object[]>(
  params: ICellRendererParams & {
    onShowJson(data: TData): void;
  }
) {
  return (
    <>
      <ShowJson {...params} />
      <Divider orientation="horizontal" />
      <CopyJson {...params} />
      <CopyCsv {...params} />
    </>
  );
}
