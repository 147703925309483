import { useTheme, type DefaultTheme } from 'styled-components';

import { ESignRecipientStatusEnum } from '../../types/types';
import { Icon, IconName } from '../Icons';
import { IndicatorDotVariants, IndicatorDotWrapper } from '../IndicatorDot';
import { Wrapper } from './styles';

export enum RecipientStatusText {
  CptyToSign = 'Cpty To Sign',
  NeedToSign = 'Need To Sign',
  Signed = 'Signed',
  Rejected = 'Rejected',
  Error = 'Error',
  Pending = 'Pending',
}

const getRecipientStatusColor = (status: RecipientStatusText | undefined, theme: DefaultTheme) => {
  const { colors } = theme;
  switch (status) {
    case RecipientStatusText.Rejected:
      return colors.yellow.lighten;
    case RecipientStatusText.Error:
      return colors.red.lighten;
    case RecipientStatusText.CptyToSign:
    case RecipientStatusText.NeedToSign:
      return colors.blue.lighten;
    case RecipientStatusText.Signed:
      return colors.green.lighten;
    default:
      return colors.gray['080'];
  }
};

export const getRecipientStatusText = (
  recipientStatus: ESignRecipientStatusEnum | undefined,
  isCounterparty: boolean
) => {
  const orderStatusTextMapping = {
    [ESignRecipientStatusEnum.PendingSignature]: isCounterparty
      ? RecipientStatusText.CptyToSign
      : RecipientStatusText.NeedToSign,
    [ESignRecipientStatusEnum.Signed]: RecipientStatusText.Signed,
    [ESignRecipientStatusEnum.Rejected]: RecipientStatusText.Rejected,
  };

  const orderStatusText = recipientStatus ? orderStatusTextMapping[recipientStatus] : RecipientStatusText.Pending;
  if (orderStatusText == null) {
    // TODO logger.warn...
    return RecipientStatusText.Pending;
  }

  return orderStatusText;
};

const StatusIcon = ({ recipientStatusText, theme, ...props }) => {
  const color = getRecipientStatusColor(recipientStatusText, useTheme() ?? theme);
  switch (recipientStatusText) {
    case RecipientStatusText.Signed:
      return <Icon icon={IconName.CheckCircleSolid} color={color} {...props} />;
    case RecipientStatusText.Rejected:
      return <Icon icon={IconName.ExclamationCircle} color={color} {...props} />;
    case RecipientStatusText.Error:
      return <Icon icon={IconName.CloseCircle} color={color} {...props} />;
    case RecipientStatusText.NeedToSign:
    default:
      return <Icon icon={IconName.Circle} color={color} {...props} />;
  }
};

type RecipientStatusProps = {
  status?: ESignRecipientStatusEnum;
  isCounterparty: boolean;
  align?: 'right' | 'left';
  iconPlacement?: 'right' | 'left';
  text?: string;
  theme?: DefaultTheme;
  showLabel?: boolean;
};

export function RecipientStatus({
  status,
  align = 'right',
  showLabel,
  text,
  isCounterparty,
  ...props
}: RecipientStatusProps) {
  const recipientStatusText = getRecipientStatusText(status, isCounterparty);
  const defaultTheme = useTheme();
  const theme = props.theme ?? defaultTheme;
  return (
    <Wrapper align={align} title={text} theme={theme} {...props}>
      {!showLabel ? recipientStatusText : null}
      <IndicatorDotWrapper show={!!text} theme={theme} variant={IndicatorDotVariants.Warning}>
        <StatusIcon recipientStatusText={recipientStatusText} theme={theme} size={14} />
      </IndicatorDotWrapper>
    </Wrapper>
  );
}
