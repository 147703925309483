import { useWsBlotterTable, type UseWsBlotterTableProps, type WebsocketRequest } from '../BlotterTable';
import { EntityAdminPage } from './EntityAdminPage';
import type { EntityPageClass, EntityPageRecord } from './types';
import { useEntityAdminPage, type useEntityAdminPageProps } from './useEntityAdminPage';

export interface EntityAdminPageWSProps<TRecord extends EntityPageRecord, TDrawerRecord extends TRecord = TRecord>
  extends useEntityAdminPageProps<TRecord, TDrawerRecord> {
  /** The initial request for the Websocket API endpoint. */
  wsRequest: UseWsBlotterTableProps<any, TRecord>['initialRequest'];
}

export const EntityAdminPageWS = <TRecord extends EntityPageRecord, TDrawerRecord extends TRecord = TRecord>({
  wsRequest,
  ...props
}: EntityAdminPageWSProps<TRecord, TDrawerRecord>) => {
  const { blotterTableProps, ...entityAdminPage } = useEntityAdminPage<TRecord, TDrawerRecord>(props);

  const blotterTableWS = useWsBlotterTable<WebsocketRequest, EntityPageClass<TRecord>>({
    initialRequest: wsRequest,
    ...blotterTableProps,
  });

  return <EntityAdminPage<TRecord, TDrawerRecord> blotterTable={blotterTableWS} {...entityAdminPage} />;
};
