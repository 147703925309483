import type { Paths } from '../../../utils/types';
import { autohedgeStatus, AutoHedgingStatus } from './autoHedgeStatus';
import {
  active,
  addressRoutingType,
  addressStatus,
  asset,
  bitmap,
  bps,
  button,
  careOrderStatus,
  connectionStatus,
  cumBaseQty,
  currency,
  custom,
  customer,
  date,
  email,
  exposureStatus,
  exposureUtilization,
  feeMode,
  filledCounterAmount,
  filledNotional,
  filler,
  formError,
  iconButton,
  id,
  json,
  loanParticipant,
  loanQuoteStatus,
  loanStatus,
  loanTransactionStatus,
  marketColor,
  mode,
  modeWithMessage,
  openNotional,
  orderStatus,
  percent,
  prettyText,
  pricingAggregation,
  processStep,
  productType,
  productTypeField,
  quoteMarkets,
  quoteSide,
  quoteStatus,
  quoteValue,
  reconMismatchStatus,
  remove,
  revision,
  role,
  security,
  side,
  strategy,
  text,
  textArray,
  time,
  totalNotional,
  tradeStatus,
  tradingMarketAccounts,
  transactionType,
  transferStatus,
} from './columns';
import { connectionStatusWithText } from './connectionStatusWithText';
import { counterparty } from './counterparty';
import { counterpartyFromMarketAccount } from './counterpartyFromMarketAccount';
import { credentialAge } from './credentialAge';
import { creditUsageWarning } from './creditUsageWarning';
import { expandRow } from './expandRow';
import { exposureDefinition } from './exposureDefinition';
import { filledPercent } from './filledPercent';
import { delta, gamma, rho, theta, vega } from './greeks';
import { group } from './group';
import { hamburgerMenu } from './hamburgerMenu';
import { market } from './market';
import { account, marketAccount, marketAccountSourceAccountID, marketAccountTransfer } from './marketAccount';
import { marketAccountGroupDerived } from './marketAccountGroupDerived';
import { marketAccountTradingStatus } from './marketAccountTradingStatus';
import { marketAccounts } from './marketAccounts';
import { markets } from './markets';
import { number } from './number';
import { optionGroup, optionSide, optionStrike, STRIKE_CLASSNAME } from './options';
import { orderParameters } from './orderParameters';
import { orderStartTime } from './orderStartTime';
import { pmsWarnings } from './pmsWarning';
import { positionDirection } from './positionDirection';
import { positionExpiry } from './positionExpiry';
import { positionThresholdMeter } from './positionThresholdMeter';
import { positionUnderlyingAsset } from './positionUnderlyingAsset';
import { price } from './price';
import { reconCheckpointEvalStatus } from './reconCheckpointEvalStatus';
import { reconCheckpointStatus } from './reconCheckpointStatus';
import { reconMatchStatus } from './reconMatchStatus';
import { reconWarning } from './reconWarning';
import { rowCount } from './rowCount';
import { securityMarketData } from './securityMarketData';
import { size } from './size';
import { sizedAvg } from './sizedAvg';
import { stringArray } from './stringArray';
import { subAccount } from './subAccount';
import { subAccountName } from './subAccountName';
import { thresholdAsset } from './thresholdAsset';
import { thresholdType } from './thresholdType';
import { toggle } from './toggle';
import { transferDescription } from './transferDescription';
import { user } from './user';
import { warning } from './warning';
import { windowDuration } from './windowDuration';

export * from './autoGroupSortingDefaults';
export * from './baseColumn';
export { baseColumn } from './baseColumn';
export { getOrderStatusColor, type FilledPercentColumnParams, type FilledPercentColumnValue } from './filledPercent';
export { getAgGridColId } from './getAgGridColId';

export * from './credentialAge';
export * from './hamburgerMenu';
export type { NumberColumnParams } from './number';
export type { PositionExpiryValue } from './positionExpiry';
export type { PriceParams } from './price.types';
export type { SizeColumnParams } from './size.types';
export type { StringArrayColumnParams, StringArrayColumnValue } from './stringArray';
export type { SubAccountAutocompleteItem, SubAccountNameColumnParams } from './subAccountName';
export type { ToggleParams } from './toggle.types';
export { baseTreeGroupColumnDef } from './treeGroup';
export * from './types';
export type { ColDefFactory, Column } from './types';
export {
  amountObjectComparator,
  numericColumnComparator,
  stringColumnComparator,
  stringColumnComparatorMultiColumn,
} from './utils';
export { AutoHedgingStatus, STRIKE_CLASSNAME };

export const columnTypes = {
  active,
  addressStatus,
  asset,
  bps,
  button,
  bitmap,
  cumBaseQty,
  connectionStatus,
  connectionStatusWithText,
  counterparty,
  counterpartyFromMarketAccount,
  currency,
  custom,
  customer,
  credentialAge,
  creditUsageWarning,
  role,
  date,
  delta,
  email,
  expandRow,
  exposureDefinition,
  exposureStatus,
  exposureUtilization,
  filledCounterAmount,
  filledNotional,
  filledPercent,
  filler,
  formError,
  gamma,
  group,
  hamburgerMenu,
  iconButton,
  id,
  json,
  loanParticipant,
  loanQuoteStatus,
  loanStatus,
  loanTransactionStatus,
  market,
  markets,
  account,
  marketAccount,
  marketAccountGroupDerived,
  marketAccountSourceAccountID,
  marketAccountTradingStatus,
  marketAccountTransfer,
  marketAccounts,
  marketColor,
  mode,
  modeWithMessage,
  number,
  openNotional,
  orderParameters,
  orderStartTime,
  orderStatus,
  percent,
  pmsWarnings,
  positionDirection,
  positionExpiry,
  positionUnderlyingAsset,
  price,
  pricingAggregation,
  feeMode,
  processStep,
  productType,
  productTypeField,
  quoteMarkets,
  quoteSide,
  quoteStatus,
  quoteValue,
  reconCheckpointStatus,
  reconCheckpointEvalStatus,
  reconMatchStatus,
  reconMismatchStatus,
  reconWarning,
  remove,
  revision,
  rho,
  rowCount,
  security,
  securityMarketData,
  side,
  size,
  sizedAvg,
  strategy,
  stringArray,
  subAccount,
  subAccountName,
  positionThresholdMeter,
  prettyText,
  text,
  textArray,
  theta,
  thresholdAsset,
  thresholdType,
  time,
  toggle,
  totalNotional,
  tradeStatus,
  transferDescription,
  tradingMarketAccounts,
  transferStatus,
  autohedgeStatus,
  transactionType,
  addressRoutingType,
  user,
  vega,
  warning,
  windowDuration,
  optionStrike,
  optionSide,
  optionGroup,
  careOrderStatus,
} as const;

declare module './types' {
  export interface Column {
    /** Column type */
    type: keyof typeof columnTypes;
  }

  export type ColumnDefByColumnType<TData> = {
    [K in keyof typeof columnTypes]: Omit<Parameters<(typeof columnTypes)[K]>[0], 'type' | 'field'> &
      ({ type: K; field: Paths<TData> & string } | { type: K; id: string });
  };
  export type ColumnDef<TData> = ColumnDefByColumnType<TData>[keyof typeof columnTypes];
}
