import { isNil } from 'lodash';
import { UpdateActionEnum } from '../../types';
import { logger } from '../../utils';

function rowHasUpdateActionRemove(row: unknown): boolean {
  if (!(row instanceof Object)) {
    return false;
  }

  // EntityAdminPage row - data is in "row.data"
  if (
    'data' in row &&
    row.data instanceof Object &&
    'UpdateAction' in row.data &&
    row.data.UpdateAction === UpdateActionEnum.Remove
  ) {
    return true;
  }

  // Regular row - data is in "row"
  if ('UpdateAction' in row && row.UpdateAction === UpdateActionEnum.Remove) {
    return true;
  }

  return false;
}

export function compileTransactions<R>(
  state: Set<string>,
  rows: R[],
  rowId: string,
  clear = false
): {
  add: R[];
  update: R[];
  remove: R[];
} {
  const thisRun = { add: {}, update: {}, remove: {} };
  for (const row of rows) {
    const id = row[rowId];

    if (id != null) {
      if (state.has(id)) {
        if (rowHasUpdateActionRemove(row)) {
          thisRun.remove[id] = row;
        } else {
          thisRun.update[id] = row;
        }
      } else {
        thisRun.add[id] = row;
      }
    } else {
      // Log id == null occurrence
      logger.warn(`Missing required ${rowId} in AgGrid row`, {
        extra: {
          row: row,
        },
      });
    }
  }

  for (const id of Object.keys(thisRun.add)) {
    state.add(id);
  }

  for (const id of Object.keys(thisRun.remove)) {
    state.delete(id);
  }

  if (clear) {
    for (const id of state.values()) {
      if (thisRun.add[id] == null && thisRun.update[id] == null) {
        thisRun.remove[id] = { [rowId]: id };
        state.delete(id);
      }
    }
  }

  return {
    add: Object.values(thisRun.add),
    update: Object.values(thisRun.update),
    remove: Object.values(thisRun.remove),
  };
}

export function filterExistsAndExcludes<T, R>(
  filter: T,
  filterKey: keyof T,
  data: R | undefined,
  dataKey: keyof R
): boolean {
  if (!data) {
    return false;
  }
  // Pass if filter for key is not defined
  const filterPart = filter[filterKey];
  if (!(filterPart && filterPart instanceof Array)) {
    return false;
  }
  // If filter exists, exclude if property from data is in the array
  // Use isNil because of `false` and `0` being possible filter values.
  if (filterPart.length > 0 && !isNil(data[dataKey]) && !filterPart.includes(data[dataKey])) {
    return true;
  }
  // Special case having values which are undefined match for filters of empty string
  if (filterPart.length > 0 && data[dataKey] === undefined && !filterPart.includes('')) {
    return true;
  }
  // For now have filters which are defined but empty behave as all pass...
  return false;
}

export const DEFAULT_MAX_ROWS = 10_000;
export const DEFAULT_PAGINATION_SIZE = 500;
export const DEFAULT_BIGGER_PAGINATION_SIZE = 5_000;
