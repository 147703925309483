import { defineMessages } from 'react-intl';
import { getIntlKey } from '../../contexts';
import { useIntl } from '../../hooks';
import { isKeyIn } from '../../utils';
import { Button, ButtonVariants } from '../Button';
import { FormControlSizes } from '../Form/types';
import { Container } from './styles';

export type QuickOptionsProps<T> = {
  options: {
    label: string;
    value: T;
  }[];
  onOptionClick: (value: T) => void;
  disabled?: boolean;
  tabbable?: boolean;
  isOptionDisabled?(value: T): boolean;
};

const messages = defineMessages({
  bid: {
    id: 'QuickOptions.bid',
    defaultMessage: 'Bid',
  },
  offer: {
    id: 'QuickOptions.offer',
    defaultMessage: 'Offer',
  },
});

export const QuickOptions = <T,>({
  options,
  onOptionClick,
  disabled = false,
  tabbable = false,
  isOptionDisabled,
}: QuickOptionsProps<T>) => {
  const { formatMessage } = useIntl();
  return (
    <Container>
      {options.map((option, i) => {
        const labelIntlKey = getIntlKey(option.label);
        return (
          <Button
            key={i}
            data-testid={`QuickOptions-${option.label}`}
            onClick={() => onOptionClick(option.value)}
            variant={ButtonVariants.Default}
            size={FormControlSizes.Tiny}
            disabled={disabled || isOptionDisabled?.(option.value)}
            tabIndex={tabbable ? undefined : -1}
          >
            {isKeyIn(labelIntlKey, messages) ? formatMessage(messages[labelIntlKey]) : option.label}
          </Button>
        );
      })}
    </Container>
  );
};
