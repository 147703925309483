import type { CellStyle, GridOptions, RowClassParams } from 'ag-grid-community';
import type { UseBlotterTableProps } from 'components/BlotterTable/useBlotterTable/types';
import { cloneDeep, get, keys, mapValues } from 'lodash';
import { useCallback, useMemo, useRef, useState } from 'react';
import { map, pipe } from 'rxjs';
import { useUserContext } from '../../contexts/UserContext';
import { useConstant, useDynamicCallback, useWSFilterPipe } from '../../hooks';
import { AppwideDrawerContentType, useAppwideDrawerContext } from '../../providers/AppwideDrawerProvider';
import { useGlobalToasts } from '../../providers/GlobalToastsProvider';
import { DELETE, EMPTY_ARRAY, EMPTY_OBJECT, isTimeActive, PATCH, POST, request } from '../../utils';
import {
  baseTreeGroupColumnDef,
  BlotterDensity,
  DEFAULT_BLOTTER_SELECTION_SINGLE_PARAMS,
  filterExistsAndExcludes,
  useAccordionFilterBuilder,
  useGenericFilter,
  usePersistedBlotterTable,
  type BlotterTableSort,
  type ColumnDef,
  type CompositePipeFunction,
} from '../BlotterTable';
import type { FilterableProperty } from '../Filters';
import { NotificationVariants } from '../Notification';
import { EntityAdminDrawer, GenericDrawerTypeEnum } from './EntityAdminDrawer';
import type { EntityAdminPageProps } from './EntityAdminPage';
import { ENTITY_INTERNAL_ROW_ID, EntityPageClass, type EntityPageRecord } from './types';
import {
  applyInheritanceCellStyle,
  getAddChildEntityColumn,
  getDeleteColumn,
  getEditColumn,
  getEntitiesByParentIDMap,
  getModeColumn,
  type HierarchicalColumnProps,
} from './utils';

export interface useEntityAdminPageProps<TRecord extends EntityPageRecord, TDrawerRecord extends TRecord = TRecord>
  extends Omit<
    EntityAdminPageProps<TRecord, TDrawerRecord>,
    | 'blotterTable'
    | 'entityDrawer'
    | 'openEntityDrawer'
    | 'handleOnSaveEntity'
    | 'handleOnDeleteEntity'
    | 'handleOnUpdateEntity'
    | 'handleOnCreateNewEntity'
  > {
  /** The path for the GET API endpoint. */
  path?: string;

  /** The density of the table. */
  density?: BlotterDensity;

  /** Function to determine the POST path based on the entity. */
  getPostPath?: (entity: TRecord) => string;

  /** Function to determine the PATCH or DELETE path based on the entity. */
  getPatchDeletePath?: (entity: TRecord) => string;

  /** The name of the entity. */
  entityName?: string;

  /** The request API endpoint override. */
  apiEndpointOverride?: string;

  /** The columns to display in the table. If blank, all columns will be generated and displayed. */
  columns?: ColumnDef<TRecord>[];

  /** The filter options to display in the table. */
  filterableProperties?: FilterableProperty[];

  /** Whether to allow mode switching. */
  allowModeSwitch?: boolean;

  /** Function to filter the list of entities. */
  filterFunc?: (entity: TRecord) => boolean;

  /** The group column definition. */
  groupColumnDef?: GridOptions<EntityPageClass<TRecord>>['autoGroupColumnDef'];

  /** The Blotter API ref */
  blotterTableApiRef?: React.MutableRefObject<{ refresh?: (force?: boolean) => void }>;

  /** Function to construct a custom entity for the patch request. Helpful if there are custom API reqs outside the drawer's responsibilities. */
  getEntityForPost?: (entity: TDrawerRecord) => TRecord;
}

type UseEntityAdminPage<
  TData extends EntityPageRecord,
  TRow extends EntityPageClass<TData>,
  TDrawerRecord extends TData
> = Omit<EntityAdminPageProps<TData, TDrawerRecord>, 'blotterTable'> & {
  blotterTableProps: Omit<UseBlotterTableProps<TRow>, 'dataObservable' | 'rowID'> & {
    rowID: string;
    startingColumns: ColumnDef<TData>[];
    endingColumns: ColumnDef<TData>[];
    // If we were stronger typed, this would be BlotterTableSort<TData>
    initialSort?: BlotterTableSort<TRow>;
    pipe?: CompositePipeFunction<TRow>;
  };
};

export const useEntityAdminPage = <TRecord extends EntityPageRecord, TDrawerRecord extends TRecord>(
  props: useEntityAdminPageProps<TRecord, TDrawerRecord>
): UseEntityAdminPage<TRecord, EntityPageClass<TRecord>, TDrawerRecord> => {
  const {
    childIDField,
    entityIDField,
    filterableProperties = EMPTY_ARRAY,
    allowEditEntity = false,
    groupColumnDef: userTreeGroupColumnDef = EMPTY_OBJECT,
    columns: _columns = EMPTY_ARRAY,
    density = BlotterDensity.Comfortable,
    apiEndpointOverride,
    entityName = 'Entity',
    blotterTableApiRef,
    allowAddEntity = false,
    allowDeleteEntity = false,
    allowModeSwitch = false,
    addChildEntityButtonProps,
    persistKey = null,
    getEditEntityName,
    drawerOptions,
    getEntityDrawerOptions = () => drawerOptions,
    sendNullForEmptyFields = true,
    postEntityInArray = false,
    getEntityForPost = (entity: TDrawerRecord) => entity satisfies TRecord,
    drawerType = GenericDrawerTypeEnum.InputsAndDropdowns,
  } = props;

  if ((allowAddEntity || allowEditEntity || allowDeleteEntity) && props.path == null) {
    throw new Error('path is required when allowAddEntity, allowEditEntity, or allowDeleteEntity is true');
  }

  // Its very important that we keep props like these stable. Hence we memoize the things we are defaulting to keep the defaults stable.
  // If editing is allowed, props.path will never be undefined.
  const getPostPath = useMemo(
    () => props.getPostPath ?? ((_: TRecord) => props.path!),
    [props.getPostPath, props.path]
  );
  const userFilterFunc = useMemo(() => props.filterFunc ?? (() => true), [props.filterFunc]);
  const getPatchDeletePath = useMemo(
    () => props.getPatchDeletePath ?? ((entity: TRecord) => `${getPostPath(entity)}/${get(entity, entityIDField)}`),
    [props.getPatchDeletePath, getPostPath, entityIDField]
  );

  const [selectedEntity, setSelectedEntity] = useState<TRecord | undefined>();
  const [addingChildEntity, setAddingChildEntity] = useState<boolean>(false);
  const [quickFilterText, setQuickFilterText] = useState<string>('');
  const { openDrawer, closeDrawer } = useAppwideDrawerContext();

  const { orgApiEndpoint } = useUserContext();
  const apiEndpoint = apiEndpointOverride ?? orgApiEndpoint;
  const { add: addToast } = useGlobalToasts();

  const getEntityDrawerTitle = useDynamicCallback((setupEntity: TRecord | undefined, setupIsChildEntity: boolean) => {
    if (setupIsChildEntity && setupEntity != null) {
      const childEntityName = addChildEntityButtonProps?.text;
      return `New ${childEntityName}`;
    }
    if (setupEntity != null) {
      return `Modify ${getEditEntityName?.(setupEntity) ?? entityName}`;
    }
    return `New ${entityName}`;
  });

  const openEntityDrawer: HierarchicalColumnProps<TRecord>['openEntityDrawer'] = useDynamicCallback(
    (setupEntity: TRecord | undefined, setupIsChildEntity: boolean) => {
      setSelectedEntity(setupEntity);
      setAddingChildEntity(setupIsChildEntity);
      openDrawer({
        type: AppwideDrawerContentType.EntityAdminPage,
        title: getEntityDrawerTitle(setupEntity, setupIsChildEntity),
        renderContent: () => (
          <EntityAdminDrawer<TRecord, TDrawerRecord>
            key={`${JSON.stringify(setupEntity)}`}
            drawerOptions={getEntityDrawerOptions(setupEntity, setupIsChildEntity)}
            drawerType={drawerType}
            addingChildEntity={setupIsChildEntity}
            selectedEntity={setupEntity}
            handleOnSaveEntity={handleOnSaveEntity}
            handleOnDeleteEntity={handleOnDeleteEntity}
            allowAddEntity={allowAddEntity}
            allowEditEntity={allowEditEntity}
            allowDeleteEntity={allowDeleteEntity}
          />
        ),
      });
    }
  );

  const closeEntityDrawer = useDynamicCallback(() => {
    closeDrawer();
    setSelectedEntity(undefined);
    setAddingChildEntity(false);
  });

  type OnRowDoubleClicked = NonNullable<GridOptions<EntityPageClass<TRecord>>['onRowDoubleClicked']>;
  const onRowDoubleClicked = useDynamicCallback<OnRowDoubleClicked>(params => {
    if (params.data && allowEditEntity) {
      openEntityDrawer(cloneDeep(params.data?.data), false);
    }
  });

  const getRowStyle = useDynamicCallback((params: RowClassParams<EntityPageClass<TRecord>>): CellStyle | undefined => {
    const startTime = get(params.data?.data, 'StartTime');
    const endTime = get(params.data?.data, 'EndTime');
    // If the entity has a StartTime and an EndTime, reduce the opacity if the entity is not active.
    if (startTime != null && endTime != null && !isTimeActive(startTime, endTime)) {
      return { opacity: 0.5 };
    }
  });

  // AgGrid wants this return type to be a string[] but it's actually stronger typed as TRecord[keyof TRecord][]
  const getDataPath = useDynamicCallback((data: TRecord): string[] => {
    // If the childIDField is provided, we treat the data as hierarchical.
    if (childIDField != null && get(data, childIDField)) {
      return [get(data, entityIDField), get(data, childIDField)] satisfies TRecord[keyof TRecord][] as string[];
    }
    return [get(data, entityIDField)] satisfies TRecord[keyof TRecord][] as string[];
  });

  const getUniqueKey = useDynamicCallback((entity: TRecord) => getDataPath(entity).join('-'));

  const filterResults = useGenericFilter(filterableProperties);
  const filterBuilderAccordion = useAccordionFilterBuilder({
    accordionProps: { initialOpen: keys(filterResults.filter).length > 0 },
    filterBuilderProps: filterResults.filterBuilderProps,
  });

  const filterFunc = useCallback(
    (entity: TRecord) => {
      let filteredOut = false;
      filterableProperties.forEach(property => {
        if (property.field == null) {
          throw new Error('Field is required for all filterable properties of EntityAdminPage');
        }
        if (filterExistsAndExcludes(filterResults.filter, property.key, entity, property.field as keyof TRecord)) {
          filteredOut = true;
        }
      });
      return !filteredOut;
    },
    [filterResults.filter, filterableProperties]
  );

  const filterPipe = useWSFilterPipe<TRecord>({ getUniqueKey, filterFunc });
  const parentMapRef = useRef<Map<keyof TRecord, TRecord> | undefined>();
  const blotterTablePipe: CompositePipeFunction<EntityPageClass<TRecord>, TRecord> = useConstant(
    pipe(
      filterPipe,
      map(json => {
        parentMapRef.current = getEntitiesByParentIDMap(json.data, entityIDField, childIDField);
        return json;
      }),
      map(json => ({
        ...json,
        data: json.data
          .filter(item => userFilterFunc(item))
          .map(row => new EntityPageClass<TRecord>(cloneDeep(row), entityIDField, childIDField, parentMapRef.current)),
      }))
    )
  );

  const treeDataProps: Pick<GridOptions<any>, 'autoGroupColumnDef' | 'treeData' | 'getDataPath'> | undefined =
    useMemo(() => {
      if (childIDField != null) {
        // If the childIDField is provided, we treat the data as hierarchical.
        return {
          autoGroupColumnDef: { ...baseTreeGroupColumnDef, ...userTreeGroupColumnDef },
          treeData: true,
          getDataPath: (entity: EntityPageClass<TRecord>) => {
            return getDataPath(entity.data);
          },
        };
      }
      return undefined;
    }, [childIDField, getDataPath, userTreeGroupColumnDef]);

  const styledColumns = useMemo(() => {
    if (childIDField != null) {
      // If the childIDField is provided, we treat the data as hierarchical.
      return _columns?.map(applyInheritanceCellStyle);
    }
    return _columns;
  }, [_columns, childIDField]);

  const getPathWithApiEndpoint = useDynamicCallback((path: string) => `${apiEndpoint}${path}`);

  const postEntity = useDynamicCallback((entity: TRecord) =>
    request(POST, getPathWithApiEndpoint(getPostPath(entity)), postEntityInArray ? [entity] : entity)
  );
  const patchEntity = useDynamicCallback((entity: TRecord) =>
    request(PATCH, getPathWithApiEndpoint(getPatchDeletePath(entity)), entity)
  );
  const deleteEntity = useDynamicCallback((entity: TRecord) =>
    request(DELETE, getPathWithApiEndpoint(getPatchDeletePath(entity)))
  );

  const handleOnUpdateEntity = useDynamicCallback(async (updatedEntity: TRecord | TDrawerRecord) => {
    // If sendNullForEmptyFields is true, convert empty strings to null
    const entityForPatch: TRecord = sendNullForEmptyFields
      ? (mapValues(cloneDeep(updatedEntity), (value: unknown) => (value === '' ? null : value)) as TRecord)
      : updatedEntity;

    return patchEntity(entityForPatch)
      .then(({ data }: { data: TRecord[] }) => {
        blotterTableApiRef?.current.refresh?.();
        addToast({
          text: `${entityName} entity saved successfully.`,
          variant: NotificationVariants.Positive,
        });
        return data.at(0)!;
      })
      .catch(error => {
        addToast({
          text: error.toString() ?? `Failed to save ${entityName} entity.`,
          variant: NotificationVariants.Negative,
        });
        return Promise.reject();
      });
  });

  const handleOnDeleteEntity = useDynamicCallback(async (selectedEntity: TRecord) => {
    return deleteEntity(selectedEntity)
      .then(() => {
        closeEntityDrawer();
        blotterTableApiRef?.current.refresh?.(true);
        addToast({
          text: 'Entity deleted successfully.',
          variant: NotificationVariants.Positive,
        });
        return;
      })
      .catch(error => {
        addToast({
          text: error.toString() ?? 'Failed to delete entity',
          variant: NotificationVariants.Negative,
        });
        return Promise.reject();
      });
  });

  const handleOnCreateNewEntity = useDynamicCallback(async (newEntity: TDrawerRecord) => {
    return postEntity(getEntityForPost(newEntity))
      .then(({ data }: { data: TRecord[] }) => {
        closeEntityDrawer();
        blotterTableApiRef?.current.refresh?.();
        addToast({
          text: `${entityName} entity created successfully.`,
          variant: NotificationVariants.Positive,
        });
        return data.at(0)!;
      })
      .catch(error => {
        addToast({
          text: error.toString() ?? `Failed to create ${entityName} entity.`,
          variant: NotificationVariants.Negative,
        });
        return Promise.reject();
      });
  });

  const handleOnSaveEntity = useDynamicCallback((entity: TDrawerRecord) => {
    if (selectedEntity == null || addingChildEntity) {
      return handleOnCreateNewEntity(entity);
    } else {
      return handleOnUpdateEntity(entity);
    }
  });

  const startingColumns = useMemo(() => {
    const colDefs: ColumnDef<TRecord>[] = [];

    if (allowModeSwitch) {
      colDefs.push(
        getModeColumn<TRecord>({
          handleOnClick: (entity: TRecord) => handleOnUpdateEntity(entity),
        })
      );
    }

    return colDefs;
  }, [allowModeSwitch, handleOnUpdateEntity]);

  const endingColumns = useMemo(() => {
    const colDefs: ColumnDef<TRecord>[] = [];

    if (allowAddEntity && childIDField != null) {
      colDefs.push(
        getAddChildEntityColumn<TRecord>({
          openEntityDrawer: openEntityDrawer,
          buttonProps: addChildEntityButtonProps,
          entityIDField: entityIDField,
          childIDField: childIDField,
        })
      );
    }

    if (allowEditEntity) {
      colDefs.push(
        getEditColumn<TRecord>({
          handleOnClick: (entity: TRecord) => {
            openEntityDrawer(entity, false);
          },
        })
      );
    }

    if (allowDeleteEntity) {
      colDefs.push(
        getDeleteColumn<TRecord>({
          handleOnClick: (entity: TRecord) => {
            if (window.confirm('Are you sure you want to delete this entity?')) {
              handleOnDeleteEntity(entity);
            }
          },
        })
      );
    }

    return colDefs;
  }, [
    addChildEntityButtonProps,
    allowAddEntity,
    allowDeleteEntity,
    allowEditEntity,
    childIDField,
    entityIDField,
    handleOnDeleteEntity,
    openEntityDrawer,
  ]);

  const persistedTable = usePersistedBlotterTable<TRecord>(persistKey, {
    columns: styledColumns,
    persistColumns: persistKey != null,
    persistFilter: persistKey != null,
    persistSort: persistKey != null,
  });

  const value = useMemo(() => {
    return {
      blotterTableProps: {
        rowID: ENTITY_INTERNAL_ROW_ID,
        density,
        // We need this casting due to the way "pipe" is typed.
        pipe: blotterTablePipe as CompositePipeFunction<EntityPageClass<TRecord>, unknown>,
        quickSearchParams: {
          filterText: quickFilterText,
        },
        startingColumns,
        endingColumns,
        columns: persistedTable.columns,
        onColumnsChanged: persistedTable.onColumnsChanged,
        onSortChanged: persistedTable.onSortChanged,
        // We need this casting due to the way "persistedTable" is typed.
        initialSort: persistedTable.initialSort as BlotterTableSort<EntityPageClass<TRecord>>,
        gridOptions: {
          ...treeDataProps,
          rowSelection: allowEditEntity ? DEFAULT_BLOTTER_SELECTION_SINGLE_PARAMS : undefined,
          getRowStyle,
          onRowDoubleClicked,
        },
      },
      childIDField,
      blotterTableFilters: {
        ...filterBuilderAccordion,
        onQuickFilterTextChanged: setQuickFilterText,
        showFilterBuilder: filterableProperties.length > 0,
      },
      openEntityDrawer,
      handleOnSaveEntity,
      handleOnDeleteEntity,
      handleOnUpdateEntity,
      handleOnCreateNewEntity,
      addingChildEntity,
      selectedEntity,
      ...props,
    } satisfies UseEntityAdminPage<TRecord, EntityPageClass<TRecord>, TDrawerRecord>;
  }, [
    density,
    blotterTablePipe,
    quickFilterText,
    startingColumns,
    endingColumns,
    persistedTable.columns,
    persistedTable.onColumnsChanged,
    persistedTable.onSortChanged,
    persistedTable.initialSort,
    treeDataProps,
    allowEditEntity,
    getRowStyle,
    onRowDoubleClicked,
    childIDField,
    filterBuilderAccordion,
    filterableProperties.length,
    openEntityDrawer,
    handleOnSaveEntity,
    handleOnDeleteEntity,
    handleOnUpdateEntity,
    handleOnCreateNewEntity,
    addingChildEntity,
    selectedEntity,
    props,
  ]);

  return value;
};
