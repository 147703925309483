import type { IRowNode } from 'ag-grid-community';
import { useMemo } from 'react';
import type { DefaultTheme } from 'styled-components';
import { getOrderStatusColor, type FilledPercentColumnParams } from '../components/BlotterTable/columns/filledPercent';
import { getAgGridColId } from '../components/BlotterTable/columns/getAgGridColId';
import type { Column, ColumnDef } from '../components/BlotterTable/columns/types';
import { useDefaultColumns } from '../components/BlotterTable/useDefaultColumns';
import type { ExpectTrue } from '../tests';
import { EMPTY_ARRAY } from '../utils/empty';
import { isOrderPending } from '../utils/isOrderPending';
import type { ICareExecutionReport } from './types';
import { OrdStatusEnum, type CxlRejReasonEnum, type ExecTypeEnum, type OrdRejReasonEnum, type SideEnum } from './types';

export function isCareExecutionReport(entity: any): entity is CareExecutionReport {
  return entity instanceof CareExecutionReport;
}

export class CareExecutionReport {
  static readonly rowID = 'OrderID';

  Timestamp: string;
  User: string;
  Symbol: string;
  Currency: string;
  OrderID: string;
  ClOrdID: string;
  OrigClOrdID: string;
  SubmitTime: string;
  ExecID: string;
  Side: SideEnum;
  TransactTime: string;
  ExecType: ExecTypeEnum;
  OrdStatus: OrdStatusEnum;
  OrderQty: string | undefined;
  LeavesQty: string | undefined;
  CumQty: string | undefined;
  OrdRejReason: OrdRejReasonEnum | undefined;
  CxlRejReason: CxlRejReasonEnum | undefined;
  RequestUser: string;
  Revision: number;
  OrgID: number;
  Text: string;
  Group: string;
  Comments: string;
  CumAmt?: string;
  AvgPx?: string;
  LastPx?: string;
  LastQty?: string;
  LastAmt?: string;
  AmountCurrency: string;
  Counterparty: string;
  LastExecID: string;
  Annotations: unknown;

  get isCancelable(): boolean {
    return !(this.isPendingOrdStatus || this.isComplete);
  }

  get isComplete(): boolean {
    return [OrdStatusEnum.Canceled, OrdStatusEnum.Filled, OrdStatusEnum.Rejected, OrdStatusEnum.DoneForDay].includes(
      this.OrdStatus
    );
  }

  // Private for now to encourage using the more abstract getters (isCancelable etc.)
  private get isPendingOrdStatus(): boolean {
    return isOrderPending(this.OrdStatus);
  }

  constructor(data: ICareExecutionReport | Omit<CareExecutionReport, 'isCancelable' | 'isComplete'>) {
    this.Timestamp = data.Timestamp;
    this.User = data.User;
    this.Symbol = data.Symbol;
    this.OrderID = data.OrderID;
    this.ClOrdID = data.ClOrdID;
    this.OrigClOrdID = data.OrigClOrdID;
    this.SubmitTime = data.SubmitTime;
    this.ExecID = data.ExecID;
    this.Side = data.Side;
    this.TransactTime = data.TransactTime;
    this.ExecType = data.ExecType;
    this.OrdStatus = data.OrdStatus;
    this.OrderQty = data.OrderQty;
    this.LeavesQty = data.LeavesQty;
    this.Currency = data.Currency;
    this.CumQty = data.CumQty;
    this.OrdRejReason = data.OrdRejReason;
    this.CxlRejReason = data.CxlRejReason;
    this.RequestUser = data.RequestUser;
    this.Revision = data.Revision;
    this.OrgID = data.OrgID;
    this.Text = data.Text;
    this.Group = data.Group;
    this.Comments = data.Comments;
    this.AmountCurrency = data.AmountCurrency;
    this.Counterparty = data.Counterparty;
    this.CumAmt = data.CumAmt;
    this.AvgPx = data.AvgPx;
    this.LastExecID = data.LastExecID;
    this.LastPx = data.LastPx;
    this.LastQty = data.LastQty;
    this.LastAmt = data.LastAmt;
    this.LastExecID = data.LastExecID;
    this.Annotations = data.Annotations;
  }
}

interface UseCareExecutionReportColumns {
  defaultColumns?: (keyof CareExecutionReport | Partial<Column>)[];
}

export function useCareExecutionReportColumns({
  defaultColumns = EMPTY_ARRAY,
}: UseCareExecutionReportColumns): Column[] {
  const defaultVisibleColumns = useMemo(
    () =>
      new Map(
        (
          [
            {
              field: 'SubmitTime',
              type: 'date',
              sortable: true,
              sort: '-',
            },
            {
              field: 'Side',
              type: 'side',
              sortable: true,
            },
            {
              field: 'Symbol',
              type: 'security',
              sortable: true,
            },
            {
              field: 'OrdStatus',
              type: 'orderStatus',
              sortable: true,
            },
            {
              type: 'filledPercent',
              id: 'filledPercent',
              params: {
                filledQtyField: 'CumQty',
                totalQtyField: 'OrderQty',
                getColor: getCareExecutionReportMeterColor,
              } satisfies FilledPercentColumnParams<CareExecutionReport>,
            },
            {
              field: 'OrderQty',
              type: 'size',
              sortable: true,
              params: { currencyField: 'Currency' },
            },
            {
              field: 'LeavesQty',
              type: 'size',
              sortable: true,
              params: { currencyField: 'Currency' },
            },
            {
              field: 'User',
              type: 'user',
              sortable: true,
            },
            {
              field: 'OrderID',
              type: 'id',
            },
            {
              field: 'ClOrdID',
              type: 'id',
            },
          ] satisfies ColumnDef<CareExecutionReport>[]
        ).map(c => [getAgGridColId(c), c])
      ),
    []
  );
  const defaultHiddenColumns = useMemo(() => {
    return new Map(
      (
        [
          {
            field: 'ExecID',
            type: 'id',
          },
          {
            field: 'ExecType',
            type: 'text',
            sortable: true,
          },
          {
            field: 'TransactTime',
            type: 'date',
            sortable: true,
          },
          {
            field: 'Text',
            type: 'text',
            sortable: true,
          },
          {
            field: 'Timestamp',
            type: 'date',
            sortable: true,
          },
          {
            field: 'RequestUser',
            type: 'user',
            sortable: true,
          },
          {
            field: 'Revision',
            type: 'text',
          },
          {
            field: 'OrigClOrdID',
            type: 'id',
          },
          {
            field: 'OrdRejReason',
            type: 'text',
          },
          {
            field: 'CxlRejReason',
            type: 'text',
          },
          {
            field: 'isCancelable',
            type: 'text',
          },
          {
            field: 'isComplete',
            type: 'text',
          },
        ] satisfies (false | ColumnDef<CareExecutionReport>)[]
      ).map(c => [getAgGridColId(c), { ...c, hide: true }])
    );
  }, []);
  const columnDefinitions = useMemo(() => {
    return new Map(
      (
        [
          ...defaultVisibleColumns.values(),
          ...defaultHiddenColumns.values(),
        ] satisfies ColumnDef<CareExecutionReport>[] as Column[]
      ).map(c => [getAgGridColId(c), c])
    );
  }, [defaultVisibleColumns, defaultHiddenColumns]);
  return useDefaultColumns(defaultColumns, columnDefinitions);
}

function getCareExecutionReportMeterColor(node: IRowNode<CareExecutionReport>, theme: DefaultTheme) {
  if (!node.data) {
    return;
  }
  // If these dont exist we arent gonna show any color anyway
  const { OrderQty, CumQty } = node.data;
  if (!OrderQty || !CumQty) {
    return undefined;
  }

  return getOrderStatusColor({ ...node.data, OrderQty, CumQty }, theme);
}

// TYPE LEVEL TESTS
type OmittedClassKeys = 'isCancelable' | 'isComplete';
type _Expect_CareExecutionReport_To_Only_Have_Keys_From_ICareExecutionReport = ExpectTrue<
  {
    [K in Exclude<keyof CareExecutionReport, OmittedClassKeys> & string]: K extends keyof ICareExecutionReport
      ? true
      : K;
  }[Exclude<keyof CareExecutionReport, OmittedClassKeys>]
>;

type OmittedInterfaceKeys = 'MessageID';
type _Expect_All_Keys_In_ICareExecutionReport_To_Be_In_CareExecutionReport = ExpectTrue<
  {
    [K in keyof Exclude<ICareExecutionReport, OmittedInterfaceKeys> & string]: K extends keyof CareExecutionReport
      ? true
      : K;
  }[Exclude<keyof ICareExecutionReport, OmittedInterfaceKeys>]
>;
