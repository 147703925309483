import {
  type Aggregation,
  type AllInTier,
  type Currency,
  type Customer,
  CustomerAddressTypeEnum,
  CustomerExecutionStrategyEnum,
  type CustomerSecurity,
  type CustomerUser,
  type FeeTier,
  type MarketFeeModeEnum,
  OrdTypeEnum,
  type Security,
  type SymbolGroup,
  TimeInForceEnum,
} from '../../types';
import { prettyName } from '../../utils';
import type { InputsAndDropdownsDrawerDropdownOption } from './InputsAndDropdownsDrawer';

export function getCustomerUserDrawerOptions(
  customerUsers: Pick<CustomerUser, 'Email'>[]
): InputsAndDropdownsDrawerDropdownOption[] {
  return customerUsers.map(({ Email }) => ({
    value: Email,
    label: Email, // Add preference to use DisplayName ?
  }));
}

/**
 * Returns an array of 24-hour time options in 15-minute intervals.
 */
export function getTimeDrawerOptions(): InputsAndDropdownsDrawerDropdownOption[] {
  return Array.from({ length: 24 }, (_, h) => {
    const hours = h < 10 ? `0${h}` : h;
    return ['00', '15', '30', '45']
      .map(minutes => `${hours}:${minutes}:00`)
      .map(time => ({ value: time, label: `${time} UTC` }));
  }).flat();
}

export function getCustomerDrawerOptions(
  customers: Pick<Customer, 'Name' | 'DisplayName'>[]
): InputsAndDropdownsDrawerDropdownOption[] {
  return customers.map(({ Name, DisplayName }) => ({
    value: Name,
    label: DisplayName ?? Name,
  }));
}

export function getSecurityDrawerOptions(
  securities: Pick<CustomerSecurity | Security, 'Symbol' | 'DisplaySymbol' | 'Description'>[]
): InputsAndDropdownsDrawerDropdownOption[] {
  return securities.map(({ Symbol, DisplaySymbol, Description }) => ({
    value: Symbol,
    label: DisplaySymbol ?? Symbol,
    description: Description,
  }));
}

export function getCurrencyDrawerOptions(
  currencies: Pick<Currency, 'Symbol' | 'Description'>[]
): InputsAndDropdownsDrawerDropdownOption[] {
  return currencies.map(({ Symbol, Description }) => ({
    value: Symbol,
    label: Symbol,
    description: Description,
  }));
}

export function getAddressTypeDrawerOptions(): InputsAndDropdownsDrawerDropdownOption[] {
  return [
    { value: CustomerAddressTypeEnum.Deposit, label: 'Deposit' },
    { value: CustomerAddressTypeEnum.Withdrawal, label: 'Withdrawal' },
  ];
}

export function getOrdTypeDrawerOptions(): InputsAndDropdownsDrawerDropdownOption[] {
  return [
    {
      value: OrdTypeEnum.Limit,
      label: 'Limit',
    },
    {
      value: OrdTypeEnum.Market,
      label: 'Market',
    },
  ];
}

enum FIXConnectionTypeEnum {
  MarketData = 'MARKETDATA',
  Orders = 'ORDERS',
  DropCopy = 'DROPCOPY',
}

export function getConnectionTypeDrawerOptions(): InputsAndDropdownsDrawerDropdownOption[] {
  return [
    {
      value: FIXConnectionTypeEnum.Orders,
      label: 'Orders',
      description: 'Orders FIX Connection',
    },
    {
      value: FIXConnectionTypeEnum.DropCopy,
      label: 'Drop Copy',
      description: 'Post-Trade FIX Connection',
    },
    {
      value: FIXConnectionTypeEnum.MarketData,
      label: 'Market Data',
      description: 'MarketDataDepth and MarketDataTrades Connection Types',
    },
  ];
}

export function getExecutionStrategiesDrawerOptions(): InputsAndDropdownsDrawerDropdownOption[] {
  return [
    {
      value: CustomerExecutionStrategyEnum.OrderAcceptance,
      label: 'Order Acceptance',
      description: 'Execute Customer Order Unhedged',
    },
    {
      value: CustomerExecutionStrategyEnum.RisklessPrincipal,
      label: 'Riskless Principal',
      description: 'Execute with a Cover and Deal Strategy',
    },
  ];
}

export function getTimeInForceDrawerOptions(): InputsAndDropdownsDrawerDropdownOption[] {
  return [
    {
      value: TimeInForceEnum.FillAndKill,
      label: 'Fill and Kill',
    },
    {
      value: TimeInForceEnum.FillOrKill,
      label: 'Fill or Kill',
    },
    {
      value: TimeInForceEnum.GoodTillCancel,
      label: 'Good Till Cancel',
    },
  ];
}

export function getPricingAggregationDrawerOptions(
  aggregations: Pick<Aggregation, 'Name' | 'DisplayName'>[]
): InputsAndDropdownsDrawerDropdownOption[] {
  return aggregations.map(({ Name, DisplayName }) => ({
    value: Name,
    label: DisplayName,
  }));
}

export function getFeeModeDrawerOptions(feeModeOptions: MarketFeeModeEnum[]): InputsAndDropdownsDrawerDropdownOption[] {
  return feeModeOptions.map(mode => ({ value: mode, label: prettyName(mode) }));
}

export function getTierDrawerOptions(
  tiers: Pick<AllInTier | FeeTier | SymbolGroup, 'Tier'>[]
): InputsAndDropdownsDrawerDropdownOption[] {
  return tiers.map(({ Tier }) => ({ value: Tier, label: Tier }));
}
