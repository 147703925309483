import { noop } from 'lodash';
import type { SubscriptionResponse } from '../types/SubscriptionResponse';

import {
  BUYING_POWER,
  CARE_EXECUTION_REPORT,
  CARE_ORDER,
  CUSTOMER,
  CUSTOMER_BALANCE,
  CUSTOMER_BALANCE_TRANSACTION,
  CUSTOMER_MAX_ORDER_SIZE_LIMIT,
  CUSTOMER_ORDER,
  CUSTOMER_QUOTE,
  CUSTOMER_QUOTE_HISTORY,
  CUSTOMER_TRADE,
  CUSTOMER_TRANSACTION,
  CUSTOMER_USER,
  EXECUTION_REPORT,
  FIXING_INDEX,
  HEDGE_ORDER_STATUS,
  LOAN_QUOTE_BORROWER,
  LOAN_QUOTE_LENDER,
  MARKET_FIXING_INDEX,
  MARKET_ORDER,
  MARKET_QUOTE,
  ORDER,
  PORTFOLIO_TREASURY_LINKS,
  POSITION,
  POSITION_SUB_ACCOUNT,
  QUOTE,
  QUOTE_HISTORY,
  RECON_MISMATCH,
  RECON_STATUS,
  TRADE,
  TRADE_SETTLE_REPORT,
} from '../tokens/requestTypes';

import { Customer } from '../types/Customer';
import { CustomerBalance, CustomerBalanceTransaction, CustomerTransaction } from '../types/CustomerBalance';
import { CustomerOrder } from '../types/CustomerOrder';
import { CustomerQuote } from '../types/CustomerQuote';
import { CustomerTrade } from '../types/CustomerTrade';
import { CustomerTradingLimit } from '../types/CustomerTradingLimit';
import { CustomerUser } from '../types/CustomerUser';
import { LoanQuote } from '../types/LoanQuote';
import { MarketFixingIndex } from '../types/MarketFixingIndex';
import { MarketOrder } from '../types/MarketOrder';
import { MarketQuote } from '../types/MarketQuote';
import { Order } from '../types/Order';
import { Position } from '../types/Position';
import { Quote } from '../types/Quote';
import { ReconMismatch } from '../types/ReconMismatch';
import { ReconStatus } from '../types/ReconStatus';
import { Trade } from '../types/Trade';
import { TreasuryLink } from '../types/TreasuryLink';

import {
  AssetTransaction,
  CareExecutionReport,
  CareOrder,
  ExecutionReport,
  FixingIndex,
  Transfer,
  type RequestStream,
} from '../types';
import { BidAskSpreadsDatapoint } from '../types/Analytics/BidAskSpread';
import { MarketOrderOutcomeDatapoints } from '../types/Analytics/MarketOrderOutcome';
import { NumOrdersDatapoint } from '../types/Analytics/NumOrders';
import { OrdersSummaryDatapoint } from '../types/Analytics/OrdersSummary';
import { PostTradeOrderAnalyticsDatapoint } from '../types/Analytics/PostTradeOrderAnalytics';
import { SlippageDatapoint } from '../types/Analytics/Slippage';
import { TotalTradingVolumeStats } from '../types/Analytics/TotalTradingVolume';
import { BuyingPower } from '../types/BuyingPower';
import { HedgeOrderStatus } from '../types/HedgeOrderStatus';
import { LedgerEvent } from '../types/LedgerEvent';
import { TradeSettleReport } from '../types/TradeSettleReport';
import { logger } from '../utils';

const wsTypeMappings = {
  [ORDER]: Order,
  [CARE_ORDER]: CareOrder,
  [CARE_EXECUTION_REPORT]: CareExecutionReport,
  [MARKET_ORDER]: MarketOrder,
  [MARKET_QUOTE]: MarketQuote,
  [EXECUTION_REPORT]: ExecutionReport,
  [QUOTE]: Quote,
  [QUOTE_HISTORY]: Quote,
  [TRADE]: Trade,
  [CUSTOMER]: Customer,
  [CUSTOMER_ORDER]: CustomerOrder,
  [CUSTOMER_QUOTE]: CustomerQuote,
  [CUSTOMER_QUOTE_HISTORY]: CustomerQuote,
  [CUSTOMER_TRADE]: CustomerTrade,
  [LOAN_QUOTE_BORROWER]: LoanQuote,
  [FIXING_INDEX]: FixingIndex,
  [MARKET_FIXING_INDEX]: MarketFixingIndex,
  [LOAN_QUOTE_LENDER]: LoanQuote,
  [CUSTOMER_BALANCE]: CustomerBalance,
  [CUSTOMER_USER]: CustomerUser,
  [CUSTOMER_MAX_ORDER_SIZE_LIMIT]: CustomerTradingLimit,
  [CUSTOMER_TRANSACTION]: CustomerTransaction,
  [RECON_STATUS]: ReconStatus,
  [RECON_MISMATCH]: ReconMismatch,
  [PORTFOLIO_TREASURY_LINKS]: TreasuryLink,
  [POSITION]: Position,
  [POSITION_SUB_ACCOUNT]: Position,
  [BUYING_POWER]: BuyingPower,
  [HEDGE_ORDER_STATUS]: HedgeOrderStatus,
  [TotalTradingVolumeStats.Stream_Name]: TotalTradingVolumeStats,
  [NumOrdersDatapoint.Stream_Name]: NumOrdersDatapoint,
  [LedgerEvent.MarketAccountStreamName]: LedgerEvent,
  [LedgerEvent.SubAccountStreamName]: LedgerEvent,
  [SlippageDatapoint.Stream_Name]: SlippageDatapoint,
  [OrdersSummaryDatapoint.Stream_Name]: OrdersSummaryDatapoint,
  [MarketOrderOutcomeDatapoints.Stream_Name]: MarketOrderOutcomeDatapoints,
  [PostTradeOrderAnalyticsDatapoint.Stream_Name]: PostTradeOrderAnalyticsDatapoint,
  [BidAskSpreadsDatapoint.Stream_Name]: BidAskSpreadsDatapoint,
  [TRADE_SETTLE_REPORT]: TradeSettleReport,
  [AssetTransaction.StreamName]: AssetTransaction,
  [CUSTOMER_BALANCE_TRANSACTION]: CustomerBalanceTransaction,
  [Transfer.StreamName]: Transfer,
} as const;

export type SubscriptionCallback<TData> = (err: unknown | undefined, response?: SubscriptionResponse<TData>) => void;
export class Subscription<TData> {
  address: string;
  reqid: number;
  streams: RequestStream[];
  callback: SubscriptionCallback<TData>;
  active: boolean;
  loadAll: boolean;
  next?: string;
  overrideParticipant?: string;
  data = new Map<unknown, TData>();

  startedAt: number;

  constructor({
    address,
    reqid,
    streams,
    callback,
    overrideParticipant,
    loadAll = true,
  }: {
    address: string;
    reqid: number;
    streams: RequestStream[];
    callback: SubscriptionCallback<TData>;
    loadAll?: boolean;
    overrideParticipant?: string;
  }) {
    this.address = address;
    this.reqid = reqid;
    this.streams = streams;
    this.callback = callback;
    this.active = true;
    this.loadAll = loadAll;
    this.overrideParticipant = overrideParticipant; // todo remove, dont use

    this.startedAt = Date.now();
  }

  handleJson(json: SubscriptionResponse<TData>) {
    const now = Date.now();

    // This guard is important. We only want to update the Subscription.next property if we are looking at a "potentially pageably" response.
    // As in, either an initial or a paged response. Reason being that we could receive a non-peageable response in the middle of paging.
    // As in we're paging for old data and get a new delta update while doing that. We can't have the non-existance of .next on that live update
    // stop us from paging
    if (json.initial || json.page) {
      this.next = json.next; // Allow us to load more pages if they exist
    }

    // If we have been disposed don't do anything
    if (!this.callback) {
      return { loadMore: false };
    }

    this.callback(undefined, instantiateClasses(json));
    const loadMore = this.loadAll && json.next != null && this.streams.length === 1;

    if (json.initial && this.streams.length === 1) {
      logger.trackDuration('subscriptionInitialResponseMs', {
        startTime: this.startedAt,
        duration: now - this.startedAt,
        context: this.streams[0],
        description: this.streams[0].name,
      });
    }

    const didReachLastPage = json.next == null && (json.initial || json.page);
    if (this.loadAll && this.streams.length === 1 && didReachLastPage) {
      // loadAll was set, and we've reached the last page
      logger.trackDuration('subscriptionLoadAllMs', {
        startTime: this.startedAt,
        duration: now - this.startedAt,
        context: this.streams[0],
        description: this.streams[0].name,
      });
    }

    return { loadMore };
  }

  dispose() {
    this.callback = noop;
  }
}

// Attempts to create an instance of the stream's respective class and passes that back instead if possible.
function instantiateClasses(json: SubscriptionResponse<any>): SubscriptionResponse<any> {
  const Constructor = wsTypeMappings[json.type as keyof typeof wsTypeMappings];
  if (Constructor == null || json.data == null) {
    return json;
  }
  for (let i = 0; i < json.data.length; i++) {
    json.data[i] = new Constructor(json.data[i]);
  }
  return json;
}
