import { useMemo } from 'react';
import { map } from 'rxjs';
import { useObservableValue } from '../../hooks/useObservable';
import { useSubscription } from '../../hooks/useSubscription';
import { ORDER } from '../../tokens';
import type { CustomerOrder } from '../../types';
import { useWLOrdersProvider } from '../WLOrdersProvider';

export function useWLOrder({ orderID }: { orderID: string | undefined }): CustomerOrder | undefined {
  const { recentOrdersByOrdID: ordersByOrdID } = useWLOrdersProvider();

  // Most of the time, the order will be in the ordersByOrdID map.
  // But if it is not, we'll create a subscription to the orderID to try to find it.
  const cachedOrder = orderID ? ordersByOrdID.get(orderID) : undefined;
  const proposedCachedOrder = cachedOrder?.OrderID === orderID ? cachedOrder : undefined;
  const orderRequest = useMemo(() => {
    if (orderID && !proposedCachedOrder) {
      return {
        name: ORDER,
        tag: 'useOrder_id_specific',
        OrderID: orderID,
      };
    }
    return null;
  }, [orderID, proposedCachedOrder]);

  const { data: orderSub } = useSubscription<CustomerOrder>(orderRequest);

  const fetchedOrder: CustomerOrder | undefined = useObservableValue(
    () => orderSub.pipe(map(json => json.data.at(-1))),
    [orderSub]
  );

  const proposedFetchedOrder = orderID === fetchedOrder?.OrderID ? fetchedOrder : undefined;
  return proposedCachedOrder ?? proposedFetchedOrder;
}
