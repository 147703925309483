import { useMemo } from 'react';
import { getAgGridColId } from '../components/BlotterTable/columns/getAgGridColId';
import type { Column, ColumnDef } from '../components/BlotterTable/columns/types';
import { useDefaultColumns } from '../components/BlotterTable/useDefaultColumns';
import { EMPTY_ARRAY } from '../utils/empty';
import type {
  ExecTypeEnum,
  PricingModeEnum,
  SideEnum,
  TradeActionEnum,
  TradeSourceEnum,
  TradeStatusEnum,
} from './types';

export const isCustomerTrade = (entity: any): entity is CustomerTrade => {
  return entity instanceof CustomerTrade;
};

// CustomerTradeCapture4225
export class CustomerTrade {
  static readonly rowID = 'TradeID';
  static readonly defaultColumns = [
    'TransactTime',
    'Counterparty',
    'MarketAccount',
    'TradeID',
    'Side',
    'Symbol',
    'Amount',
    'Quantity',
    'Price',
    'TradeStatus',
  ] satisfies (keyof CustomerTrade)[];

  TradeID!: string;

  OrderID?: string; // does not exist for manual trades

  Symbol!: string;

  Quantity!: string;

  Amount!: string;

  Fee?: string;

  User!: string;

  CustomerUser?: string;

  Timestamp!: string;

  DealtCurrency?: string;

  Price!: string;

  CredentialID?: string;

  ClTradeID?: string;

  TransactTime!: string;

  ExecType?: ExecTypeEnum;

  Currency!: string;

  AmountCurrency!: string;

  FeeCurrency?: string;

  TradeStatus?: TradeStatusEnum;

  Side!: SideEnum;

  AggressorSide?: SideEnum;

  OID?: string;

  EID?: string;

  QuoteID?: string;

  RFQID?: string;

  Group?: string;

  SubAccount?: string;

  Counterparty?: string;

  Comments?: string;

  TradeSource?: TradeSourceEnum;

  TradeAction?: TradeActionEnum;

  Revision?: string;

  UpdateUser?: string;

  MarketAccount!: string;

  MessageID?: string;

  SalesCommission?: string;

  PricingReference?: string;

  FixingDetails?: {
    Index?: string;
    Fixing?: string;
  };

  PricingMode?: PricingModeEnum;

  constructor(data: CustomerTrade) {
    Object.assign(this, data);
  }
}

interface UseCustomerTradeColumns {
  defaultColumns?: (keyof CustomerTrade | Partial<Column>)[];
}

export function useCustomerTradeColumns({ defaultColumns = EMPTY_ARRAY }: UseCustomerTradeColumns): Column[] {
  const defaultVisibleColumns = useMemo(
    () =>
      new Map(
        (
          [
            {
              field: 'Revision',
              id: 'revision_indicator',
              type: 'revision',
            },
            {
              field: 'TransactTime',
              type: 'date',
              width: 200,
              sortable: true,
              params: { milliseconds: true },
            },
            {
              field: 'Counterparty',
              title: 'Counterparty',
              type: 'customer',
            },
            {
              field: 'MarketAccount',
              type: 'text',
              title: 'Market Account',
              id: 'marketAccount',
            },
            {
              field: 'MarketAccount',
              type: 'marketAccountSourceAccountID',
              id: 'customerAccountID',
              hide: true,
            },
            {
              field: 'TradeID',
              type: 'id',
            },
            {
              field: 'Symbol',
              type: 'security',
            },
            {
              field: 'Side',
              type: 'side',
            },
            {
              field: 'Quantity',
              type: 'size',
              title: 'Filled Qty',
              params: { currencyField: 'Currency' },
            },
            {
              field: 'Price',
              type: 'price',
              title: 'Filled Price',
              sortable: true,
              params: { assetField: 'Symbol' },
              width: 120,
            },
            {
              field: 'Amount',
              title: 'Filled Counter Amount',
              type: 'size',
              params: { currencyField: 'AmountCurrency' },
            },
            {
              field: 'TradeStatus',
              type: 'tradeStatus',
            },
            {
              field: 'CustomerUser',
              type: 'user',
              title: 'Customer User',
            },
            {
              field: 'User',
              type: 'user',
            },
            {
              field: 'OrderID',
              type: 'id',
            },
          ] satisfies ColumnDef<CustomerTrade>[]
        ).map(c => [getAgGridColId(c), c])
      ),
    []
  );
  const defaultHiddenColumns = useMemo(() => {
    return new Map(
      (
        [
          {
            field: 'Fee',
            type: 'size',
            params: { currencyField: 'FeeCurrency' },
          },
          {
            field: 'Timestamp',
            type: 'date',
            sortable: true,
            params: { milliseconds: true },
          },
          {
            field: 'CredentialID',
            type: 'text',
          },
          {
            field: 'ClTradeID',
            type: 'text',
          },
          {
            field: 'ExecType',
            type: 'text',
          },
          {
            field: 'AggressorSide',
            type: 'side',
          },
          {
            field: 'OID',
            type: 'text',
          },
          {
            field: 'EID',
            type: 'text',
          },
          {
            field: 'QuoteID',
            type: 'id',
          },
          {
            field: 'RFQID',
            type: 'id',
          },
          {
            field: 'Group',
            type: 'text',
          },
          {
            field: 'SubAccount',
            type: 'subAccount',
          },
          {
            field: 'Comments',
            type: 'text',
          },
          {
            field: 'TradeSource',
            type: 'text',
          },
          {
            field: 'TradeAction',
            type: 'text',
          },
          {
            field: 'Revision',
            type: 'text',
          },
          {
            field: 'UpdateUser',
            type: 'user',
          },
          {
            field: 'MessageID',
            type: 'text',
          },
          {
            field: 'PricingReference',
            type: 'price',
            hide: true,
            params: {
              isReferencePrice: true,
            },
          },
          {
            field: 'FixingDetails.Index',
            title: 'Fixing Index',
            type: 'text',
          },
          {
            field: 'FixingDetails.Fixing',
            title: 'Fixing Price',
            type: 'price',
            params: {
              // TODO fhqvst this should probably be `FixingDetails.Index`
              assetField: 'Symbol',
            },
          },
        ] satisfies (false | ColumnDef<CustomerTrade>)[]
      ).map(c => [getAgGridColId(c), { ...c, hide: true }])
    );
  }, []);
  const columnDefinitions = useMemo(() => {
    return new Map(
      (
        [
          ...defaultVisibleColumns.values(),
          ...defaultHiddenColumns.values(),
        ] satisfies ColumnDef<CustomerTrade>[] as Column[]
      ).map(c => [getAgGridColId(c), c])
    );
  }, [defaultVisibleColumns, defaultHiddenColumns]);
  return useDefaultColumns(defaultColumns, columnDefinitions);
}
