import type { RefObject } from 'react';
import type { BlotterTableFilter } from '../../BlotterTable';
import type { AutocompleteGroupSorterFunc, UseAutocompleteProps } from '../../Form/Autocomplete/types';
import type { AutocompleteDropdownProps } from '../../Form/AutocompleteDropdown/types';
import type { IconName } from '../../Icons';

export type FilterClauseControl = 'multi-select' | 'single-select' | 'text';

export type FilterBuilderSide = 'lhs' | 'rhs';

export enum FilterClauseType {
  INCLUSIVE = 'inclusive',
  EXCLUSIVE = 'exclusive',
}

export interface UseFilterBuilderProps {
  /** Initial filter clauses, bootstraps the component */
  initialFilterClauses: InitialFilterClause[];
  /** The "metadata" needed to give users properties to create clauses on */
  properties: FilterableProperty[];
  /* Filters that are always applied, not visible in the UI and cannot be changed */
  permanentFilters?: BlotterTableFilter | undefined;
  /** Handler for when filters change */
  onFilterClausesChanged?(
    filterClausesByPropertyKey: Map<string, FilterClause>,
    propertiesByKey: Map<string, FilterableProperty>
  ): void;
}

export type UseFilterBuilderOutput = Pick<UseFilterBuilderRefsOutput, 'refs' | 'updateSelectionRefs'> & {
  /** The currently active filter clauses */
  filterClauses: FilterClause[];
  /** The current active filter clauses by property key */
  filterClausesByPropertyKey: Map<string, FilterClause>;
  addFilterClause: (property: string) => void;
  removeFilterClause: (property: string) => void;
  removeAllFilterClauses: () => void;
  replaceFilterClause: (oldProperty: string, newProperty: string) => void;
  resetFilterClauses: (filterClauses?: FilterClause[]) => void;
  setFilterClauseSelections: (property: string, newSelections: string[]) => void;
  /** The list of filterable properties the user can build filter clauses using */
  propertiesList: FilterableProperty[];
  /** The list of filterable properties by property key which the user can build filter clauses using */
  propertiesByKey: Map<string, FilterableProperty>;
  addAndOpenClause: (property: string | undefined, side?: FilterBuilderSide, value?: string | string[]) => void;
};

export interface FilterablePropertyCommon<TKey = string> {
  /** The key of the property on the entity. For example "Symbol", or "User" */
  key: TKey;
  /** Display label for the key */
  label: string;
  /** Field override for the property */
  field?: string;
  /** A string to be used in the <Icon /> component */
  icon: IconName;
  /** Specify what type of control you would like to set the selections for this clause */
  control?: FilterClauseControl;

  /** If required, the filter cannot be removed */
  required?: boolean;
}

export interface FilterableSelectProperty<TKey = string, TData = string> extends FilterablePropertyCommon<TKey> {
  control?: 'multi-select' | 'single-select';

  /** An array of strings representing the selectable options for this property */
  options: TData[];
  /** A function allowing the component to get a display version of the option */
  getOptionLabel: (option: TData) => string;
  /** Get a description to show below the label of an option in the dropdown */
  getOptionDescription?: (option: TData) => string;
  /** If provided, will group the options in the dropdown by this property */
  getOptionGroup?: (option: TData) => string;
  /** Specify sorting hierarchy on options */
  optionSorter?: (a: TData, b: TData) => number;
  /** Optional group sorter to sort the groups which are being shown in the property's selector dropdown */
  groupSorter?: AutocompleteGroupSorterFunc;
  /** Customize the dropdown for selecting the RHS options of this property */
  dropdownOptions?: Pick<AutocompleteDropdownProps<any>, 'dropdownWidth' | 'maxHeight' | 'groupMaxHeight'>;
  /**
   * Filter out unsupported selections on init. Defaults to true.
   * If your array of options is async, this should probably be set to false.
   */
  clearUnsupportedSelectionsOnInit?: boolean;
  /** Matching threshold */
  matchThreshold?: UseAutocompleteProps<unknown>['matchThreshold'];
  /** Only relevant for multi-select controls. Whether or not the user should be able to select all selectable options. */
  allowSelectAll?: boolean;
}

export interface FilterableTextProperty<TKey = string> extends FilterablePropertyCommon<TKey> {
  control: 'text';
}

export type FilterableProperty<TKey = string, TData = string> =
  | FilterableSelectProperty<TKey, TData>
  | FilterableTextProperty<TKey>;

export interface FilterClause<T = string> {
  /** The key of the property on the entity. For example "Symbol" or "User" */
  key: T;
  /** The type of the filter clause, for example "inclusive" or "exclusive" */
  type: FilterClauseType;
  /** List of selections. Selections are made from the "options" array in the FilterableProperty. */
  selections: string[];
  /** Whether the clause is required to be applied */
  required?: boolean;
}

export interface InitialFilterClause extends Omit<FilterClause, 'selections'> {
  /** List of selections. Selections are made from the "options" array in the FilterableProperty. */
  selections?: FilterClause['selections'];
}

export interface FilterBuilderRefs {
  addButtonRef: RefObject<HTMLButtonElement>;
  refsByPropertyKey: Map<string, PropertyRefs>;
}

export interface PropertyRefs<TData = string> {
  property: RefObject<HTMLButtonElement>;
  empty: RefObject<HTMLButtonElement>;
  selections: Map<TData, RefObject<HTMLButtonElement>>;
  tail: RefObject<HTMLButtonElement>;
}

export interface UseFilterBuilderRefsOutput<TData = string> {
  refs: FilterBuilderRefs;
  updateSelectionRefs: (property: string, selections: TData[]) => void;
  addPropertyRefs: (property: string) => void;
  removePropertyRefs: (property: string) => void;
  removeAllPropertyRefs: () => void;
  resetAllPropertyRefs: (filterClauses?: FilterClause[]) => void;
  openClause: (property: string | undefined, side?: FilterBuilderSide, value?: string) => void;
}
