import type { GetContextMenuItemsParams, GetMainMenuItemsParams, IRowNode, MenuItemDef } from 'ag-grid-enterprise';
import { compact, first } from 'lodash';
import { useCallback } from 'react';
import { defineMessages } from 'react-intl';
import { useMixpanel, type IntlWithFormatter, type MixpanelInstance } from '../../contexts';
import type { IWebSocketClient } from '../../providers/WebSocketClient';
import { MixpanelEvent, WITHDRAW_CANCEL_REQUEST } from '../../tokens';
import { CustomerBalanceTransactionStatusEnum, type CustomerBalanceTransaction } from '../../types';
import { copyText } from '../../utils';
import { getCellDisplayValue, getCellFilterValue } from '../AgGrid/agGridGetCellValue';
import type { FilterableProperty, UseFilterBuilderOutput } from '../Filters';
import { IconName } from '../Icons';
import { agGridGetCSV } from './utils';

const messages = defineMessages({
  copyCell: {
    id: 'BlotterTable.copyCell',
    defaultMessage: 'Copy cell',
  },
  copyRowsAsJson: {
    id: 'BlotterTable.copyRowsAsJson',
    defaultMessage: `{rowCount, plural, one {Copy row as JSON} other {Copy rows as JSON}}`,
  },
  copyRowsInGroupAsJson: {
    id: 'BlotterTable.copyRowsInGroupAsJson',
    defaultMessage: `{rowCount, plural, one {Copy row in group as JSON} other {Copy rows in group as JSON}}`,
  },
  copyRowsAsCSV: {
    id: 'BlotterTable.copyRowsAsCSV',
    defaultMessage: `{rowCount, plural, one {Copy row as CSV} other {Copy rows as CSV}}`,
  },
  copyRowsInGroupAsCSV: {
    id: 'BlotterTable.copyRowsInGroupAsCSV',
    defaultMessage: `{rowCount, plural, one {Copy row in group as CSV} other {Copy rows in group as CSV}}`,
  },
  selectAllRows: {
    id: 'BlotterTable.selectAllRows',
    defaultMessage: 'Select all rows',
  },
  filterThisColumnLabel: {
    id: 'BlotterTable.filterThisColumnLabel',
    defaultMessage: 'Filter This Column ({label})',
  },
  filterByValue: {
    id: 'BlotterTable.filterByValue',
    defaultMessage: 'Filter by {value}',
  },
  filterByMultipleValues: {
    id: 'BlotterTable.filterByMultipleValues',
    defaultMessage: 'Filter by {count} {label}',
  },
  cancelWithdrawalRequest: {
    id: 'BlotterTable.cancelWithdrawalRequest',
    defaultMessage: 'Cancel Withdrawal Request',
  },
});

export function useGetDefaultContextMenuItems<T extends object = any>(
  /** allow caller to specify how the json copy show behave (likely should be in line with 'Show JSON') */
  copyDataCallback?: (data: T) => unknown
): (params: GetContextMenuItemsParams) => MenuItemDef[] {
  const mixpanel = useMixpanel();

  return useCallback(
    params =>
      compact([
        copyCell(params, mixpanel),
        copyRowAsJson<T>(params, mixpanel, copyDataCallback),
        copyRowAsCsv(params, mixpanel),
      ]),
    [copyDataCallback, mixpanel]
  );
}

/**
 * Selects all "filtered" rows, as in all visible rows in the grid.
 * @param param Params object passed to getContextMenuItems
 * @returns Menu items
 */
export function selectAll(params: GetContextMenuItemsParams, mixpanel: MixpanelInstance): MenuItemDef {
  return {
    name: params.context.current.intl.formatMessage(messages.selectAllRows),
    action: () => {
      mixpanel.track(MixpanelEvent.SelectAllRows);
      params.api.selectAllFiltered();
    },
    icon: `<i class="ag-icon ${IconName.CheckMultiple}"/>`,
  };
}

/**
 * Get a "Copy cell" menu item for an ag-grid context menu.
 * Will copy the formatted value of the current cell to the clipboard.
 *
 * @param param Params object passed to getContextMenuItems
 * @returns Menu items
 */
function copyCell(params: GetContextMenuItemsParams, mixpanel: MixpanelInstance): MenuItemDef | null {
  if (params?.value == null) {
    return null;
  }

  return {
    name: params.context.current.intl.formatMessage(messages.copyCell),
    action: () => {
      copyText(getCellDisplayValue(params));
      mixpanel?.track(MixpanelEvent.CopyCell);
    },
    icon: `<i class="ag-icon ${IconName.ClipboardCopy}"/>`,
  };
}

function getRowsForCopying(params: GetContextMenuItemsParams):
  | {
      rowNodes: IRowNode[];
      copyingChildren: boolean;
    }
  | undefined {
  if (!params.node) {
    return undefined;
  }
  const rowIsGroup = !!params.node.group;
  const rowHasData = !!params.node.data;
  const copyingChildren = rowIsGroup && !rowHasData;

  const rowNodes = rowHasData ? [params.node] : rowIsGroup ? getRowNodesToOperateOn(params) : undefined;
  if (!rowNodes) {
    return undefined;
  }

  return {
    rowNodes,
    copyingChildren,
  };
}

/**
 * Get a "Copy as JSON" menu item for an ag-grid context menu.
 * If multiple rows are selected, all rows will be copied.
 * Any group rows will export all their leaf nodes instead (the rows
 *  that would be rendered if the group was expanded).
 *
 * @param param Params object passed to getContextMenuItems
 * @returns Menu items
 */
function copyRowAsJson<T extends object = any>(
  params: GetContextMenuItemsParams,
  mixpanel: MixpanelInstance,
  copyDataCallback?: (data: T) => unknown
): MenuItemDef | null {
  const rowsToCopy = getRowsForCopying(params);
  if (!rowsToCopy) {
    return null;
  }

  const { rowNodes, copyingChildren } = rowsToCopy;

  const formattedName = params.context.current.intl.formatMessage(
    copyingChildren ? messages.copyRowsInGroupAsJson : messages.copyRowsAsJson,
    { rowCount: rowNodes.length }
  );

  if (rowNodes.length > 0) {
    return {
      name: formattedName,
      action: () => {
        const nodeData: object[] = rowNodes.map(row => (copyDataCallback ? copyDataCallback(row.data) : row.data));
        const jsonData = nodeData.length > 1 ? nodeData : first(nodeData);
        copyText(JSON.stringify(jsonData, null, 2));
        mixpanel?.track(copyingChildren ? MixpanelEvent.CopyRowsInGroupAsJSON : MixpanelEvent.CopyRowAsJSON);
      },
      icon: `<i class="ag-icon ${IconName.Braces}"/>`,
    };
  }
  return null;
}

/**
 * Get a "Copy as CSV" menu item for an ag-grid context menu.
 * If multiple rows are selected, all rows will be copied.
 * Any group rows will export all their leaf nodes instead (the rows
 *  that would be rendered if the group was expanded).
 *
 * @param param Params object passed to getContextMenuItems
 * @returns Menu items
 */
const copyRowAsCsv = (params: GetContextMenuItemsParams, mixpanel: MixpanelInstance): MenuItemDef | null => {
  const rowsToCopy = getRowsForCopying(params);
  if (!rowsToCopy) {
    return null;
  }

  const { rowNodes, copyingChildren } = rowsToCopy;

  const formattedName = params.context.current.intl.formatMessage(
    copyingChildren ? messages.copyRowsInGroupAsCSV : messages.copyRowsAsCSV,
    { rowCount: rowNodes.length }
  );

  if (rowNodes.length > 0) {
    return {
      name: formattedName,
      action: () => {
        copyText(agGridGetCSV(params, new Set(rowNodes.map(row => row.id ?? ''))));
        mixpanel?.track(copyingChildren ? MixpanelEvent.CopyRowsInGroupAsCSV : MixpanelEvent.CopyRowAsCSV);
      },
      icon: `<i class="ag-icon ${IconName.Braces}"/>`,
    };
  }
  return null;
};

/**
 * A simple function which returns the list of RowNodes you should be operating on when performing any selection-based interactions.
 * It filters out all group nodes since they have no meaning for extraction / copying / performing actions as of yet.
 */
export function getRowNodesToOperateOn<T>(params: GetContextMenuItemsParams<T>): IRowNode<T>[] {
  // params.api.getSelectedNodes() returns nodes that are filtered out too. There is no trivial way to
  // check if a selected node has been filtered out from view or not since the properties on a node saying if its
  // "displayed" / "in the grid" or not also come into effect when a node is in a collapsed group (in which case they are allowed to be selected)
  // so approach is to only operate on the set of post-filter nodes. We iterate over all nodes in the blotter in the worst case but it seems to be OK.
  const nodes: IRowNode<T>[] = [];
  params.api.forEachNodeAfterFilter(node => {
    if (!node.group && node.isSelected()) {
      nodes.push(node);
    }
  });
  return nodes;
}

export function cancelPendingApprovalWithdrawalRequest(
  params: GetContextMenuItemsParams<CustomerBalanceTransaction>,
  client: IWebSocketClient<unknown>
): MenuItemDef | null {
  const data = params.node!.data!;
  if (data.Status === CustomerBalanceTransactionStatusEnum.PendingApproval) {
    return {
      name: params.context.current.intl.formatMessage(messages.cancelWithdrawalRequest),
      action: () => {
        client.registerPublication({
          type: WITHDRAW_CANCEL_REQUEST,
          data: [{ TransactionID: data.TransactionID }],
        });
      },
      icon: `<i class="ag-icon ${IconName.CloseCircleSolid}"/>`,
    };
  }
  return null;
}

export interface FilterByCellValueParams {
  /** Params object passed to getContextMenuItems */
  params: GetContextMenuItemsParams<any>;
  /** Filterable properties list as passed to the Filter Builder */
  filterableProperties: FilterableProperty<string>[];
  /** Function returned from the filter builder accordion, to add / open a new filter clause */
  openClause: UseFilterBuilderOutput['addAndOpenClause'];
  /** Function to map an ag-grid column id to a Filter builder property key */
  colIDToFilterBuilderKey: (id: string) => string | undefined;
  /** Appropriate mixpanel instance to use when tracking event */
  mixpanel: MixpanelInstance;
  /** An optional way to override (hard-code) the colID of the column we want to do filtering on. */
  colID?: string;
}

/**
 * Determine which column to actually filter by.
 * Usually this will be the column in the params object.
 * However, if this is a group column, we need to determine which level
 * of grouping we are currently at, and then use the column for that level.
 *
 * @param params Ag-grid params object
 * @returns Column id to filter by
 */
export function getColumnKeyForFiltering(params: GetContextMenuItemsParams<any>) {
  if (params.node?.group) {
    return params.node.rowGroupColumn?.getColId();
  }

  const isInGroupingColumn = params.column?.getColDef()?.showRowGroup;
  if (isInGroupingColumn && !params.node?.group) {
    // We are in the grouping column, but we are not a group, meaning we're a leaf node.
    // In this case, we take the rowGroupColumn id of our parent.
    return params.node?.parent?.rowGroupColumn?.getColId();
  }

  // Else, normal cell, just return the colid
  return params.column?.getColId();
}

/**
 * Get a "Filter by cell-value" menu item for an ag-grid context menu.
 *
 * @param param Params object passed to getContextMenuItems
 * @returns Array of menu items (empty if this cell is not filterable)
 */
export function filterByCellValueMenuItem({
  params,
  filterableProperties,
  openClause,
  colIDToFilterBuilderKey,
  mixpanel,
  colID,
}: FilterByCellValueParams): MenuItemDef[] {
  const colIDToUse = colID ?? getColumnKeyForFiltering(params);
  const key = colIDToUse && colIDToFilterBuilderKey(colIDToUse);

  if (!key) {
    // This column doesn't exist in the filter builder, so we can't filter by this cell's value
    return [];
  }
  const property = filterableProperties.find(p => p.key === key);

  // This is the value that a user would see in the cell (although the cell renderer may add extra text)
  const displayValue = getCellDisplayValue(params);

  // This is the value that we should filter by
  const filterValue = getCellFilterValue(params);

  // There are a few different code paths here where we need to decide if the cell is filterable.
  let cellFilterable = false;
  if (property?.control === 'text') {
    cellFilterable = true;
  } else if (property?.control === 'multi-select' && filterValue instanceof Array) {
    // We support the case where the cell's filter value is an array. In this case, we allow the user to prime with an array of selections.
    const optionsSet = new Set(property.options);
    const validSelections = filterValue.filter(fv => optionsSet.has(fv));
    cellFilterable = validSelections.length > 0;
  } else {
    cellFilterable = property?.options.includes(filterValue) ?? false;
  }

  const filterByCellValue =
    cellFilterable && property
      ? ([
          {
            name: filterByCellLabel(property, filterValue, displayValue, params.context.current.intl),
            action: () => {
              mixpanel.track(MixpanelEvent.FilterCell);
              // Note that we need to pass the _filter value_ here, because the filter builder looks for the option by value
              openClause(key, 'rhs', filterValue);
            },
            icon: `<i class="ag-icon ${IconName.Filter}"/>`,
          },
          'separator',
        ] as MenuItemDef[])
      : [];
  return filterByCellValue;
}

function filterByCellLabel(property: FilterableProperty, filterValue: any, displayValue: any, intl: IntlWithFormatter) {
  if (Array.isArray(filterValue) && filterValue.length > 1) {
    return intl.formatMessage(messages.filterByMultipleValues, {
      count: filterValue.length,
      label: property.label,
    });
  }

  return intl.formatMessage(messages.filterByValue, {
    value: displayValue,
  });
}

interface FilterByColumnItemParams {
  /** Params object passed to getContextMenuItems */
  params: GetMainMenuItemsParams;
  /** Function returned from the filter builder accordion, to add / open a new filter clause */
  openClause: UseFilterBuilderOutput['addAndOpenClause'];
  /** Function to map an ag-grid column id to a Filter builder property key */
  colIDToFilterBuilderKey: (id: string) => string | undefined;
  /** Appropriate mixpanel instance to use when tracking event */
  mixpanel: MixpanelInstance;
}

/**
 * Creates MenuItemDefs from your passed params.
 * For application on group columns, relies on the assumption that there is only one grouped column.
 * If this is not the case any more, the implementation will behave incorrectly.
 *
 * @param Params object
 * @returns an array of MenuItemDef, empty if the colID is not filterable
 */
export function filterByColumnMainMenuItems({
  params,
  colIDToFilterBuilderKey,
  openClause,
  mixpanel,
}: FilterByColumnItemParams): MenuItemDef[] {
  const colIDsAndLabels: { colID: string; label: string }[] = [];
  if (params.column.getColDef().showRowGroup) {
    const groupColumns = params.api.getRowGroupColumns();
    colIDsAndLabels.push(
      ...groupColumns.map(gc => {
        const colID = gc.getColId();
        const label = gc.getColDef().headerName ?? colID ?? '';
        return { colID, label };
      })
    );
  } else {
    const colID = params.column.getColId();
    const label = params.column.getColDef().headerName ?? colID ?? '';
    colIDsAndLabels.push({
      colID,
      label,
    });
  }

  const menuItems = colIDsAndLabels.map(({ colID, label }) => {
    const key = colIDToFilterBuilderKey(colID);
    if (!key) {
      return undefined;
    }

    return {
      name: params.context.current.intl.formatMessage(messages.filterThisColumnLabel, {
        label,
      }),
      action: () => {
        mixpanel.track(MixpanelEvent.FilterColumn);
        openClause?.(key);
      },
    };
  });

  return compact(menuItems);
}
