import styled, { css, type DefaultTheme } from 'styled-components';
import { getIconButtonSize, IconButton } from '../../Button';
import { Box, HStack } from '../../Core';
import { Text } from '../../Text';
import { formControlCSS } from '../styles';
import { FormControlSizes, type FormControlProps } from '../types';

export const StyledSelection = styled(Box)`
  width: 100%;
  height: 100%;
  text-align: left;
  display: flex;
  flex-direction: column;
`;

const EllipsisTextBase = styled(Text)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const EllipsisText = styled(EllipsisTextBase)`
  max-width: 100%;
`;

export const SuffixIconButtons = styled(HStack)``;

// Enums taken from Helix design states:
// https://www.figma.com/file/bCvaVjDeIroGxYmMVGnSmz/Helix---Kyoko?node-id=2-9213&t=Um359rmwDeuR4qe3-0
type State =
  | 'Default'
  | 'DefaultHover'
  | 'DefaultActive'
  | 'Filled'
  | 'FilledHover'
  | 'FilledActive'
  | 'ReadOnly'
  | 'Disabled'
  | 'Invalid'
  | 'InvalidAsSelected';

interface StateColors {
  wrapperColor: string;
  textColor: string;
  borderColor: string;
  backgroundColor: string;
}

const getStateColors = (state: State, theme: DefaultTheme): StateColors => {
  switch (state) {
    case 'Default':
      return {
        wrapperColor: theme.colorTextSubtle,
        textColor: theme.colorTextSubtle,
        borderColor: theme.borderColorSelect,
        backgroundColor: theme.backgroundSelect,
      };
    case 'DefaultHover':
      return {
        wrapperColor: theme.colorTextImportant,
        textColor: theme.colorTextImportant,
        borderColor: theme.borderColorSelectHover,
        backgroundColor: theme.backgroundSelectHover,
      };
    case 'DefaultActive':
      return {
        wrapperColor: theme.colorTextImportant,
        textColor: theme.colorTextImportant,
        borderColor: theme.borderColorSelectFocus,
        backgroundColor: theme.backgroundSelectFocus,
      };
    case 'Filled':
      return {
        wrapperColor: theme.colorTextDefault,
        textColor: theme.colorTextImportant,
        borderColor: theme.borderColorSelect,
        backgroundColor: theme.backgroundSelect,
      };
    case 'FilledHover':
      return {
        wrapperColor: theme.colorTextImportant,
        textColor: theme.colorTextImportant,
        borderColor: theme.borderColorSelectHover,
        backgroundColor: theme.backgroundSelectHover,
      };
    case 'FilledActive':
      return {
        wrapperColor: theme.colorTextImportant,
        textColor: theme.colorTextImportant,
        borderColor: theme.borderColorSelectFocus,
        backgroundColor: theme.backgroundSelectFocus,
      };
    case 'ReadOnly':
      return {
        wrapperColor: theme.colorTextDefault,
        textColor: theme.colorTextDefault,
        borderColor: theme.borderColorSelectReadOnly,
        backgroundColor: theme.backgroundSelectReadOnly,
      };
    case 'Disabled':
      return {
        wrapperColor: theme.colorTextDefault,
        textColor: theme.colorTextDefault,
        borderColor: theme.borderColorSelectDisabled,
        backgroundColor: theme.backgroundSelectDisabled,
      };
    case 'Invalid':
      return {
        wrapperColor: theme.colorTextSubtle,
        textColor: theme.colorTextSubtle,
        borderColor: theme.borderColorSelectInvalid,
        backgroundColor: theme.backgroundSelectInvalid,
      };
    case 'InvalidAsSelected':
      return {
        wrapperColor: theme.colorTextSubtle,
        textColor: theme.colorTextImportant,
        borderColor: theme.borderColorSelectInvalid,
        backgroundColor: theme.backgroundSelectInvalid,
      };
    default: {
      // Fall-through to a reasonable default
      return {
        wrapperColor: theme.colorTextSubtle,
        textColor: theme.colorTextSubtle,
        borderColor: theme.borderColorSelect,
        backgroundColor: theme.backgroundSelect,
      };
    }
  }
};

interface BaseSelectWrapperProps extends FormControlProps<HTMLDivElement> {
  isDropdownOpened: boolean;
  isValueDefined: boolean;
  centered: boolean;
  showDescription: boolean;
}

export const BaseSelectWrapper = styled.label<BaseSelectWrapperProps>`
  ${formControlCSS};

  // Override the default height rules
  height: ${({ theme, size = FormControlSizes.Default, showDescription }) =>
    getIconButtonSize(theme, size) * (showDescription ? 1.5 : 1)}px;
  min-height: ${({ theme, size = FormControlSizes.Default, showDescription }) =>
    getIconButtonSize(theme, size) * (showDescription ? 1.5 : 1)}px;

  // When we're showing a description we override the default form control line-height
  ${({ showDescription }) =>
    showDescription &&
    css`
      line-height: normal;
    `}

  transition: color 200ms ease, border-color 200ms ease, background-color 200ms ease;

  ${({ disabled }) =>
    !disabled &&
    css`
      cursor: pointer;
    `}

  // Our select component only has a distinguishable border on active states (hover, focus),
  // so override the default one here to be same as the background.
  ${({ theme, isDropdownOpened, isValueDefined, disabled, invalid, touched, readOnly = false }) =>
    labelLevelCss(
      getState({
        focused: isDropdownOpened,
        isValueDefined,
        hovered: false,
        readonly: readOnly,
        disabled,
        invalid,
        touched,
      }),
      theme
    )}

  ${StyledSelection} {
    ${({ theme, isDropdownOpened, isValueDefined, disabled, invalid, touched, readOnly = false }) =>
      buttonLevelCss(
        getState({
          focused: isDropdownOpened,
          isValueDefined,
          hovered: false,
          readonly: readOnly,
          disabled,
          invalid,
          touched,
        }),
        theme
      )}
  }

  // The IconButtons we use in this component get their own styles form the buttonStyles function, so we
  // force it to inherit its color from us instead.
  ${IconButton} {
    color: inherit;
  }

  // For our own suffix icon buttons specifically, we remove the hover and focus events on their backgrounds
  ${SuffixIconButtons} {
    ${IconButton} {
      :hover,
      :focus {
        background-color: transparent;
      }
    }
  }

  :hover {
    ${({ theme, isDropdownOpened, isValueDefined, disabled, invalid, touched, readOnly = false }) =>
      labelLevelCss(
        getState({
          focused: isDropdownOpened,
          isValueDefined,
          hovered: true,
          readonly: readOnly,
          disabled,
          invalid,
          touched,
        }),
        theme
      )}

    ${StyledSelection} {
      ${({ theme, isDropdownOpened, isValueDefined, disabled, invalid, touched, readOnly = false }) =>
        buttonLevelCss(
          getState({
            focused: isDropdownOpened,
            isValueDefined,
            hovered: true,
            readonly: readOnly,
            disabled,
            invalid,
            touched,
          }),
          theme
        )}
    }
  }

  // For the focus states, we can replace the isDropdownOpened state with true
  :focus,
  :focus-within {
    ${({ theme, isValueDefined, disabled, invalid, touched, readOnly = false }) =>
      labelLevelCss(
        getState({
          focused: true,
          isValueDefined,
          hovered: false,
          readonly: readOnly,
          disabled,
          invalid,
          touched,
        }),
        theme
      )}

    ${StyledSelection} {
      ${({ theme, isValueDefined, disabled, invalid, touched, readOnly = false }) =>
        buttonLevelCss(
          getState({
            focused: true,
            isValueDefined,
            hovered: false,
            readonly: readOnly,
            disabled,
            invalid,
            touched,
          }),
          theme
        )}
    }
  }

  justify-content: ${({ centered }) => (centered ? 'center' : 'space-between')};
`;

const labelLevelCss = (state: State, theme: DefaultTheme) => {
  const { wrapperColor, borderColor, backgroundColor } = getStateColors(state, theme);
  return css`
    border-color: ${borderColor};
    background-color: ${backgroundColor};
    color: ${wrapperColor};
  `;
};

const buttonLevelCss = (state: State, theme: DefaultTheme) => {
  const { textColor } = getStateColors(state, theme);
  return css`
    color: ${textColor};
  `;
};

interface GetStateParams {
  focused: boolean;
  isValueDefined: boolean;
  hovered: boolean;
  readonly: boolean;
  disabled?: boolean;
  invalid?: boolean;
  touched?: boolean;
}

// Helper function to resolve variables to one kind of state
function getState({ focused, isValueDefined, hovered, readonly, disabled, invalid, touched }: GetStateParams): State {
  if (invalid && isValueDefined) {
    return 'InvalidAsSelected';
  }
  if (invalid && touched) {
    return 'Invalid';
  }

  if (disabled) {
    return 'Disabled';
  }

  if (readonly) {
    return 'ReadOnly';
  }

  if (focused) {
    return isValueDefined ? 'FilledActive' : 'DefaultActive';
  }

  if (hovered) {
    return isValueDefined ? 'FilledHover' : 'DefaultHover';
  }

  return isValueDefined ? 'Filled' : 'Default';
}
