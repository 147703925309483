import { isEqual, keys, pick } from 'lodash';
import { useCallback, useMemo, useState, type SetStateAction } from 'react';

import {
  FilterClauseType,
  cleanupInitialFilterDateRange,
  filterExcludesOrderStatus,
  filterExistsAndExcludes,
  orderStatusFilterTextToBackendStatus,
  removeEmptyFilters,
  useDateRangeFilter,
  useOrderStatusFilter,
  useSidesFilter,
  useSymbolsFilter,
  type BlotterTableClientLocalFilter,
  type BlotterTableFilter,
  type BlotterTableFiltersProps,
  type CustomerOrder,
  type DateRangeFilter,
  type FilterClause,
  type FilterableProperty,
  type OrdStatusEnum,
  type UseFilterBuilderProps,
  type UsePersistedBlotterTable,
} from '@talos/kyoko';

import { useCustomerMarketAccountsFilter, useStrategiesFilter } from './filters';

export interface OrdersTableFilter extends DateRangeFilter {
  MarketAccounts?: string[];
  Sides?: string[];
  Symbols?: string[];
  Strategies?: string[];
  /** These are the order status texts we are filtering by on the client side */
  _statuses?: string[];
  /* These are the order statuses that we are filtering by on the server */
  Statuses?: OrdStatusEnum[];
}

interface UseOrdersFilterParams<TData extends CustomerOrder> {
  persistedBlotterTable: UsePersistedBlotterTable<TData>;
  showDateRangePicker?: boolean;
}

export function useOrdersFilter<TData extends CustomerOrder>({
  persistedBlotterTable,
  showDateRangePicker = true,
}: UseOrdersFilterParams<TData>) {
  const { onFilterChanged: saveFilter } = persistedBlotterTable;

  const [initialFilter] = useState<OrdersTableFilter>(() =>
    cleanupInitialFilterDateRange(persistedBlotterTable.initialFilter)
  );
  const [filter, setFilter] = useState<OrdersTableFilter>(() =>
    cleanupInitialFilterDateRange(persistedBlotterTable.initialFilter)
  );

  const changeFilter = useCallback(
    (action: SetStateAction<BlotterTableFilter>) => {
      const prevFilter = filter;
      const newFilter = action instanceof Function ? action(filter) : action;
      if (!isEqual(prevFilter, newFilter)) {
        setFilter(newFilter);
        saveFilter(newFilter);
      }
    },
    [filter, saveFilter]
  );

  const clientSideFilter = useCallback<BlotterTableClientLocalFilter<TData>>(
    row => {
      const data = row.data;
      if (filterExistsAndExcludes(filter, 'Sides', data, 'Side')) {
        return false;
      }
      if (filterExcludesOrderStatus(filter._statuses, data)) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Strategies', data, 'Strategy')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'Symbols', data, 'Symbol')) {
        return false;
      }
      if (filterExistsAndExcludes(filter, 'MarketAccounts', data, 'MarketAccount')) {
        return false;
      }
      return true;
    },
    [filter]
  );

  const customerMarketAccountsFilter = useCustomerMarketAccountsFilter();
  const strategiesFilter = useStrategiesFilter();
  const sidesFilter = useSidesFilter();
  const orderStatusFilter = useOrderStatusFilter({
    includeStatusGroups: false,
    includeStandaloneStatuses: false,
  });
  const symbolFilter = useSymbolsFilter();

  const filterableProperties: FilterableProperty[] = useMemo(
    () => [customerMarketAccountsFilter, sidesFilter, orderStatusFilter, strategiesFilter, symbolFilter].compact(),
    [customerMarketAccountsFilter, orderStatusFilter, sidesFilter, strategiesFilter, symbolFilter]
  );

  const initialFilterClauses = useMemo(() => {
    const clauses: FilterClause[] = [];
    if (filter) {
      (keys(filter) as (keyof OrdersTableFilter)[]).forEach((key: keyof OrdersTableFilter) => {
        switch (key) {
          case '_start':
          case 'StartDate':
          case 'EndDate':
          case 'TimestampField':
            return;
          default:
            clauses.push({
              key: key,
              type: FilterClauseType.INCLUSIVE,
              selections: filter[key] as string[],
            });
        }
      });
    }
    return clauses;
  }, [filter]);
  const handleFilterClausesChanged = useCallback(
    (filterClausesByPropertyKey: Map<string, FilterClause>, propertiesByKey: Map<string, FilterableProperty>) => {
      changeFilter(curr => {
        const newFilter: OrdersTableFilter = removeEmptyFilters<OrdersTableFilter>({
          ...curr,
          ...(Object.fromEntries(
            [...propertiesByKey.keys()].map(key => [key, filterClausesByPropertyKey.get(key)?.selections])
          ) as unknown as OrdersTableFilter),
        });
        if (isEqual(curr, newFilter)) {
          return curr;
        }
        return newFilter;
      });
    },
    [changeFilter]
  );
  const dateRangeFilter = useDateRangeFilter(filter, changeFilter);

  const filterBuilderProps = useMemo(
    () => ({
      initialFilterClauses,
      properties: filterableProperties,
      onFilterClausesChanged: handleFilterClausesChanged,
    }),
    [filterableProperties, handleFilterClausesChanged, initialFilterClauses]
  ) satisfies UseFilterBuilderProps;
  const blotterTableFilterProps = useMemo(
    () => (showDateRangePicker ? { ...dateRangeFilter } : {}),
    [dateRangeFilter, showDateRangePicker]
  ) satisfies BlotterTableFiltersProps;
  return useMemo(
    () => ({
      initialFilter,
      clientSideFilter,
      filter,
      // shortcut to spread properties into useAccordionFilterBuilder.filterBuilderProps
      filterBuilderProps,
      // shortcut to spread props into the BlotterTableFilters component
      blotterTableFilterProps,
    }),
    [blotterTableFilterProps, clientSideFilter, filter, filterBuilderProps, initialFilter]
  );
}

export function onlyServerFilterKeys(filter: OrdersTableFilter | undefined) {
  if (!filter) {
    return filter;
  }

  return {
    ...pick(filter, ['EndDate', 'StartDate', 'MarketAccounts', 'Strategies', 'Symbols', 'Sides']),
    Statuses: orderStatusFilterTextToBackendStatus(filter._statuses),
  };
}
