import { transparentize } from 'polished';
import styled, { css, keyframes, type DefaultTheme } from 'styled-components';
import { NoteVariant } from './types';

const slideIn = keyframes`
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0%);
  }
`;

const getVariantStyles = (theme: DefaultTheme, variant: NoteVariant = NoteVariant.Default) => {
  switch (variant) {
    case NoteVariant.Positive:
      return css`
        background: ${theme.colors.green.dim};
        border-left: 3px solid ${theme.colors.green.lighten};
      `;
    case NoteVariant.Negative:
      return css`
        background: ${theme.colors.red.dim};
        border-left: 3px solid ${theme.colors.red.lighten};
      `;
    case NoteVariant.Warning:
      return css`
        background: ${theme.colors.yellow.dim};
        border-left: 3px solid ${theme.colors.yellow.lighten};
      `;
    case NoteVariant.Primary:
      return css`
        animation: ${slideIn} 1s cubic-bezier(1, 0, 0.76, 1.17);
        background: linear-gradient(to right, ${theme.colors.primary.dim}, ${theme.colors.primary.lighten});
      `;
    case NoteVariant.Announcement:
      return css`
        animation: ${slideIn} 1s cubic-bezier(1, 0, 0.76, 1.17);
        background: linear-gradient(to right, ${theme.colors.green.dim}, ${theme.colors.green.lighten});
      `;
    default:
      return css`
        background: ${theme.colors.gray['040']};
        border-left: 3px solid ${theme.colors.yellow.lighten};
      `;
  }
};

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column-reverse;
  overflow: hidden;
  flex-shrink: 0;
  align-items: stretch;
  gap: ${({ theme }) => `${theme.spacingTiny}px`};
`;

export const NoteWrapper = styled.div<{ variant?: NoteVariant }>`
  animation: ${slideIn} 500ms cubic-bezier(1, 0, 0.76, 1.17);
  overflow: hidden;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => `${theme.spacingDefault}px ${theme.spacingComfortable}px`};
  width: 100%;
  color: ${({ theme }) => theme.colors.gray['100']};
  outline: 1px solid ${({ theme }) => transparentize(0.92, theme.colors.gray['100'])};
  outline-offset: -1px;

  ${({ theme, variant }) => getVariantStyles(theme, variant)};
`;

export const NoteContent = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => `${theme.spacingDefault}px`};
`;

const pulse = keyframes`
  0% {
    opacity: 0.1;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0.1;
  }
`;

export const Square = styled.div`
  aspect-ratio: 1;
  border: 1px solid ${({ theme }) => theme.colors.gray['100']};
  animation: ${pulse} 5s calc(var(--animation-delay) * 1s) infinite linear;
`;
export const Squares = styled.div`
  position: absolute;
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  height: 200%;
  top: 0;
  right: 10%;
  transform: translateY(-25%);
  gap: ${({ theme }) => `${theme.spacingDefault}px`};

  ${Array(20)
    .fill(0)
    .map(
      (_, i) => css`
        ${Square}:nth-child(${i}) {
          --animation-delay: ${-Math.round(5 * Math.random())};
        }
      `
    )};
`;
