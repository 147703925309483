import type { ContextType } from 'react';
import { EMPTY, of, Subject } from 'rxjs';

import { BlotterTableContext, type BlotterTableContextProps } from '../components/BlotterTable/BlotterTableContext';
import { AVA_PING, WebSocketStatus } from '../tokens';
import { PortalContextProvider } from './PortalContextProvider';
import { WebSocketContext, type WebSocketContextProps } from './WebSocketClientProvider';

import { ThemeContext } from 'styled-components';
import { CurrenciesContext, type CurrenciesContextProps } from '../contexts/CurrenciesContext';
import { EndpointsContext, type EndpointsContextProps } from '../contexts/EndpointsContext';
import { HomeCurrencyContext, type HomeCurrencyContextProps } from '../contexts/HomeCurrencyContext';
import { DEFAULT_LOCALE, intlDefaultFormatter } from '../contexts/IntlContext';
import { MarketsContext, type MarketsContextProps } from '../contexts/MarketsContext';
import { GET_MIXPANEL_MOCK, MixpanelContext } from '../contexts/MixpanelContext';
import { MixpanelSourceContext } from '../contexts/MixpanelSourceContext';
import { SecuritiesContext, type SecuritiesContextProps } from '../contexts/SecuritiesContext';
import { StrategiesContext, type StrategiesContextProps } from '../contexts/StrategiesContext';
import { UserContext, type UserContextProps } from '../contexts/UserContext';
import { AppwideDrawerProvider } from '../providers/AppwideDrawerProvider';
import { DialogProvider } from '../providers/DialogProvider';
import { GlobalToastsContext, type GlobalToastsProviderProps } from '../providers/GlobalToastsProvider';
import { DarkTheme } from '../styles';
import type { SubscriptionResponse } from '../types';
import type { RequestStream } from '../types/RequestStream';
import type { Security } from '../types/Security';
import type { ProductTypeEnum } from '../types/types';
import { EMPTY_OBJECT } from '../utils';
import { formattedDateForSubscription } from '../utils/date';
import { IntlProvider } from './IntlProvider';
import type { IWebSocketClient } from './WebSocketClient';
import type { Subscription } from './subscription';

interface CreateMockWebsocketContextParams {
  /**
   * An optional callback you can implement if you need more granular control over the mock sendMessage functionality.
   *
   * The default functionality this mock websocket employs is to just match messages <-> streams using stream.name === json.type.
   * However, this does not handle the case where you might have several streams registered within just one type.
   *
   * In these cases, this callback helps you. The callback gives you full control over to which stream a message should go.
   * On every message you try to send to sendMessage, this callback function will be called for every registered stream.
   *
   * The most default implementation of this callback would be `return json.type === stream.name`. On top of this you would add your
   * bespoke if-statements to split up message sending for specific types where you know you have several streams open.
   * @param json the message the websocket client is trying to send
   * @param stream the stream to consider for sending this message to
   * @returns true if the message should be sent on this stream, false if it should not be sent on this stream
   */
  shouldSendToThisStream?: (json: SubscriptionResponse<any>, stream: RequestStream) => boolean;
}

export const createMockWebsocketContext = ({
  shouldSendToThisStream,
}: CreateMockWebsocketContextParams = EMPTY_OBJECT) => {
  return (
    import.meta.env.VITE_IS_TEST_ENVIRONMENT
      ? ({
          client: {
            subscriptions: [] as Subscription<any>[],
            isConnected: true,
            sessionId: 'MOCK_SESSION',
            connect: () => {},
            registerSubscription(address, streams, callback, _) {
              this.subscriptions.push({
                address,
                streams,
                callback,
              });
              return () => this.unregisterSubscription(address);
            },
            updateSubscription(address, streams, callback) {
              this.subscriptions = [
                ...this.subscriptions.filter(s => s.address !== address),
                {
                  address,
                  streams,
                  callback,
                },
              ];
            },
            unregisterSubscription(address) {
              this.subscriptions = this.subscriptions.filter(s => s.address !== address);
            },
            pageSubscription() {
              // TODO
            },
            registerPublication() {
              // TODO
              return () => {};
            },
            sendMessage(json: any) {
              for (const subscription of this.subscriptions) {
                for (const stream of subscription.streams) {
                  const shouldSend = shouldSendToThisStream
                    ? shouldSendToThisStream(json, stream)
                    : stream.name === json.type;
                  if (shouldSend) {
                    subscription.callback(null, json);
                  }
                }
              }
            },
            sendTimedMessage(message) {
              return this.sendMessage({
                ...message,
                ts: formattedDateForSubscription(new Date()),
              });
            },
            ping(data) {
              return this.sendTimedMessage({
                type: AVA_PING,
                reqid: this.requestCount++,
                data: [data],
              });
            },
            pongs() {
              return EMPTY;
            },
            performance: null,
            closeAndReconnect() {},
            onOpen: new Subject(),
            onClose: new Subject(),
            onError: new Subject(),
          } as IWebSocketClient<any>,
          status: WebSocketStatus.CONNECTED,
          performance: null,
        } satisfies WebSocketContextProps<any>)
      : (null as unknown)
  ) as WebSocketContextProps<any>;
};

const MOCK_WEBSOCKET_CONTEXT = createMockWebsocketContext() as ContextType<typeof WebSocketContext>;

export const MOCK_USER_CONTEXT = (
  import.meta.env.VITE_IS_TEST_ENVIRONMENT
    ? {
        getOrganization: () =>
          new Promise(r =>
            r({
              ID: 1,
              Name: 'Talos',
              Users: [],
            })
          ),
      }
    : (null as unknown)
) as UserContextProps;

export const MOCK_ENDPOINTS_CONTEXT = (
  import.meta.env.VITE_IS_TEST_ENVIRONMENT
    ? {
        orgApiEndpoint: 'tal-1.talostrading.test:3002',
        wsEndpoint: 'tal-1.talostrading.test:8089',
      }
    : (null as unknown)
) as EndpointsContextProps;

const MOCK_MARKETS_CONTEXT = (
  import.meta.env.VITE_IS_TEST_ENVIRONMENT
    ? {
        marketsByName: new Map(
          Object.entries({
            binance: { DisplayName: 'Binance' },
            coinbase: { DisplayName: 'Coinbase' },
            kraken: { DisplayName: 'Kraken' },
            enclave: { DisplayName: 'Enclave', Type: 'Dark' },
            one_inch: { DisplayName: '1inch', Type: 'Dealer' },
          })
        ),
        marketsList: [
          { Name: 'binance', DisplayName: 'Binance', Type: 'Dealer' },
          { Name: 'coinbase', DisplayName: 'Coinbase', Type: 'InternalAlgo' },
          { Name: 'kraken', DisplayName: 'Kraken', Type: 'Exchange' },
          { Name: 'enclave', DisplayName: 'Enclave', Type: 'Dark' },
          { Name: 'one_inch', DisplayName: '1inch', Type: 'Dealer' },
        ],
        isMarketConfigured: (...args) => true,
        isMarketOnline: (...args) => true,
      }
    : (null as unknown)
) as MarketsContextProps;

const MOCK_CURRENCIES_CONTEXT = (
  import.meta.env.VITE_IS_TEST_ENVIRONMENT
    ? {
        currenciesBySymbol: new Map(
          Object.entries({
            USD: {
              Timestamp: '2020-09-17T14:20:48.159024Z',
              Symbol: 'USD',
              MinIncrement: '0.01',
              DefaultIncrement: '0.01',
              Description: 'U.S. Dollar',
            },
            BTC: {
              Timestamp: '2020-06-19T14:31:23.197493Z',
              Symbol: 'BTC',
              MinIncrement: '0.00000001',
              DefaultIncrement: '0.0001',
              Description: 'Bitcoin',
            },
            ETH: {
              Timestamp: '2020-06-19T14:31:23.197493Z',
              Symbol: 'ETH',
              MinIncrement: '0.0000001',
              DefaultIncrement: '0.001',
              Description: 'Ethereum',
            },
          })
        ),
        currenciesList: [
          {
            Timestamp: '2020-09-17T14:20:48.159024Z',
            Symbol: 'USD',
            MinIncrement: '0.01',
            DefaultIncrement: '0.01',
            Description: 'U.S. Dollar',
          },
          {
            Timestamp: '2020-06-19T14:31:23.197493Z',
            Symbol: 'BTC',
            MinIncrement: '0.00000001',
            DefaultIncrement: '0.0001',
            Description: 'Bitcoin',
          },
          {
            Timestamp: '2020-06-19T14:31:23.197493Z',
            Symbol: 'ETH',
            MinIncrement: '0.0000001',
            DefaultIncrement: '0.001',
            Description: 'Ethereum',
          },
        ],
        isLoaded: true,
      }
    : (null as unknown)
) as CurrenciesContextProps;

const BTCUSDSecurity = (
  import.meta.env.VITE_IS_TEST_ENVIRONMENT
    ? {
        Timestamp: '2021-03-19T11:21:31.746111Z',
        Symbol: 'BTC-USD',
        MinPriceIncrement: '0.01',
        MinSizeIncrement: '0.00000001',
        MinimumSize: '0',
        MaximumSize: '10000000',
        QuoteCurrency: 'USD',
        BaseCurrency: 'BTC',
        DefaultPriceIncrement: '0.01',
        DefaultSizeIncrement: '0.0001',
        PriceDisplaySpec: 'M.m',
        SizeDisplaySpec: 'M.m',
        NormalSize: '0.5',
        ProductType: 'Spot' as ProductTypeEnum.Spot,
        PositionCurrency: 'BTC',
        SettlementCurrency: 'USD',
        NotionalMultiplier: '1',
        DisplaySymbol: 'BTC-USD',
        Description: 'Bitcoin/U.S. Dollar',
        Markets: ['coinbase', 'kraken', 'flowtraders'],
        Rank: 1,
      }
    : (null as unknown)
) as Security;
const USDEURSecurity = (
  import.meta.env.VITE_IS_TEST_ENVIRONMENT
    ? {
        Symbol: 'USD-EUR',
        DisplaySymbol: 'USD-EUR',
        MinPriceIncrement: '0.000001',
        MinSizeIncrement: '0.00000001',
        MinimumSize: '0.01',
        MaximumSize: '10000000',
        Markets: ['market1', 'market2'],
        BaseCurrency: 'USD',
        QuoteCurrency: 'EUR',
        DefaultPriceIncrement: '0.01',
        DefaultSizeIncrement: '0.01',
        SizeBuckets: ['10', '25', '50', '100', '1000'],
        PriceDisplaySpec: 'm.MMMMMm',
        NormalSize: '1000',
        ProductType: 'Spot',
        PositionCurrency: 'USD',
        SettlementCurrency: 'EUR',
        Description: 'Ethereum/Bitcoin',
        SettleValueType: 'Regular',
      }
    : (null as unknown)
) as Security;
const BTCUSDCSecurity = (
  import.meta.env.VITE_IS_TEST_ENVIRONMENT
    ? {
        Timestamp: '2021-03-21T21:32:29.775797Z',
        Symbol: 'BTC-USDC',
        MinPriceIncrement: '0.00000001',
        MinSizeIncrement: '0.00000001',
        MinimumSize: '0',
        MaximumSize: '10000000',
        QuoteCurrency: 'USDC',
        BaseCurrency: 'BTC',
        DefaultPriceIncrement: '0.01',
        DefaultSizeIncrement: '0.0001',
        PriceDisplaySpec: 'M.m',
        SizeDisplaySpec: 'M.m',
        NormalSize: '0.5',
        ProductType: 'Spot' as ProductTypeEnum.Spot,
        PositionCurrency: 'BTC',
        SettlementCurrency: 'USDC',
        NotionalMultiplier: '1',
        DisplaySymbol: 'BTC-USDC',
        Description: 'Bitcoin/USD Coin',
        Markets: ['coinbase', 'binance', 'kraken', 'poloniex', 'bitstamp', 'alameda', 'b2c2', 'ftx-otc', 'binance_us'],
        Rank: 5,
      }
    : (null as unknown)
) as Security;

const BTCUSDTSecurity = (
  import.meta.env.VITE_IS_TEST_ENVIRONMENT
    ? {
        Timestamp: '2021-03-21T21:32:29.775797Z',
        Symbol: 'BTC-USDT',
        MinPriceIncrement: '0.00000001',
        MinSizeIncrement: '0.00000001',
        MinimumSize: '0',
        MaximumSize: '10000000',
        QuoteCurrency: 'USDT',
        BaseCurrency: 'BTC',
        DefaultPriceIncrement: '0.01',
        DefaultSizeIncrement: '0.0001',
        PriceDisplaySpec: 'M.m',
        SizeDisplaySpec: 'M.m',
        NormalSize: '0.5',
        ProductType: 'Spot' as ProductTypeEnum.Spot,
        PositionCurrency: 'BTC',
        SettlementCurrency: 'USDT',
        NotionalMultiplier: '1',
        DisplaySymbol: 'BTC-USDT',
        Description: 'Bitcoin/Tether USD',
        Markets: [
          'binance',
          'kraken',
          'poloniex',
          'bittrex',
          'alameda',
          'cumberland',
          'b2c2',
          'galaxy',
          'ftx-otc',
          'binance_us',
        ],
        Rank: 15,
      }
    : (null as unknown)
) as Security;

const ETHUSDSecurity = (
  import.meta.env.VITE_IS_TEST_ENVIRONMENT
    ? {
        Timestamp: '2021-03-19T11:21:31.746111Z',
        Symbol: 'ETH-USD',
        MinPriceIncrement: '0.01',
        MinSizeIncrement: '0.0000001',
        MinimumSize: '0',
        MaximumSize: '1000000',
        QuoteCurrency: 'USD',
        BaseCurrency: 'ETH',
        DefaultPriceIncrement: '0.01',
        DefaultSizeIncrement: '0.0001',
        PriceDisplaySpec: 'M.m',
        SizeDisplaySpec: 'M.m',
        NormalSize: '0.5',
        ProductType: 'Spot' as ProductTypeEnum.Spot,
        PositionCurrency: 'ETH',
        SettlementCurrency: 'USD',
        NotionalMultiplier: '1',
        DisplaySymbol: 'ETH-USD',
        Description: 'Ethereum/U.S. Dollar',
        Markets: ['coinbase', 'kraken', 'flowtraders'],
        Rank: 2,
      }
    : (null as unknown)
) as Security;

const ETHBTCSecurity = (
  import.meta.env.VITE_IS_TEST_ENVIRONMENT
    ? {
        Timestamp: '2021-03-19T11:21:31.746111Z',
        Symbol: 'ETH-BTC',
        MinPriceIncrement: '0.01',
        MinSizeIncrement: '0.0000001',
        MinimumSize: '0',
        MaximumSize: '1000000',
        QuoteCurrency: 'BTC',
        BaseCurrency: 'ETH',
        DefaultPriceIncrement: '0.01',
        DefaultSizeIncrement: '0.0001',
        PriceDisplaySpec: 'M.m',
        SizeDisplaySpec: 'M.m',
        NormalSize: '0.5',
        ProductType: 'Spot' as ProductTypeEnum.Spot,
        PositionCurrency: 'ETH',
        SettlementCurrency: 'BTC',
        NotionalMultiplier: '1',
        DisplaySymbol: 'ETH-BTC',
        Description: 'Ethereum/Bitcoin',
        Markets: ['coinbase', 'kraken', 'flowtraders'],
        Rank: 10,
      }
    : (null as unknown)
) as Security;

const BTCUSDPERPSecurity = (
  import.meta.env.VITE_IS_TEST_ENVIRONMENT
    ? {
        Timestamp: '2023-05-15T10:31:00.798474Z',
        Symbol: 'binance_futures:BTCUSD-PERP',
        MinPriceIncrement: '0.1',
        MinSizeIncrement: '1',
        MinimumSize: '1',
        MaximumSize: '0',
        QuoteCurrency: 'USD',
        BaseCurrency: 'BTC',
        DefaultPriceIncrement: '0.1',
        DefaultSizeIncrement: '1',
        PriceDisplaySpec: 'M.Mm',
        SizeDisplaySpec: 'M.m',
        NormalSize: '100',
        ProductType: 'PerpetualSwap',
        SettlementCurrency: 'BTC',
        NotionalMultiplier: '100',
        DisplaySymbol: 'BINANCE BTCUSD Perp',
        Description: 'BINANCE BTCUSD Coin Perpetual Swap',
        SizeBuckets: ['100', '250', '500', '1000', '2000'],
        Markets: ['binance_futures'],
        SettleValueType: 'Inverted',
        Rank: 7,
      }
    : (null as unknown)
) as Security;

const BTCUSDTPERPSecurity = (
  import.meta.env.VITE_IS_TEST_ENVIRONMENT
    ? {
        Timestamp: '2023-05-15T10:31:00.798474Z',
        Symbol: 'binance_futures:BTCUSDT-PERP',
        MinPriceIncrement: '0.1',
        MinSizeIncrement: '0.001',
        MinimumSize: '0.001',
        MaximumSize: '0',
        QuoteCurrency: 'USDT',
        BaseCurrency: 'BTC',
        DefaultPriceIncrement: '0.1',
        DefaultSizeIncrement: '0.001',
        PriceDisplaySpec: 'M.m',
        SizeDisplaySpec: 'M.m',
        NormalSize: '1',
        ProductType: 'PerpetualSwap',
        PositionCurrency: 'BTC',
        SettlementCurrency: 'USDT',
        NotionalMultiplier: '1',
        DisplaySymbol: 'BINANCE BTCUSDT Perp',
        Description: 'BINANCE BTCUSDT Perpetual Swap',
        SizeBuckets: ['1', '5', '10', '50', '100'],
        Markets: ['binance_futures'],
        SettleValueType: 'Regular',
        Rank: 1,
      }
    : (null as unknown)
) as Security;

const okxBTCUSDTPERPSecurity = (
  import.meta.env.VITE_IS_TEST_ENVIRONMENT
    ? {
        Timestamp: '2023-05-15T10:31:00.798474Z',
        Symbol: 'okx:BTCUSDT-PERP',
        MinPriceIncrement: '0.1',
        MinSizeIncrement: '1',
        MinimumSize: '1',
        MaximumSize: '0',
        QuoteCurrency: 'USDT',
        BaseCurrency: 'BTC',
        DefaultPriceIncrement: '0.1',
        DefaultSizeIncrement: '1',
        PriceDisplaySpec: 'M.m',
        SizeDisplaySpec: 'M.m',
        NormalSize: '10',
        ProductType: 'PerpetualSwap',
        SettlementCurrency: 'USDT',
        NotionalMultiplier: '0.01',
        DisplaySymbol: 'OKX BTC-USDT-SWAP Perp',
        Description: 'OKX BTC-USDT-SWAP Perpetual Swap',
        SizeBuckets: ['10', '25', '50', '100', '200'],
        Markets: ['okex'],
        SettleValueType: 'Regular',
        Rank: 5,
      }
    : (null as unknown)
) as Security;

const okxBTCUSDPERPSecurity = (
  import.meta.env.VITE_IS_TEST_ENVIRONMENT
    ? {
        Timestamp: '2024-10-14T23:39:08.187524Z',
        Symbol: 'okx:BTCUSD-PERP',
        MinPriceIncrement: '0.1',
        MinSizeIncrement: '1',
        MinAmountIncrement: '0',
        MinimumSize: '1',
        MaximumSize: '0',
        QuoteCurrency: 'USD',
        BaseCurrency: 'BTC',
        DefaultPriceIncrement: '0.1',
        DefaultSizeIncrement: '1',
        PriceDisplaySpec: 'M.Mm',
        SizeDisplaySpec: 'M.m',
        NormalSize: '100',
        ProductType: 'PerpetualSwap',
        SettlementCurrency: 'BTC',
        NotionalMultiplier: '100',
        DisplaySymbol: 'OKX BTC-USD-SWAP Perp',
        Description: 'OKX BTC-USD-SWAP Coin Perpetual Swap',
        SizeBuckets: ['100', '250', '500', '1000', '2000'],
        Markets: ['okex'],
        SettleValueType: 'Inverted',
        Rank: 18,
      }
    : (null as unknown)
) as Security;

const ETHBTCviaUSDSecurity = (
  import.meta.env.VITE_AVA_ENV === 'test'
    ? {
        Timestamp: '2022-12-01T19:50:22.621473Z',
        Symbol: 'ETH-BTC:USD',
        MinPriceIncrement: '0.00000001',
        MinSizeIncrement: '0.00000001',
        MinimumSize: '0.00000001',
        MaximumSize: '100000000',
        QuoteCurrency: 'BTC',
        BaseCurrency: 'ETH',
        DefaultPriceIncrement: '0.00001',
        DefaultSizeIncrement: '0.0001',
        PriceDisplaySpec: 'M.MMMM',
        SizeDisplaySpec: 'M.m',
        NormalSize: '1',
        ProductType: 'Spot',
        PositionCurrency: 'ETH',
        SettlementCurrency: 'BTC',
        NotionalMultiplier: '1',
        Composition: 'Synthetic',
        StrikePrice: '0',
        DisplaySymbol: 'ETH-BTC via USD',
        Description: 'ETH-BTC via USD',
        SizeBuckets: ['2', '5', '10', '20', '40'],
        Markets: ['multileg'],
        SettleValueType: 'Regular',
        Rank: 1,
        MultilegDetails: {
          FeeMode: 'Net',
          Legs: [
            {
              Category: '',
              Direction: 'Same',
              Index: 0,
              Markets: [
                {
                  Market: 'coinbase',
                  MarketAccount: 'coinbase/coinbase',
                },
                {
                  Market: 'kraken',
                  MarketAccount: 'kraken/kraken',
                },
                {
                  Market: 'b2c2',
                  MarketAccount: 'b2c2/b2c2',
                },
                {
                  Market: 'cumberland',
                  MarketAccount: 'cumberland/cumberland',
                },
                {
                  Market: 'galaxy',
                  MarketAccount: 'galaxy/galaxy',
                },
                {
                  Market: 'dvchain',
                  MarketAccount: 'dvchain/dvchain',
                },
                {
                  Market: 'osl_am',
                  MarketAccount: 'osl_am/osl_am',
                },
              ],
              Symbol: 'ETH-USD',
            },
            {
              Category: '',
              Direction: 'Opposite',
              Index: 1,
              Markets: [
                {
                  Market: 'coinbase',
                  MarketAccount: 'coinbase/coinbase',
                },
                {
                  Market: 'kraken',
                  MarketAccount: 'kraken/kraken',
                },
                {
                  Market: 'b2c2',
                  MarketAccount: 'b2c2/b2c2',
                },
                {
                  Market: 'cumberland',
                  MarketAccount: 'cumberland/cumberland',
                },
                {
                  Market: 'galaxy',
                  MarketAccount: 'galaxy/galaxy',
                },
                {
                  Market: 'dvchain',
                  MarketAccount: 'dvchain/dvchain',
                },
                {
                  Market: 'osl_am',
                  MarketAccount: 'osl_am/osl_am',
                },
              ],
              Symbol: 'BTC-USD',
            },
          ],
          MarketDataDepth: 20,
          Parameters: {
            LegParams: [
              {
                Initiating: true,
              },
              {
                Initiating: true,
              },
            ],
          },
          ProductType: 'Spot',
        },
      }
    : (null as unknown)
) as Security;

const OptionSecurity = (
  import.meta.env.VITE_IS_TEST_ENVIRONMENT
    ? {
        Timestamp: '2022-12-15T02:15:39.074880Z',
        Symbol: 'deribit:BTC-31MAR23-25000-P',
        MinPriceIncrement: '0.0005',
        MinSizeIncrement: '0.1',
        MinimumSize: '0.1',
        MaximumSize: '0',
        QuoteCurrency: 'BTC',
        BaseCurrency: 'BTC',
        DefaultPriceIncrement: '0',
        DefaultSizeIncrement: '0',
        PriceDisplaySpec: 'M.MMMm',
        SizeDisplaySpec: 'M.m',
        NormalSize: '1',
        ProductType: 'Option',
        SettlementCurrency: 'BTC',
        NotionalMultiplier: '1',
        Expiration: '2022-03-31T08:00:00.000000Z',
        OptionType: 'Put',
        StrikePrice: '25000',
        UnderlyingSymbol: 'deribit:BTC-31MAR23',
        DisplaySymbol: 'Deribit BTC-31MAR23-25000-P',
        Description: 'Deribit BTC Put Option 31MAR23 25000',
        SizeBuckets: ['1', '2', '5', '10', '50'],
        Markets: ['deribit'],
        SettleValueType: 'Regular',
      }
    : (null as unknown)
) as Security;

const CalendarSpreadSecurity = (
  import.meta.env.VITE_IS_TEST_ENVIRONMENT
    ? {
        Timestamp: '2023-11-02T15:54:01.563775Z',
        Symbol: 'cme:BTCX3-BTCZ3_20231124',
        MinPriceIncrement: '1',
        MinSizeIncrement: '1',
        MinAmountIncrement: '0',
        MinimumSize: '1',
        MaximumSize: '0',
        QuoteCurrency: 'USD',
        BaseCurrency: 'BTC',
        DefaultPriceIncrement: '1',
        DefaultSizeIncrement: '1',
        PriceDisplaySpec: 'M.m',
        SizeDisplaySpec: 'M.m',
        NormalSize: '1',
        ProductType: 'CalendarSpread',
        SettlementCurrency: 'USD',
        NotionalMultiplier: '5',
        Expiration: '2023-11-24T16:00:00.000000Z',
        DisplaySymbol: 'BTCX3-BTCZ3',
        Description: 'CME BTC-USD 231124 Futures',
        SizeBuckets: ['1', '2', '5', '10', '50'],
        Markets: ['cme'],
        SettleValueType: 'Regular',
        Legs: [
          {
            Symbol: 'cme:BTCX3_20231124',
            Direction: 'Same',
          },
          {
            Symbol: 'cme:BTCZ3_20231229',
            Direction: 'Same',
          },
        ],
      }
    : (null as unknown)
) as Security;

const multilegSameMarketSecurity = (
  import.meta.env.VITE_IS_TEST_ENVIRONMENT
    ? {
        Timestamp: '2023-11-07T11:27:37.017471Z',
        Symbol: 'BTC-USDT|bybit:BTCUSDT-PERP-2',
        MinPriceIncrement: '0.1',
        MinSizeIncrement: '0.001000000000',
        MinAmountIncrement: '0',
        MinimumSize: '0.001000000000',
        MaximumSize: '100000000',
        QuoteCurrency: 'USDT',
        BaseCurrency: 'BTC',
        DefaultPriceIncrement: '0.1',
        DefaultSizeIncrement: '0.001000000000',
        PriceDisplaySpec: 'M.m',
        SizeDisplaySpec: 'M.m',
        NormalSize: '1',
        ProductType: 'Synthetic',
        PositionCurrency: 'BTC',
        SettlementCurrency: 'USDT',
        NotionalMultiplier: '1',
        Composition: 'Synthetic',
        StrikePrice: '0',
        DisplaySymbol: 'BTC-USDT v BTCUSDT.P BB 2',
        Description: 'BTC-USDT vs BYBIT BTCUSDT Perp',
        SizeBuckets: ['1', '5', '10', '50', '100'],
        Markets: ['multileg'],
        SettleValueType: 'Regular',
        Rank: 1,
        MultilegDetails: {
          FeeMode: 'Net',
          Legs: [
            {
              Category: '',
              Direction: 'Same',
              Index: 0,
              Markets: [
                {
                  Market: 'bybit',
                  MarketAccount: 'bybit/by-bit',
                },
              ],
              Symbol: 'BTC-USDT',
            },
            {
              Category: '',
              Direction: 'Opposite',
              Index: 1,
              Markets: [
                {
                  Market: 'bybit',
                  MarketAccount: 'bybit/by-bit',
                },
              ],
              Symbol: 'bybit:BTCUSDT-PERP',
            },
          ],
          MarketDataDepth: 20,
          Parameters: {
            LegParams: [
              {
                CrossingAllowed: true,
                Initiating: true,
                MaxRestingLevels: 1,
              },
              {
                CrossingAllowed: true,
                Initiating: true,
                MaxRestingLevels: 1,
              },
            ],
          },
          ProductType: 'Synthetic',
          SyntheticProductType: 'Delta1Spread',
        },
      }
    : (null as unknown)
) as Security;

export const MOCK_SECURITIES_CONTEXT = (
  import.meta.env.VITE_IS_TEST_ENVIRONMENT
    ? {
        securitiesBySymbol: new Map(
          Object.entries({
            'BTC-USD': BTCUSDSecurity,
            'BTC-USDC': BTCUSDCSecurity,
            'BTC-USDT': BTCUSDTSecurity,
            'ETH-USD': ETHUSDSecurity,
            'ETH-BTC': ETHBTCSecurity,
            'binance_futures:BTCUSD-PERP': BTCUSDPERPSecurity, // Inverted (can be traded in contracts)
            'binance_futures:BTCUSDT-PERP': BTCUSDTPERPSecurity, // Regular (cannot be traded in contracts)
            'okx:BTCUSDT-PERP': okxBTCUSDTPERPSecurity, // Regular (can be traded in contracts)
            'okx:BTCUSD-PERP': okxBTCUSDPERPSecurity, // Inverted (can be traded in contracts)
            'ETH-BTC:USD': ETHBTCviaUSDSecurity,
            'deribit:BTC-31MAR23-25000-P': OptionSecurity,
            'cme:BTCX3-BTCZ3_20231124': CalendarSpreadSecurity,
            'BTC-USDT|bybit:BTCUSDT-PERP-2': multilegSameMarketSecurity,
            'USD-EUR': USDEURSecurity,
          })
        ),
        searchableSecurities: [
          BTCUSDSecurity,
          ETHUSDSecurity,
          ETHBTCSecurity,
          BTCUSDTSecurity,
          BTCUSDCSecurity,
          USDEURSecurity,
        ],
        securitiesList: [
          BTCUSDSecurity,
          ETHUSDSecurity,
          ETHBTCSecurity,
          BTCUSDTSecurity,
          BTCUSDCSecurity,
          USDEURSecurity,
        ],
        securitiesListSortedByRank: [
          BTCUSDSecurity,
          ETHUSDSecurity,
          ETHBTCSecurity,
          BTCUSDTSecurity,
          BTCUSDCSecurity,
          USDEURSecurity,
        ].sort((a, b) => ((a.Rank ?? Infinity) < (b.Rank ?? Infinity) ? -1 : 1)),
        isLoaded: true,
      }
    : (null as unknown)
) as SecuritiesContextProps;

const STRATEGIES_MAP = new Map(
  Object.entries({
    Limit: {
      Name: 'Limit',
      Group: 'All',
      Parameters: [
        {
          Name: 'EndTime',
          Type: 'Date',
          Presence: 'Optional',
          Description: 'Expire time for the order.',
          DisplayName: 'End Time',
        },
      ],
      Description:
        'For marketable orders, the algorithm will sweep the available liquidity up to the limit price by routing to those market(s) offering the best prices. The remaining quantity will be split equally among the selected market(s) at the limit price.',
      DisplayName: 'Limit',
    },
    MultilegLimit: {
      Name: 'MultilegLimit',
      Group: 'All',
      AlgoType: 'Base',
      InstrumentScope: 'Synthetic',
      Parameters: [
        {
          Name: 'StartTime',
          Type: 'Date',
          Presence: 'Optional',
          Description: 'Time at which this order will activate and begin sending orders to the market.',
          DisplayName: 'Start Time',
        },
        {
          Name: 'EndTime',
          Type: 'Date',
          Presence: 'Optional',
          Description: 'Expire time for the order.',
          DisplayName: 'End Time',
        },
      ],
      Description:
        'For marketable orders, the algorithm will sweep the available liquidity up to the limit price by routing to those market(s) offering the best prices. The remaining quantity will be split equally among the selected market(s) at the limit price.',
      DisplayName: 'Limit',
    },
    TWAP: {
      Name: 'TWAP',
      Group: 'Exchange',
      Parameters: [
        {
          Name: 'EndTime',
          Type: 'Date',
          Presence: 'Required',
          Description: 'Expire time for the order.',
          DisplayName: 'End Time',
        },
      ],
      Description:
        'Time-Weighted Average Price algorithm that trades over a specified duration of time, breaking up the order into smaller parts. The algorithm is geared to minimize market impact and reduce trading fees.',
      DisplayName: 'TWAP',
    },
    EnclaveCross: {
      // Dark Pool strategy
      Name: 'EnclaveCross',
      Group: 'Dark',
      DisplayName: 'Enclave Cross',
    },
  })
);

const USERS = [
  {
    ID: '000',
    Name: 'I am the boss',
    Email: 'boss@talos.com',
    Roles: ['talos.admin'],
  },
  {
    ID: '007',
    Name: 'James Bond',
    Email: 'james@talos.com',
    Roles: ['org.viewer'],
  },
];

export const MOCK_STRATEGIES_CONTEXT = (
  import.meta.env.VITE_IS_TEST_ENVIRONMENT
    ? {
        strategiesByNameObs: of(STRATEGIES_MAP),
        strategiesByName: STRATEGIES_MAP,
        strategiesList: [...STRATEGIES_MAP.values()],
      }
    : (null as unknown)
) as StrategiesContextProps;

const MOCK_BLOTTER_TABLE_CONTEXT = (
  import.meta.env.VITE_IS_TEST_ENVIRONMENT
    ? ({
        currenciesBySymbol: MOCK_CURRENCIES_CONTEXT.currenciesBySymbol,
        securitiesBySymbol: MOCK_SECURITIES_CONTEXT.securitiesBySymbol,
        strategies: MOCK_STRATEGIES_CONTEXT.strategiesByNameObs,
        userByID: new Map(USERS.map(user => [user.ID, user])),
        userByEmail: new Map(USERS.map(user => [user.Email, user])),
        currentUser: USERS[1], // non-Talos user
        intl: intlDefaultFormatter,
      } as unknown)
    : (null as unknown)
) as BlotterTableContextProps;

const MOCK_HOME_CURRENCY = (
  import.meta.env.VITE_IS_TEST_ENVIRONMENT ? { homeCurrency: 'USD' } : (null as unknown)
) as HomeCurrencyContextProps;

const MOCK_GLOBAL_TOAST_CONTEXT = (
  import.meta.env.VITE_IS_TEST_ENVIRONMENT
    ? {
        add: () => undefined,
        remove: () => undefined,
        toasts: [],
      }
    : (null as unknown)
) as GlobalToastsProviderProps;

export const TestProviders = props => {
  if (import.meta.env.VITE_IS_TEST_ENVIRONMENT) {
    return (
      <MixpanelContext.Provider value={GET_MIXPANEL_MOCK}>
        <MixpanelSourceContext.Provider value={'test' as any}>
          <UserContext.Provider value={MOCK_USER_CONTEXT}>
            <DialogProvider>
              <EndpointsContext.Provider value={MOCK_ENDPOINTS_CONTEXT}>
                <WebSocketContext.Provider value={MOCK_WEBSOCKET_CONTEXT}>
                  <MarketsContext.Provider value={MOCK_MARKETS_CONTEXT}>
                    <CurrenciesContext.Provider value={MOCK_CURRENCIES_CONTEXT}>
                      <SecuritiesContext.Provider value={MOCK_SECURITIES_CONTEXT}>
                        <StrategiesContext.Provider value={MOCK_STRATEGIES_CONTEXT}>
                          <HomeCurrencyContext.Provider value={MOCK_HOME_CURRENCY}>
                            <BlotterTableContext.Provider value={MOCK_BLOTTER_TABLE_CONTEXT}>
                              <GlobalToastsContext.Provider value={MOCK_GLOBAL_TOAST_CONTEXT}>
                                <ThemeContext.Provider value={DarkTheme}>
                                  <PortalContextProvider>
                                    <AppwideDrawerProvider>
                                      <IntlProvider locale={DEFAULT_LOCALE}>{props.children}</IntlProvider>
                                    </AppwideDrawerProvider>
                                  </PortalContextProvider>
                                </ThemeContext.Provider>
                              </GlobalToastsContext.Provider>
                            </BlotterTableContext.Provider>
                          </HomeCurrencyContext.Provider>
                        </StrategiesContext.Provider>
                      </SecuritiesContext.Provider>
                    </CurrenciesContext.Provider>
                  </MarketsContext.Provider>
                </WebSocketContext.Provider>
              </EndpointsContext.Provider>
            </DialogProvider>
          </UserContext.Provider>
        </MixpanelSourceContext.Provider>
      </MixpanelContext.Provider>
    );
  }
  return null;
};
