import React, { createContext, useMemo } from 'react';
import { useSocketClient } from '../../WebSocketClientProvider';
import { useWLOrdersProvider } from '../../WLOrdersProvider';
import { useWLOrderFormStore } from '../WLOrderFormStore';
import { OrderService } from './OrderService';

type OrderServiceContextProps = { orderService: OrderService };
export const OrderServiceContext = createContext<OrderServiceContextProps | undefined>(undefined);

export function OrderServiceProvider(props: React.PropsWithChildren) {
  const store = useWLOrderFormStore();
  const webSocketClient = useSocketClient();
  const { sessionOrdersObs } = useWLOrdersProvider();
  const service = useMemo(
    () =>
      new OrderService(
        store,
        webSocketClient,
        import.meta.env.VITE_IS_MOBILE ? { dismissesActionAfterTimeout: false } : undefined,
        sessionOrdersObs
      ),
    [store, webSocketClient, sessionOrdersObs]
  );

  return (
    <OrderServiceContext.Provider value={{ orderService: service }}>{props.children}</OrderServiceContext.Provider>
  );
}

export const useOrderServiceContext = () => {
  const context = React.useContext(OrderServiceContext);
  if (context === undefined) {
    throw new Error('Missing OrderServiceContext.Provider further up in the tree. Did you forget to add it?');
  }
  return context;
};
