import Big from 'big.js';
import { useEffect, useState, type PropsWithChildren, type ReactNode } from 'react';
import { bigMin, toBigWithDefault } from '../../utils';
import { Bar, BarWrapper, Label, MeterWrapper } from './styles';

interface SharedProps {
  backgroundColor?: string;
  /** Whether or not to show the standard % label to the right of the bar (eg 95.00 %) */
  showLabel?: boolean;
  label?: string;
  animate?: boolean;
  showInitialAnimation?: boolean;
  /** Whether or not to round the starting edge of the bar. Defaults to true. */
  roundStartingEdge?: boolean;
  height?: number;
  className?: string;
}

function asPercent(value: number | string | Big) {
  const valueBig = toBigWithDefault(value, 0);
  return valueBig.gt(0) ? valueBig.times(100).toNumber() : 0;
}

export type MeterWithChildrenProps = PropsWithChildren<SharedProps>;
export type MeterWithValueProps = SharedProps & {
  value: number;
  animate?: boolean;
  appearance?: 'filled' | 'dashed';
  alignBar?: 'left' | 'right';
  color?: string;
};

export type { MeterBarProps } from './styles';

export type MeterProps = MeterWithChildrenProps | MeterWithValueProps;

export function Meter(props: MeterWithChildrenProps): ReactNode;
export function Meter(props: MeterWithValueProps): ReactNode;
export function Meter(props: MeterProps): ReactNode {
  const {
    backgroundColor,
    showLabel = true,
    showInitialAnimation = true,
    label,
    roundStartingEdge = true,
    height,
  } = props;

  const value = 'value' in props ? props.value : undefined;
  const children = 'children' in props ? props.children : undefined;

  const [percent, setPercent] = useState<number>(value != null && !showInitialAnimation ? asPercent(value) : 0);
  useEffect(() => {
    if (value == null) {
      return;
    }
    // Allow initial render and then set width to get animation
    if (percent === 0) {
      const timer = setTimeout(() => setPercent(asPercent(value)), 200);
      return () => clearTimeout(timer);
    } else {
      setPercent(asPercent(value));
    }
  }, [value, percent]);

  const alignBar = 'alignBar' in props ? props.alignBar : 'left';
  const roundLeftEdge = alignBar === 'right' || (alignBar === 'left' && roundStartingEdge);
  const roundRightEdge = alignBar === 'left' || (alignBar === 'right' && roundStartingEdge);

  return (
    <MeterWrapper className={props?.className}>
      <BarWrapper
        background={backgroundColor}
        roundLeftEdge={roundLeftEdge}
        roundRightEdge={roundRightEdge}
        height={height}
      >
        {'value' in props ? (
          <Bar
            width={toBigWithDefault(bigMin(percent, 100), 0).toNumber()}
            color={props.color}
            align={alignBar}
            animate={props.animate}
            appearance={props.appearance}
          />
        ) : (
          children
        )}
      </BarWrapper>
      {showLabel && <Label>{label ?? `${Big(percent).toFixed(2)}%`}</Label>}
    </MeterWrapper>
  );
}

Meter.Bar = Bar;
