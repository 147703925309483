import { useCallback, useEffect, useMemo, useRef, useState, type KeyboardEvent as ReactKeyboardEvent } from 'react';
import { defineMessages } from 'react-intl';
import { useMixpanel } from '../../../contexts';
import { useIntl } from '../../../hooks';
import { useGlobalToasts } from '../../../providers/GlobalToastsProvider';
import { MixpanelEvent, MixpanelEventProperty } from '../../../tokens';
import { Button, ButtonVariants, IconButton } from '../../Button';
import { Flex } from '../../Core';
import { useDropdownPopper } from '../../Form/Dropdown';
import { Input } from '../../Form/Input';
import { useSearchSelect } from '../../Form/SearchSelect';
import { IconName } from '../../Icons';
import { FormattedMessage } from '../../Intl';
import { NotificationVariants } from '../../Notification';
import { Text } from '../../Text';
import { CopyPasteFilter } from './CopyPasteFilter/CopyPasteFilter';
import { PropertyDropdown } from './PropertyDropdown';
import { PropertyMultiSelectList } from './RHS/PropertyMultiSelectList';
import { PropertySingleSelectList } from './RHS/PropertySingleSelectList';
import { PropertyTextList } from './RHS/PropertyTextList';
import { FilterBuilderContent, FilterClauseWrapper, Middle, PropertyButton } from './styles';
import type { FilterBuilderSide, FilterClause, FilterableProperty, UseFilterBuilderOutput } from './types';
import {
  findCurrentlyFocusedPropertyLHSByRef,
  getNextElementToFocus,
  getPreviousElementToFocus,
} from './utils/FocusTabHelper';

export type FilterBuilderProps = UseFilterBuilderOutput & { showCopyPaste?: boolean }; // maybe add more here later

const messages = defineMessages({
  addFilter: {
    defaultMessage: 'Add filter',
    id: 'Filters.FilterBuilder.addFilter',
  },
  filterOptions: {
    defaultMessage: 'Filter options',
    id: 'Filters.FilterBuilder.filterOptions',
  },
  filtersPasted: {
    defaultMessage: 'Filters pasted to current tab',
    id: 'Filters.FilterBuilder.filtersPasted',
  },
  in: {
    defaultMessage: 'in',
    id: 'Filters.FilterBuilder.in',
  },
  is: {
    defaultMessage: 'is',
    id: 'Filters.FilterBuilder.is',
  },
});

const getFilterablePropertyLabel = ({ label }: FilterableProperty) => label;

export const DEFAULT_FILTER_BUILDER_DROPDOWN_HEIGHT = 200;
export const DEFAULT_FILTER_BUILDER_DROPDOWN_GROUP_HEIGHT = 200;

export const FilterBuilder = ({
  propertiesByKey,
  propertiesList,
  filterClauses,
  filterClausesByPropertyKey,
  addFilterClause,
  replaceFilterClause,
  removeFilterClause,
  resetFilterClauses,
  setFilterClauseSelections,
  refs,
  updateSelectionRefs,
  showCopyPaste = false,
}: FilterBuilderProps) => {
  const { formatMessage } = useIntl();
  const mixpanel = useMixpanel();
  const handleSelectionsChange: typeof setFilterClauseSelections = useCallback(
    (...args) => {
      const [property] = args;
      mixpanel.track(MixpanelEvent.ApplyFilterBuilderFilter, {
        [MixpanelEventProperty.Filter]: property,
      });
      return setFilterClauseSelections(...args);
    },
    [mixpanel, setFilterClauseSelections]
  );

  const { add: addToast } = useGlobalToasts();
  // you can either be setting up a new property to filter on, or be editing an existing one. (new property = editMode undefined)
  const [editProperty, setEditProperty] = useState<string | undefined>(undefined);

  const propertyInputRef = useRef<HTMLInputElement>(null);

  const selectableProperties = useMemo(() => {
    return propertiesList.filter(({ key }) => !filterClausesByPropertyKey.has(key));
  }, [propertiesList, filterClausesByPropertyKey]);

  const handlePropertyChange = useCallback(
    (newProperty?: FilterableProperty) => {
      if (!newProperty) {
        return;
      }
      if (editProperty === undefined) {
        addFilterClause(newProperty.key);
      } else {
        replaceFilterClause(editProperty, newProperty.key);
      }
    },
    [editProperty, addFilterClause, replaceFilterClause]
  );

  const searchSelectDropdown = useSearchSelect({
    items: selectableProperties,
    inputRef: propertyInputRef,
    onChange: handlePropertyChange,
    getLabel: getFilterablePropertyLabel,
    enableTabSelect: false,
  });

  const { getInputProps, openMenu, isOpen, closeMenu } = searchSelectDropdown;

  const [dropdownRef, setDropdownRef] = useState<HTMLButtonElement | null>(null);
  const dropdownPopper = useDropdownPopper({
    referenceElement: dropdownRef,
    dropdownWidth: '240px',
    dropdownPlacement: 'bottom-start',
    isOpen: isOpen,
  });

  const updatePopperPosition = dropdownPopper.popper.update;

  useEffect(() => {
    if (filterClauses) {
      // every time the clauses change, we update the popper position
      updatePopperPosition && updatePopperPosition();
    }
  }, [filterClauses, updatePopperPosition]);

  const handlePropertyClick = useCallback(
    (targetRef: HTMLButtonElement | null, editProperty: string | undefined, side: FilterBuilderSide = 'lhs') => {
      if (side === 'lhs') {
        setEditProperty(editProperty);
        setDropdownRef(targetRef);
        openMenu();
      } else {
        // rhs
        targetRef?.click();
      }
    },
    [openMenu]
  );

  const handleTabForwards = useCallback(
    (currentlyFocusedPropertyKey: string | undefined, focusedSide: FilterBuilderSide) => {
      if (currentlyFocusedPropertyKey === undefined) {
        // means we are tabbing from the "addButtonRef" dropdown
        refs.addButtonRef.current?.focus();
        return;
      }

      const result = getNextElementToFocus(currentlyFocusedPropertyKey, refs, focusedSide);
      if (!result) {
        return;
      }

      const { propertyKey, ref, side } = result;

      // pretend that we clicked this new button we found
      handlePropertyClick(ref.current, propertyKey, side);
    },
    [refs, handlePropertyClick]
  );

  const handleTabBackwards = useCallback(
    (currentlyFocusedPropertyKey: string | undefined) => {
      const result = getPreviousElementToFocus(currentlyFocusedPropertyKey, refs);

      if (result === undefined) {
        // there is no previous element, focus the first button in the FilterBuilder, being either a set property or the addButtonRef
        if (refs.refsByPropertyKey.size > 0) {
          const firstProperty = [...refs.refsByPropertyKey.values()][0];
          firstProperty.property.current?.focus();
        } else {
          refs.addButtonRef.current?.focus();
        }
        return;
      }

      const { propertyKey, ref, side } = result;

      // pretend that we clicked this new button we found
      handlePropertyClick(ref.current, propertyKey, side);
    },
    [refs, handlePropertyClick]
  );

  const handlePropertyDropdownTabOut = useCallback(
    (event: ReactKeyboardEvent<HTMLElement>) => {
      event.stopPropagation();
      event.preventDefault();
      closeMenu();

      // we need to figure out which property the dropdown is currently attached to, then tab from that "anchor" element essentially
      if (dropdownRef == null) {
        return;
      }
      const currentlyFocusedPropertyKey = findCurrentlyFocusedPropertyLHSByRef(dropdownRef, refs);
      if (event.shiftKey) {
        handleTabBackwards(currentlyFocusedPropertyKey);
      } else {
        handleTabForwards(currentlyFocusedPropertyKey, 'lhs');
      }
    },
    [closeMenu, handleTabForwards, dropdownRef, refs, handleTabBackwards]
  );

  const handleSelectionListDropdownTabOut = useCallback(
    (propertyKey: string, event: ReactKeyboardEvent<HTMLElement>) => {
      if (event.shiftKey) {
        handleTabBackwards(propertyKey);
      } else {
        handleTabForwards(propertyKey, 'rhs');
      }
    },
    [handleTabForwards, handleTabBackwards]
  );

  const handleSubmitCopiedFilterClauses = useCallback(
    (newFilterClauses: FilterClause[]) => {
      resetFilterClauses(newFilterClauses);
      addToast({ text: <FormattedMessage {...messages.filtersPasted} />, variant: NotificationVariants.Positive });
    },
    [resetFilterClauses, addToast]
  );

  return (
    <Flex gap="spacingMedium" alignItems="stretch" justifyContent="space-between">
      <FilterBuilderContent gap="spacingMedium" data-testid="filter-builder-content">
        {filterClauses.map(({ key, selections }) => {
          const property = propertiesByKey.get(key);
          const propertyRefs = refs.refsByPropertyKey.get(key);
          if (!propertyRefs) {
            return null;
          }
          const propertyRef = propertyRefs.property;
          return (
            property && (
              <FilterClauseWrapper key={key} data-testid={`filter-builder-clause-${key}`}>
                <div>
                  <PropertyButton
                    variant={ButtonVariants.Default}
                    onClick={() => handlePropertyClick(propertyRef.current, key)}
                    startIcon={property.icon}
                    ref={propertyRef}
                    data-testid="filter-builder-clause-property"
                  >
                    {property.label}
                  </PropertyButton>
                </div>
                <Middle alignItems="center">
                  <Text>
                    <FormattedMessage {...messages[selections.length > 1 ? 'in' : 'is']} />
                  </Text>
                </Middle>
                {property.control === 'single-select' && (
                  <PropertySingleSelectList
                    property={property}
                    selections={selections}
                    onSelectionsChange={newSelections => handleSelectionsChange(key, newSelections)}
                    refs={propertyRefs}
                    updateSelectionRefs={updateSelectionRefs}
                    onRemove={() => removeFilterClause(property.key)}
                    onDropdownTabOut={event => handleSelectionListDropdownTabOut(property.key, event)}
                  />
                )}
                {(property.control === 'multi-select' || property.control == null) && (
                  // Default to the multi-select list for modifying clause selections
                  <PropertyMultiSelectList
                    property={property}
                    selections={selections}
                    onSelectionsChange={newSelections => handleSelectionsChange(key, newSelections)}
                    refs={propertyRefs}
                    updateSelectionRefs={updateSelectionRefs}
                    onRemove={() => removeFilterClause(property.key)}
                    onDropdownTabOut={event => handleSelectionListDropdownTabOut(property.key, event)}
                  />
                )}
                {property.control === 'text' && (
                  <PropertyTextList
                    property={property}
                    selections={selections}
                    onSelectionsChange={newSelections => handleSelectionsChange(key, newSelections)}
                    refs={propertyRefs}
                    updateSelectionRefs={updateSelectionRefs}
                    onRemove={() => removeFilterClause(property.key)}
                    onDropdownTabOut={event => handleSelectionListDropdownTabOut(property.key, event)}
                  />
                )}
              </FilterClauseWrapper>
            )
          );
        })}
        <div>
          {filterClausesByPropertyKey.size === 0 ? (
            <Button
              data-testid="add-filter-button"
              variant={ButtonVariants.Default}
              onClick={() => handlePropertyClick(refs.addButtonRef.current, undefined)}
              endIcon={IconName.Plus}
              ref={refs.addButtonRef}
            >
              <FormattedMessage {...messages.addFilter} />
            </Button>
          ) : (
            <IconButton
              data-testid="add-filter-button"
              variant={ButtonVariants.Default}
              onClick={() => handlePropertyClick(refs.addButtonRef.current, undefined)}
              icon={IconName.Plus}
              ref={refs.addButtonRef}
            />
          )}
        </div>
        <PropertyDropdown
          {...searchSelectDropdown}
          {...dropdownPopper}
          data-testid="property-dropdown"
          childrenAboveResults={
            <Input {...getInputProps({ ref: propertyInputRef, placeholder: formatMessage(messages.filterOptions) })} />
          }
          onTabOut={handlePropertyDropdownTabOut}
        />
      </FilterBuilderContent>
      {showCopyPaste && (
        <CopyPasteFilter
          currentFilterClauses={filterClauses}
          onSubmitCopiedFilterClauses={handleSubmitCopiedFilterClauses}
        />
      )}
    </Flex>
  );
};
