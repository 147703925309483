import type { WritableDraft } from 'immer';
import { BaseField } from '../../../../fields/BaseField';
import { DateField } from '../../../../fields/DateField';
import { Field } from '../../../../fields/Field';
import { NumericField, Unit, getScaleFromIncrement } from '../../../../fields/NumericField';
import { SelectorField } from '../../../../fields/SelectorField';

import { intlDefaultFormatter, type IntlWithFormatter } from '../../../../contexts/IntlContext';
import {
  DecisionStatusEnum,
  OrderFormSides,
  ParameterTypeEnum,
  PresenceEnum,
  SideEnum,
  type CustomerOrder,
  type ExecutionReport,
  type ParameterLike,
  type Security,
  type StrategyLike,
  type WLOrderStrategy,
  type WLOrderStrategyParameter,
} from '../../../../types';
import { isCFD, toBigWithDefault } from '../../../../utils';
import type { OMSReferenceDataState } from '../OMSReferenceDataSlice/types';
import { resolveDeprecatedStrategy } from './deprecatedStrategy';
import type { CleanStatePayload, OrderFormDependencies, OrderFormState, OrderState } from './types';
import { endTimeValidation, notInPastValidation, validateQuantity } from './validation';

export const INITIAL_REFERENCEDATA_STATE: OMSReferenceDataState = {
  securities: {
    securitiesList: [],
    securitiesBySymbol: new Map(),
  },
  marketAccounts: {
    customerMarketAccountsList: [],
    customerMarketAccountsBySourceAccountID: new Map(),
  },
  strategiesBySymbol: new Map(),
};

export const initialDependencies: OrderFormDependencies = {
  tradingLimitsValidation: { reject: false, warn: false },
  balances: [],
  ccConversionRate: undefined,
};

export const getInitialState = (
  initialSymbolParams?: {
    symbol: string | undefined;
    securities: OMSReferenceDataState['securities'];
    strategiesBySymbol: OMSReferenceDataState['strategiesBySymbol'];
    marketAccounts: OMSReferenceDataState['marketAccounts'];
  },
  intl: IntlWithFormatter = intlDefaultFormatter
): OrderState => {
  let initialSecurity: Security | undefined;
  if (initialSymbolParams) {
    const initialSymbol = initialSymbolParams?.symbol;
    initialSecurity = initialSymbol ? initialSymbolParams.securities.securitiesBySymbol.get(initialSymbol) : undefined;
  }

  const initialMarketAccountsReferenceData =
    initialSymbolParams?.marketAccounts ?? INITIAL_REFERENCEDATA_STATE.marketAccounts;
  const initialSecuritiesReferenceData = initialSymbolParams?.securities ?? INITIAL_REFERENCEDATA_STATE.securities;
  const initialStrategiesReferenceData =
    initialSymbolParams?.strategiesBySymbol ?? INITIAL_REFERENCEDATA_STATE.strategiesBySymbol;

  const availableStrategies = initialSecurity?.Symbol
    ? initialStrategiesReferenceData.get(initialSecurity?.Symbol)
    : [];
  const initialStrategy = availableStrategies?.length === 1 ? availableStrategies.at(0) : undefined;

  return {
    intl,
    dependencies: initialDependencies,
    referenceData: {
      ...INITIAL_REFERENCEDATA_STATE,
      marketAccounts: initialMarketAccountsReferenceData,
      securities: initialSecuritiesReferenceData,
      strategiesBySymbol: initialStrategiesReferenceData,
    },
    form: {
      symbolField: new SelectorField({
        idProperty: 'Symbol',
        name: 'Symbol',
        value: initialSecurity,
        availableItems: initialSymbolParams?.securities.securitiesList,
      }),
      sideField: new Field({ value: OrderFormSides.Buy }),
      strategyField: new SelectorField({
        idProperty: 'Name',
        name: 'Strategy',
        availableItems: availableStrategies,
        value: initialStrategy,
      }),
      marketAccountField: new SelectorField({
        idProperty: 'SourceAccountID',
        name: 'Market Account',
        availableItems: initialMarketAccountsReferenceData.customerMarketAccountsList,
        value:
          initialMarketAccountsReferenceData.customerMarketAccountsList.length === 1
            ? initialMarketAccountsReferenceData.customerMarketAccountsList[0]
            : undefined,
        isVisible: initialMarketAccountsReferenceData.customerMarketAccountsList.length > 1,
      }),
      quantityField: new NumericField({ name: 'Quantity', scale: undefined, displayTrailingZeroes: true }), // quantity validation fully managed by FieldRules.quantityValidation
      orderCurrencyField: new SelectorField({
        availableItems: initialSecurity ? [initialSecurity.BaseCurrency, initialSecurity.QuoteCurrency] : [],
        value: initialSecurity?.BaseCurrency,
        name: 'Order Currency',
      }),
      viewType: new SelectorField<'order' | 'rfq'>({
        availableItems: ['order', 'rfq'],
        value: 'order',
        name: 'View Type',
      }),
      parameters: initializeStrategyParams({
        security: initialSecurity,
        strategy: initialStrategy,
      }),
    },
    initialized: false,
    orderBeingModified: undefined,
    orderStep: 'idle',
    focusedOrderID: null,
    error: null,
    isOpen: false,
    quoteReqID: null,
    quote: null,
  };
};

export const getSupportedStrategiesOfSymbol = (state: WritableDraft<OrderState>, symbol: string): WLOrderStrategy[] => {
  const strategies = state.referenceData.strategiesBySymbol.get(symbol);
  return strategies ?? [];
};

export const handleSymbolChange = (state: WritableDraft<OrderState>, symbol?: string) => {
  const security = state.form.symbolField.availableItems.find(s => s.Symbol === symbol);
  if (!security) {
    return;
  }

  setTouchedValueForAllOrderFields(state, false);

  state.form.symbolField = state.form.symbolField.updateValue(security);
  const supportedStrategies = getSupportedStrategiesOfSymbol(state, security.Symbol);
  state.form.strategyField = state.form.strategyField.updateAvailableItems(supportedStrategies, {
    preventDefaultToValue: true,
  });
  if (state.form.strategyField.availableItems.length === 1) {
    state.form.strategyField = state.form.strategyField.updateValue(
      state.form.strategyField.availableItems.at(0),
      true
    );
    handleStrategyChange(state, state.form.strategyField.value);
  }

  state.form.quantityField = getInitialState().form.quantityField;

  // We always want to remove the marketAccount when symbol changes.
  handleCleanMarketAccount(state);

  const availableCurrencies = [security.BaseCurrency, security.QuoteCurrency];
  state.form.orderCurrencyField = state.form.orderCurrencyField
    .updateAvailableItems(availableCurrencies)
    .updateValue(availableCurrencies.at(0));

  state.form.parameters = initializeStrategyParams({
    security: state.form.symbolField.value,
    strategy: state.form.strategyField.value,
  });
};

export const handleStrategyChange = (state: WritableDraft<OrderState>, strategy: WLOrderStrategy | undefined) => {
  state.form.strategyField = state.form.strategyField.updateValue(strategy);

  const newParameters = initializeStrategyParams({
    security: state.form.symbolField.value,
    strategy,
  });

  // When switching strategies we carry over the previous parameter values if it also exists in the new strategy
  Object.keys(newParameters).forEach(paramName => {
    if (state.form.parameters[paramName]) {
      // Different strategies might have same parameter with same Name but different DisplayName
      // So only safe to carry over the value, not the entire Field object
      newParameters[paramName] = newParameters[paramName].updateValue(state.form.parameters[paramName].value, true);
    }
  });

  state.form.parameters = newParameters;
};

export const mapParameterToField = (parameter: ParameterLike, priceScale: number, quantityScale: number) => {
  const isRequired = parameter.Presence === PresenceEnum.Required;
  const placeholder = parameter.Presence === PresenceEnum.Optional ? 'Optional' : '';
  const isVisible = parameter.Presence !== PresenceEnum.Hidden;
  const defaultValue = parameter.DefaultValue;
  const name = parameter.DisplayName;

  switch (parameter.Type) {
    case ParameterTypeEnum.TargetParticipationRate:
    case ParameterTypeEnum.Percent:
      return new NumericField({
        unit: Unit.Percent,
        placeholder,
        name,
        isRequired,
        value: defaultValue,
        isVisible,
        displayTrailingZeroes: true,
      }).validate();
    case ParameterTypeEnum.Bps:
      return new NumericField({
        unit: Unit.Bps,
        placeholder,
        name,
        scale: 2,
        isRequired,
        value: defaultValue,
        isVisible,
        displayTrailingZeroes: true,
      }).validate();
    case ParameterTypeEnum.Qty:
      return new NumericField({
        placeholder,
        name,
        scale: quantityScale,
        isRequired,
        value: defaultValue,
        isVisible,
        displayTrailingZeroes: true,
      }).validate();
    case ParameterTypeEnum.Price:
    case ParameterTypeEnum.PriceOffset:
      return new NumericField({
        name,
        placeholder,
        scale: priceScale,
        isRequired,
        value: defaultValue,
        isVisible,
        displayTrailingZeroes: true,
      }).validate();
    case ParameterTypeEnum.Date:
      return new DateField({ name, placeholder, isRequired, value: defaultValue, isVisible }).validate();
    case ParameterTypeEnum.Enum:
      return new Field<number>({ name, placeholder, isRequired, value: Number(defaultValue), isVisible }).validate();
    default:
      return new Field({ name, placeholder, isRequired, value: defaultValue, isVisible }).validate();
  }
};

/**
 Unlike resetState which is called when we close the order form and reset every state property back to default,
 clean only resets part of the state. e.g. needed for when we go from modifying an order and midway deciding to
 resubmitting another - so we still need to keep certain state (settings/refData) but clean enough to
 transition into another order workflow essentially.
 Since priming does not guarantee full payload, i.e. sometimes payload includes strategy and sometimes it does not
 which means we need to keep hold of existing value; in the event the next event is of being modify/resubmit
 then the allocations/subAccount/strategies etc will definitely exist and overwrite it; otherwise we keep current selection
 */
export const cleanFormState = (
  state: WritableDraft<OrderState>,
  payload: CleanStatePayload = { keepSymbol: true, keepStrategy: false }
) => {
  const formCopy = state.form;
  const viewType = formCopy.viewType.value;

  state.form = getInitialState({ symbol: undefined, ...state.referenceData }).form;
  const newSide = viewType === 'rfq' ? OrderFormSides.Twoway : OrderFormSides.Buy;
  state.form.sideField = formCopy.sideField.updateValue(newSide).setTouched(false).setDisabled(false);

  if (payload?.keepSymbol) {
    state.form.symbolField = formCopy.symbolField.setTouched(false).setDisabled(false);
  }
  if (payload?.keepStrategy) {
    state.form.strategyField = formCopy.strategyField.setTouched(false).setDisabled(false);
    state.form.parameters = initializeStrategyParams({
      security: state.form.symbolField.value,
      strategy: state.form.strategyField.value,
    });
  }
  state.form.viewType = state.form.viewType.updateValue(viewType);

  populateDropdownsFromRefData(state);
  state.focusedOrderID = state.focusedOrderID ?? state.orderBeingModified?.OrderID ?? null;
  state.orderBeingModified = undefined;
  state.orderStep = 'idle';
};

export const initializeFieldsFromOrder = (
  state: WritableDraft<OrderState>,
  order: ExecutionReport | CustomerOrder,
  isModify = false
) => {
  state.orderStep = 'idle';
  state.error = null;
  state.focusedOrderID = null;
  state.form.symbolField = state.form.symbolField.updateValueFromID(order.Symbol).setDisabled(isModify);

  handleSymbolChange(state, order.Symbol);

  state.form.sideField = state.form.sideField
    .setDisabled(isModify)
    .updateValue(order.Side === SideEnum.Buy ? OrderFormSides.Buy : OrderFormSides.Sell);

  state.form.quantityField = state.form.quantityField.updateValue(
    order.OrderQty && toBigWithDefault(order.OrderQty, 0).toFixed()
  );
  state.form.orderCurrencyField = state.form.orderCurrencyField.updateValue(order.Currency).setDisabled(isModify);

  state.form.strategyField = state.form.strategyField.setDisabled(isModify).updateValueFromID(order.Strategy);

  if (!state.form.strategyField.value && order.Strategy) {
    // we need to resolve deprecated strategies no longer available in the system
    const newStrat = resolveDeprecatedStrategy(order.Strategy);
    if (newStrat) {
      state.form.strategyField = state.form.strategyField.updateAvailableItems([
        ...state.form.strategyField.availableItems,
        newStrat,
      ]);
      state.form.strategyField = state.form.strategyField.updateValue(newStrat, true);
    } else {
      console.error('failed to update strategyField with', order.Strategy);
    }
  }

  if ('MarketAccount' in order) {
    state.form.marketAccountField = state.form.marketAccountField
      .setDisabled(isModify)
      .updateValueFromID(order.MarketAccount);
  }

  state.form.parameters = initializeStrategyParams({
    security: state.form.symbolField.value,
    strategy: state.form.strategyField.value,
  });
  const security = state.form.symbolField.value;

  // Populate the parameters
  Object.keys(state.form.parameters).forEach(key => {
    state.form.parameters[key] = state.form.parameters[key].updateValue(order.Parameters?.[key], true);
  });

  // If modifying and no strategy available and price exists, put it on there.
  if (isModify && !order.Strategy && order.Price) {
    state.form.parameters['LimitPrice'] = mapParameterToField(
      {
        Name: 'LimitPrice',
        DisplayName: 'Limit Price',
        Type: ParameterTypeEnum.Price,
        Presence: PresenceEnum.Required,
        Description: '',
      },
      getScaleFromIncrement(security?.MinPriceIncrement),
      getScaleFromIncrement(security?.MinSizeIncrement)
    );
  }
  // If possible, prepopulate the limit price to order.Price (as opposed to Parameter.LimitPrice)
  if (state.form.parameters['LimitPrice']) {
    state.form.parameters['LimitPrice'] = state.form.parameters['LimitPrice'].updateValue(order.Price);
  }

  if (isModify && state.form.parameters.StartTime) {
    // Allow modifying start time if WaitingForStartTime || Staged
    const allowEdit = [DecisionStatusEnum.WaitingForStartTime, DecisionStatusEnum.Staged].includes(
      order.DecisionStatus
    );
    state.form.parameters.StartTime = state.form.parameters.StartTime.setDisabled(!allowEdit);
  }
};

export const validateForm = (state: WritableDraft<OrderState>) => {
  validateQuantity(state);
  validateStrategyParams(state);
};

export const validateStrategyParams = (state: WritableDraft<OrderState>) => {
  for (const [parameterKey, parameterField] of Array.from(Object.entries(state.form.parameters))) {
    state.form.parameters[parameterKey] = parameterField.validate([], state);
  }

  const shouldValidateEndTimeRelativeToStartTime =
    state.form.parameters.EndTime?.hasValue && state.form.parameters.StartTime?.hasValue;

  const validateEndTimeRelativeToStartTime = shouldValidateEndTimeRelativeToStartTime ? endTimeValidation : () => null;

  if (state.form.parameters.StartTime) {
    state.form.parameters.StartTime = state.form.parameters.StartTime.validate(
      [notInPastValidation, validateEndTimeRelativeToStartTime],
      state
    );
  }
  if (state.form.parameters.EndTime) {
    state.form.parameters.EndTime = state.form.parameters.EndTime.validate(
      [notInPastValidation, validateEndTimeRelativeToStartTime],
      state
    );
  }
};

export const setTouchedValueForAllOrderFields = (state: WritableDraft<OrderState>, touched: boolean) => {
  Object.keys(state.form).forEach(key => {
    if (state.form[key] instanceof BaseField) {
      state.form[key] = state.form[key].setTouched(touched);
    }
  });

  Object.keys(state.form.parameters).forEach(param => {
    state.form.parameters[param] = state.form.parameters[param].setTouched(touched);
  });
};

export const populateDropdownsFromRefData = (state: WritableDraft<OrderState>) => {
  const { securities, marketAccounts } = state.referenceData;

  state.form.symbolField = state.form.symbolField.updateAvailableItems(securities.securitiesList);
  state.form.marketAccountField = state.form.marketAccountField.updateAvailableItems(
    marketAccounts.customerMarketAccountsList
  );

  if (state.form.symbolField.value) {
    const supportedStrategies = getSupportedStrategiesOfSymbol(state, state.form.symbolField.value.Symbol);
    state.form.strategyField = state.form.strategyField.updateAvailableItems(supportedStrategies);
    state.form.parameters = initializeStrategyParams({
      security: state.form.symbolField.value,
      strategy: state.form.strategyField.value,
    });

    state.form.orderCurrencyField = state.form.orderCurrencyField
      .updateAvailableItems([state.form.symbolField.value.BaseCurrency, state.form.symbolField.value.QuoteCurrency])
      .updateValue(state.form.symbolField.value.BaseCurrency);
  }
  handleCleanMarketAccount(state);
};

export const initializeStrategyParams = ({
  security,
  strategy,
}: {
  security?: Security;
  strategy?: StrategyLike | WLOrderStrategy;
}): OrderFormState['parameters'] => {
  const params = {};

  if (strategy) {
    strategy.Parameters.forEach(param => {
      const parameterLike: WLOrderStrategyParameter = { ...param };
      params[parameterLike.Name] = mapParameterToField(
        parameterLike,
        getScaleFromIncrement(security?.MinPriceIncrement),
        getScaleFromIncrement(security?.MinSizeIncrement)
      );
    });
  }

  return params;
};

export const setFieldsDisabled = (state: WritableDraft<OrderState>, disabled: boolean) => {
  Object.keys(state.form).forEach(key => {
    if (state.form[key] instanceof BaseField) {
      state.form[key] = state.form[key].setDisabled(disabled);
    }
  });

  Object.keys(state.form.parameters).forEach(param => {
    state.form.parameters[param] = state.form.parameters[param].setDisabled(disabled);
  });
};

export const handleViewTypeChange = (state: WritableDraft<OrderState>, viewType: 'order' | 'rfq') => {
  const oldViewType = state.form.viewType.value;
  state.form.viewType = state.form.viewType.updateValue(viewType);
  state.isOpen = true;

  // handle -> rfq
  if (viewType === 'rfq') {
    state.form.strategyField = state.form.strategyField.setIsVisible(false);
    // set all parameters to not visible
    Object.keys(state.form.parameters).forEach(key => {
      state.form.parameters[key] = state.form.parameters[key].setIsVisible(false);
    });
    // when going from rfq -> rfq, do not change side
    if (oldViewType !== 'rfq') {
      state.form.sideField = state.form.sideField.updateValue(OrderFormSides.Twoway);
    }
  }
  // handle -> order
  else if (viewType === 'order') {
    state.form.strategyField = state.form.strategyField.setIsVisible(true);

    // if going from rfq to order, we need to clean side field if it is twoway
    const currentSide = state.form.sideField.value;
    if (currentSide === OrderFormSides.Twoway) {
      state.form.sideField = state.form.sideField.updateValue(OrderFormSides.Buy);
    }

    // set all parameters to visible
    Object.keys(state.form.parameters).forEach(key => {
      state.form.parameters[key] = state.form.parameters[key].setIsVisible(true);
    });
  }
};

// Clean the market account, if there is only one, set it as the value, otherwise set it as undefined.
export const handleCleanMarketAccount = (state: WritableDraft<OrderState>) => {
  if (isCFD(state.form.symbolField.value)) {
    // [DEAL-3949] If the selected symbol is a CFD, limit the market account selector to only those with a CFDFundingSchedule
    state.form.marketAccountField = state.form.marketAccountField.updateAvailableItems(
      state.referenceData.marketAccounts.customerMarketAccountsList.filter(
        item => item.SourceData?.CFDFundingSchedule != null
      )
    );
    return;
  } else {
    // [DEAL-3949] Otherwise, limit the market account selector to only those without a CFDFundingSchedule
    state.form.marketAccountField = state.form.marketAccountField.updateAvailableItems(
      state.referenceData.marketAccounts.customerMarketAccountsList.filter(
        item => item.SourceData?.CFDFundingSchedule == null
      )
    );
  }

  if (state.form.marketAccountField.availableItems.length === 1) {
    state.form.marketAccountField = state.form.marketAccountField
      .updateValue(state.form.marketAccountField.availableItems[0])
      .setTouched(false);
    return;
  }
  state.form.marketAccountField = state.form.marketAccountField.updateValue(undefined).setTouched(false);
};
