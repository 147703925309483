import {
  Alert,
  AlertVariants,
  Box,
  Dialog,
  Divider,
  FilledMeter,
  Flex,
  FormattedMessage,
  MixpanelEvent,
  MixpanelEventProperty,
  MixpanelEventSource,
  OrdStatusEnum,
  OrderCardHeader,
  OrderCardSummary,
  OrderCardWrapper,
  Spinner,
  setFocusedOrderID,
  useDisclosure,
  useDynamicCallback,
  useIntl,
  useMixpanel,
  useOrderServiceContext,
  useSecuritiesContext,
  useWLOrder,
  useWLOrderFormDispatch,
  type UseDisclosureReturn,
} from '@talos/kyoko';
import React, { createContext, useMemo, useReducer, useRef, type PropsWithChildren } from 'react';
import { defineMessages } from 'react-intl';

interface CancelOrderDialogProps extends UseDisclosureReturn {
  dialogState: GlobalCancelDialogState;
  dispatch: React.Dispatch<GlobalCancelDialogAction>;
}

type GlobalCancelDialogContextProps = {
  open: (orderID: string, cancellationAttempted?: boolean) => void;
};

const messages = defineMessages({
  cancelOrder: {
    id: 'providers.cancelOrder',
    defaultMessage: 'Cancel Order',
  },
  cancelOrderIdle: {
    id: 'providers.cancelOrderIdle',
    defaultMessage: "You are about to cancel this order, this action can't be undone.",
  },
  cancelingOrder: {
    id: 'providers.cancelingOrder',
    defaultMessage: 'Canceling order.',
  },
  cancelOrderSuccess: {
    id: 'providers.cancelOrderSuccess',
    defaultMessage: 'Successfully canceled order.',
  },
  cancelOrderFailed: {
    id: 'providers.cancelOrderFailed',
    defaultMessage: 'We have not received a response from the server. Please try again.',
  },
  tryAgain: {
    id: 'providers.tryAgain',
    defaultMessage: 'Try Again',
  },
  return: {
    id: 'providers.return',
    defaultMessage: 'Return',
  },
});

const GlobalCancelDialogContext = createContext<GlobalCancelDialogContextProps | null>(null);

export function GlobalCancelDialogProvider({ children }: PropsWithChildren) {
  const mixpanel = useMixpanel();
  const [dialogState, dispatch] = useReducer(dialogReducer, {
    orderID: '',
    state: 'idle',
  });

  const closeDialogTimeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const onClose = useDynamicCallback(() => {
    // We put a timeout here, so that the modal closes with a "snapshot" of the current state
    closeDialogTimeoutRef.current = setTimeout(() => {
      dispatch({ type: 'reset', orderID: undefined });
    }, 200);
  });
  const cancelDialog = useDisclosure({ onClose });
  const open: GlobalCancelDialogContextProps['open'] = useDynamicCallback((orderID, cancellationAttempted) => {
    clearTimeout(closeDialogTimeoutRef.current);
    cancelDialog.open();
    if (cancellationAttempted) {
      dispatch({ type: 'CANCEL_ORDER_FAILED', orderID: orderID });
      mixpanel.track(MixpanelEvent.ShowFailedCancelOrderModal, {
        [MixpanelEventProperty.Source]: MixpanelEventSource.CancelOrderDialog,
      });
      return;
    }
    dispatch({ type: 'reset', orderID });
  });

  return (
    <GlobalCancelDialogContext.Provider value={{ open }}>
      <GlobalCancelDialog {...cancelDialog} dialogState={dialogState} dispatch={dispatch} />
      {children}
    </GlobalCancelDialogContext.Provider>
  );
}

function GlobalCancelDialog({ dialogState, dispatch, ...props }: CancelOrderDialogProps) {
  const { securitiesBySymbol } = useSecuritiesContext();
  const { orderService } = useOrderServiceContext();
  const orderFormDispatch = useWLOrderFormDispatch();
  const mixpanel = useMixpanel(MixpanelEventSource.CancelOrderDialog);
  const { formatMessage } = useIntl();

  const order = useWLOrder({ orderID: dialogState.orderID });
  const orderSecurity = order?.Symbol ? securitiesBySymbol.get(order.Symbol) : undefined;

  const orderIsCanceled = order && order.OrdStatus === OrdStatusEnum.Canceled;

  const handleConfirm = useDynamicCallback(() => {
    if (orderIsCanceled) {
      props.close();
      return;
    }

    if (!dialogState.orderID) {
      return;
    }

    dispatch({ type: 'CANCEL_ORDER', orderID: dialogState.orderID });
    mixpanel.track(MixpanelEvent.CancelOrder, {
      [MixpanelEventProperty.ID]: dialogState.orderID,
      [MixpanelEventProperty.Source]: MixpanelEventSource.CancelOrderDialog,
    });

    orderService
      .cancelOrder(dialogState.orderID, true)
      .then(execReport => {
        console.info('execReport', execReport);
        dispatch({ type: 'CANCEL_ORDER_SUCCESS', orderID: execReport.OrderID });
        orderFormDispatch(setFocusedOrderID(execReport.OrderID));
      })
      .catch(() => {
        console.info('err');
        dispatch({ type: 'CANCEL_ORDER_FAILED' });
        // mixpanel track order cancel modal show
        mixpanel.track(MixpanelEvent.ShowFailedCancelOrderModal, {
          [MixpanelEventProperty.Source]: MixpanelEventSource.CancelOrderDialog,
        });
      });
  });

  const alert = useMemo(() => {
    switch (dialogState.state) {
      case 'idle':
        return (
          <Alert w="100%" dismissable={false} variant={AlertVariants.Warning}>
            <FormattedMessage {...messages.cancelOrderIdle} />
          </Alert>
        );
      case 'pending':
        return (
          <Alert w="100%" dismissable={false}>
            <FormattedMessage {...messages.cancelingOrder} />
          </Alert>
        );
      case 'success':
        return (
          <Alert w="100%" dismissable={false} variant={AlertVariants.Positive}>
            <FormattedMessage {...messages.cancelOrderSuccess} />
          </Alert>
        );
      case 'failed':
        return (
          <Alert w="100%" dismissable={false} variant={AlertVariants.Negative}>
            <FormattedMessage {...messages.cancelOrderFailed} />
          </Alert>
        );
    }
  }, [dialogState.state]);

  return (
    <Dialog
      dataTestId="global-cancel-dialog"
      title="Cancel Order"
      confirmLabel={
        dialogState.state === 'failed' ? formatMessage(messages.tryAgain) : formatMessage(messages.cancelOrder)
      }
      cancelLabel={formatMessage(messages.return)}
      onConfirm={handleConfirm}
      preventClosing={dialogState.state === 'pending'}
      confirmLoading={dialogState.state === 'pending'}
      showConfirm={dialogState.state !== 'success'}
      closeOnConfirm={false}
      showClose
      width={400}
      minHeight={370}
      {...props}
    >
      {!order || !orderSecurity ? (
        <Spinner />
      ) : (
        <Flex flexDirection="column" gap="spacingMedium">
          <OrderCardWrapper w="100%">
            <Box p="spacingDefault">
              <OrderCardHeader
                cumQty={order.CumQty}
                decisionStatus={order.DecisionStatus}
                ordStatus={order.OrdStatus}
                orderID={order.OrderID}
                orderQty={order.OrderQty}
                security={orderSecurity}
                pricingMode={order.PricingMode}
                startTime={order.Parameters?.StartTime}
                endTime={order.Parameters?.EndTime}
                strategy={order.Strategy}
                text={order.Text}
                submitTime={order.SubmitTime}
              />
              <Divider />
              <OrderCardSummary
                OrdStatus={order.OrdStatus}
                Side={order.Side}
                security={orderSecurity}
                showFees={false}
                AvgPx={order.AvgPx}
                CumQty={order.CumQty}
                Price={order.Price}
                Currency={order.Currency}
                OrderQty={order.OrderQty}
                tableDetails={[{ id: 'account', label: 'Account', value: order.MarketAccount }]}
              />

              <FilledMeter entity={order} showLabel={true} showInitialAnimation={false} />
            </Box>
          </OrderCardWrapper>

          {alert}
        </Flex>
      )}
    </Dialog>
  );
}

export function useGlobalCancelDialog() {
  const context = React.useContext(GlobalCancelDialogContext);
  if (!context) {
    throw new Error('useGlobalCancelDialog must be used within a GlobalCancelDialogProvider');
  }
  return context;
}

type GlobalCancelDialogState = {
  orderID: string | undefined;
  state: 'idle' | 'pending' | 'success' | 'failed';
};

type GlobalCancelDialogAction =
  | {
      type: 'CANCEL_ORDER';
      orderID: string;
    }
  | {
      type: 'reset';
      orderID: string | undefined;
    }
  | {
      type: 'CANCEL_ORDER_SUCCESS';
      orderID: string;
    }
  | {
      type: 'CANCEL_ORDER_FAILED';
      orderID?: string;
    };

const dialogReducer = (state: GlobalCancelDialogState, action: GlobalCancelDialogAction): GlobalCancelDialogState => {
  switch (action.type) {
    case 'CANCEL_ORDER': {
      return { ...state, state: 'pending', orderID: action.orderID };
    }
    case 'CANCEL_ORDER_FAILED': {
      return { ...state, state: 'failed', orderID: action.orderID ?? state.orderID };
    }
    case 'CANCEL_ORDER_SUCCESS': {
      return { ...state, state: 'success', orderID: action.orderID };
    }
    case 'reset': {
      return { ...state, state: 'idle', orderID: action.orderID };
    }
  }
};
