import { createContext, memo, useContext, useEffect, useState, type ReactNode } from 'react';
import { useAuthContext, useEndpointsContext } from '../contexts';
import { WebSocketStatus } from '../tokens/socket';
import { WebSocketClient, WebSocketPerformance, type IWebSocketClient } from './WebSocketClient';

export interface WebSocketContextProps<TMessageType> {
  client?: IWebSocketClient<TMessageType> | null;
  status: WebSocketStatus;
  performance?: any;
}
export const WebSocketContext = createContext<WebSocketContextProps<unknown>>({
  status: WebSocketStatus.CONNECTING,
});
WebSocketContext.displayName = 'WebSocketContext';

export function useSocketClient<TMessageType = unknown>() {
  const { client } = useContext(WebSocketContext);
  if (client === undefined) {
    throw new Error('Missing WebSocketClient.Provider further up in the tree. Did you forget to add it?');
  }
  return client as IWebSocketClient<TMessageType>;
}

export function useSocketStatus() {
  const { status } = useContext(WebSocketContext);
  return status;
}

type WebSocketClientProviderProps = {
  wsEndpoint?: string;
  pingPongTypes?: WebSocketClient['pingPongTypes'];
  children: ReactNode;
  wsClient?: WebSocketClient;
};

export const WebSocketClientProvider = memo(function WebSocketClientProvider(props: WebSocketClientProviderProps) {
  const authContext = useAuthContext();
  const { wsEndpoint } = useEndpointsContext();
  const [client, setClient] = useState<WebSocketClient | null>(null);
  const [status, setStatus] = useState(WebSocketStatus.CONNECTING);
  const [socketUrl, setSocketUrl] = useState<string>();
  const performance = useState<WebSocketPerformance | null>(null);

  useEffect(() => {
    if (authContext.isAuthenticated) {
      const urlWithVersion = `${wsEndpoint}/${import.meta.env.VITE_GIT_HASH}`;
      setSocketUrl(urlWithVersion);
    }
  }, [authContext, wsEndpoint]);

  const isAuthenticated = authContext?.isAuthenticated;
  useEffect(() => {
    if (socketUrl && isAuthenticated) {
      const performance = new WebSocketPerformance();
      const client =
        props.wsClient ??
        new WebSocketClient({
          pingPongTypes: props.pingPongTypes,
        });
      client.performance = performance;
      const onOpen = client.onOpen.subscribe(() => {
        setStatus(WebSocketStatus.CONNECTED);
      });
      const onClose = client.onClose.subscribe(e => {
        setStatus(WebSocketStatus.RECONNECTING);
      });
      client.connect(socketUrl);
      performance.start();
      setClient(client);
      return () => {
        performance.stop();
        client.close();
        onOpen.unsubscribe();
        onClose.unsubscribe();
        setClient(null);
      };
    } else {
      setClient(null);
    }
  }, [isAuthenticated, socketUrl, props.pingPongTypes, props.wsClient]);

  return (
    <WebSocketContext.Provider value={{ client, status, performance }}>{props.children}</WebSocketContext.Provider>
  );
});
