export enum FormRowStatus {
  Added = 'Added',
  Updated = 'Updated',
  Removed = 'Removed',
  None = 'None',
}

export type FormRowErrors = { [key: string]: string };

export interface FormRow<R> {
  readonly data: R;
  readonly status: FormRowStatus;
  readonly errors?: { [key: string]: string };
  setData(data: R): void;
  setErrors(errors?: { [key: string]: string }): void;
  remove(force?: boolean): void;
}

/** Blotter Data Item created/managed by useFormTable */
export type FormRowGridItem<R> = R & {
  formRow: {
    id?: string;
    status: FormRow<any>['status'];
    errors?: FormRowErrors;
  };
};
