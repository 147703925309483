import type { ICellRendererParams } from 'ag-grid-enterprise';
import type { ReactNode } from 'react';
import { toBig } from '../../utils';
import type { FilledPercentColumnValue } from '../BlotterTable';
import { Meter, type MeterProps, type MeterWithValueProps } from '../Meter';
import { Tooltip } from '../Tooltip';

export type AgGridMeterProps = ICellRendererParams<unknown, FilledPercentColumnValue | string | number> &
  Omit<MeterProps, 'value'> & {
    alignBar?: 'left' | 'right';
    /** @deprecated
     * Super hacky way to override the valueGetter. Has one usage in Marketplace. Remove once feasible. Do not use!
     */
    getMeterFillPercentage?: (params: ICellRendererParams) => string;
    /** @deprecated
     * A callback providing you with the MeterProps. Using this you can return a ReactNode which will be rendered in a tooltip
     *
     * Use AgGrid's own column tooltip functionality instead in the future. We do not want to couple our tooltips with the props to <Meter />.
     * Using AgGrid's own tooltips lets us build them based on the entity type (node.data), eg order, which leads to less coupled code.
     */
    tooltip?: (props: { meterProps: MeterWithValueProps } & AgGridMeterProps) => ReactNode | null;
  };

export function AgGridMeter(params: AgGridMeterProps) {
  const {
    showInitialAnimation,
    alignBar = 'left',
    showLabel,
    tooltip,
    getMeterFillPercentage,
    valueFormatted,
  } = params;

  const value = params.value ?? undefined;

  if (value == null) {
    return <span />;
  }

  const label = valueFormatted ?? undefined;

  let meterValue: number | undefined = undefined;
  let color: string | undefined = undefined;

  // This component accepts different kinds of "value" props. One is an object ({ value: BigSource, color: string }), and other is string | number
  // It shouldn't, but that's what it does.
  if (typeof value === 'object' && 'value' in value) {
    meterValue = toBig(value.value)?.toNumber();
    color = value.color;
  } else {
    meterValue = toBig(value)?.toNumber();
  }

  if (getMeterFillPercentage) {
    meterValue = toBig(getMeterFillPercentage(params))?.toNumber();
  }

  if (meterValue == null) {
    return <span />;
  }

  const meterProps: MeterWithValueProps = {
    value: meterValue,
    label,
    showLabel,
    showInitialAnimation,
    color,
    alignBar,
  };

  const maybeTooltip = tooltip?.({ meterProps, ...params });

  if (maybeTooltip) {
    return (
      <Tooltip usePortal tooltip={maybeTooltip} targetStyle={{ width: '100%' }}>
        <Meter {...meterProps} />
      </Tooltip>
    );
  }

  return <Meter {...meterProps} />;
}
