import type { GridApi } from 'ag-grid-community';
import type { BlotterTableSort } from '../types';
import { isGridApiReady } from '../utils';

export function getBlotterTableSort<TRowType = any>(api: GridApi<TRowType>): BlotterTableSort<TRowType> {
  if (!isGridApiReady(api)) {
    return [];
  }
  const sortString = api
    .getColumnState()
    .filter(column => column.sort != null)
    .sort((left, right) => (left.sortIndex ?? 0) - (right.sortIndex ?? 0))
    .map(columnState => `${columnState.sort === 'asc' ? '+' : '-'}${columnState.colId}` as const);

  // casting needed since getColumnState() is not typed, but it should align
  return sortString as BlotterTableSort<TRowType>;
}
