import { useMemo } from 'react';
import { getAgGridColId } from '../components/BlotterTable/columns/getAgGridColId';
import type { Column, ColumnDef } from '../components/BlotterTable/columns/types';
import { useDefaultColumns } from '../components/BlotterTable/useDefaultColumns';
import { EMPTY_ARRAY } from '../utils/empty';
import {
  TradeSourceEnum,
  type ExecTypeEnum,
  type IAllocation,
  type PricingModeEnum,
  type SideEnum,
  type TradeActionEnum,
  type TradeStatusEnum,
} from './types';

// OMSTradeCapture4211
export class Trade {
  static readonly rowID = 'TradeID';
  /** @deprecated Use containers/Blotters/Trades/index.tsx DEFAULT_TRADE_COLUMNS instead */
  static readonly defaultColumns: (keyof Trade)[] = [
    'TransactTime',
    'Side',
    'Symbol',
    'Quantity',
    'Price',
    'Fee',
    'PriceAllIn',
    'Amount',
    'SubAccount',
    'TradeID',
    'MarketAccount',
    'TradeStatus',
    'OrderID',
    'EID',
    'AggressorSide',
  ];

  TradeID!: string;

  OrderID!: string;

  Symbol!: string;

  Quantity!: string;

  Market!: any;

  Amount!: string;

  Fee?: string;

  User!: string;

  Timestamp!: string;

  DealtCurrency?: string;

  MarketTradeID?: string;

  TalosFee?: string;

  TalosFeeCurrency?: string;

  Price?: string;

  PriceAllIn?: string;

  AllocID?: string;

  MessageID?: string;

  CredentialID?: string;

  TransactTime!: string;

  ExecType?: ExecTypeEnum;

  Currency?: string;

  AmountCurrency?: string;

  FeeCurrency?: string;

  TradeStatus?: TradeStatusEnum;

  Side?: SideEnum;

  AggressorSide?: SideEnum;

  OID?: string;

  EID?: string;

  QuoteID?: string;

  RFQID?: string;

  Group?: string;

  SubAccount?: string;

  Counterparty?: string;

  Comments?: string;

  ParentTradeID?: string;

  TradeSource?: TradeSourceEnum;

  TradeAction?: TradeActionEnum;

  Revision?: string;

  UpdateUser?: string;

  MarketAccount!: string;

  MarketAccountID?: string;

  Allocation?: IAllocation;

  Attributes?: {
    TxHash?: string;
  };

  PricingReference?: string;

  PricingMode?: PricingModeEnum;

  FixingDetails?: {
    Index?: string;
    Fixing?: string;
  };

  get isManualBookedTrade() {
    return this.TradeSource === TradeSourceEnum.User;
  }

  get isOrphanTrade() {
    return this.OrderID == null;
  }

  /** True if the OrderID on the Trade is actually a CustomerOrderID */
  get isOrderIDCustomerOrderID() {
    return this.Market === 'dealer';
  }

  constructor(data: Trade) {
    Object.assign(this, data);
  }
}

interface UseTradeColumns {
  defaultColumns?: (keyof Trade | Partial<Column>)[];
}

export function useTradeColumns({ defaultColumns = EMPTY_ARRAY }: UseTradeColumns): Column[] {
  const defaultVisibleColumns = useMemo(
    () =>
      new Map(
        (
          [
            {
              field: 'TransactTime',
              type: 'date',
              width: 140,
              sortable: true,
              params: { milliseconds: true },
            },
            {
              field: 'Side',
              type: 'side',
              sortable: true,
            },
            {
              field: 'Symbol',
              type: 'security',
              sortable: true,
            },
            {
              field: 'Quantity',
              type: 'size',
              title: 'Filled Quantity',
              sortable: true,
              params: { currencyField: 'Currency' },
              width: 120,
            },
            {
              field: 'Price',
              type: 'price',
              title: 'Filled Price',
              sortable: true,
              params: { assetField: 'Symbol' },
              width: 120,
            },
            {
              field: 'Fee',
              type: 'size',
              sortable: true,
              params: { currencyField: 'FeeCurrency' },
              width: 80,
            },
            {
              field: 'PriceAllIn',
              type: 'price',
              title: 'Filled Price All-In',
              sortable: true,
              params: { assetField: 'Symbol' },
            },
            {
              field: 'Amount',
              type: 'size',
              title: 'Filled Counter Amt.',
              sortable: true,
              params: { currencyField: 'AmountCurrency' },
              width: 120,
            },
            {
              field: 'MarketAccount',
              type: 'marketAccount',
              sortable: true,
              width: 100,
            },
            {
              field: 'AggressorSide',
              type: 'side',
              sortable: true,
              minWidth: 150,
            },
            {
              field: 'SubAccount',
              type: 'subAccount',
              title: 'Sub Account(s)',
              sortable: true,
            },
            {
              field: 'TradeID',
              type: 'id',
              sortable: true,
            },
            {
              field: 'TradeStatus',
              type: 'tradeStatus',
              sortable: true,
            },
            {
              field: 'OrderID',
              type: 'id',
            },
            {
              field: 'User',
              type: 'user',
              sortable: true,
            },
          ] satisfies ColumnDef<Trade>[]
        ).map(c => [getAgGridColId(c), c])
      ),
    []
  );
  const defaultHiddenColumns = useMemo(() => {
    return new Map(
      (
        [
          {
            field: 'Market',
            type: 'market',
            sortable: true,
          },
          {
            field: 'EID',
            type: 'text',
          },
          {
            field: 'Timestamp',
            type: 'date',
            sortable: true,
            params: { milliseconds: true },
          },
          {
            field: 'DealtCurrency',
            type: 'text',
          },
          {
            field: 'MarketTradeID',
            type: 'text',
          },
          {
            field: 'TalosFee',
            type: 'size',
          },
          {
            field: 'TalosFeeCurrency',
            type: 'currency',
          },
          {
            field: 'AllocID',
            type: 'text',
          },
          {
            field: 'Currency',
            type: 'currency',
            sortable: true,
          },
          {
            field: 'AmountCurrency',
            type: 'currency',
          },
          {
            field: 'FeeCurrency',
            type: 'currency',
          },
          {
            field: 'OID',
            type: 'text',
          },
          {
            field: 'QuoteID',
            type: 'id',
          },
          {
            field: 'RFQID',
            type: 'id',
          },
          {
            field: 'Group',
            type: 'text',
            sortable: true,
          },
          {
            field: 'Counterparty',
            type: 'text',
            sortable: true,
          },
          {
            field: 'Comments',
            type: 'text',
            sortable: true,
          },
          {
            field: 'ParentTradeID',
            type: 'id',
          },
          {
            field: 'TradeSource',
            type: 'text',
          },
          {
            field: 'TradeAction',
            type: 'text',
          },
          {
            field: 'Revision',
            type: 'text',
          },
          {
            field: 'UpdateUser',
            type: 'user',
            sortable: true,
          },
          {
            field: 'MarketAccountID',
            type: 'text',
          },
          {
            field: 'Allocation',
            type: 'text',
          },
          { field: 'Attributes.TxHash', title: 'TxHash', type: 'text', hide: true },
          {
            field: 'PricingReference',
            type: 'price',
            hide: true,
            params: {
              isReferencePrice: true,
            },
          },
          {
            field: 'FixingDetails.Index',
            title: 'Fixing Index',
            type: 'text',
          },
          {
            field: 'FixingDetails.Fixing',
            title: 'Fixing Price',
            type: 'price',
            params: {
              // TODO fhqvst this should probably be `FixingDetails.Index`
              assetField: 'Symbol',
            },
          },
        ] satisfies (false | ColumnDef<Trade>)[]
      ).map(c => [getAgGridColId(c), { ...c, hide: true }])
    );
  }, []);
  const columnDefinitions = useMemo(() => {
    return new Map(
      (
        [...defaultVisibleColumns.values(), ...defaultHiddenColumns.values()] satisfies ColumnDef<Trade>[] as Column[]
      ).map(c => [getAgGridColId(c), c])
    );
  }, [defaultVisibleColumns, defaultHiddenColumns]);
  return useDefaultColumns(defaultColumns, columnDefinitions);
}
