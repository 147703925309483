import { CustomerDealSummary } from './CustomerOrderSummary';
import type {
  CustomerExecutionStrategyEnum,
  CxlRejReasonEnum,
  DecisionStatusEnum,
  ExecTypeEnum,
  ICustomerOrderSummary,
  IMarketViewRequest4146Markets,
  IStrategyParameters,
  OrdRejReasonEnum,
  OrdStatusEnum,
  OrdTypeEnum,
  PricingModeEnum,
  SideEnum,
  TimeInForceEnum,
} from './types';

export const isCustomerOrder = (entity: any): entity is CustomerOrder => {
  return entity instanceof CustomerOrder;
};

export interface PricingParameters {
  AcceptPriceLeniency: string;
  AcceptTimeLeniency: string;
  EnableSyntheticCounterCurrency: boolean;
  MinMarketDataThrottle: string;
  OrderPriceImprovementRatio: string;
  PriceImprovementRatio: string;
  PricesTimeout: string;
  QuoteTTL: string;
  RFQTTL: string;
  // Either Spread or OfferSpread/BidSpread exists as a Pricing Parameter
  Spread?: string;
  OfferSpread?: string;
  BidSpread?: string;
  PricingMarkets?: IMarketViewRequest4146Markets[];
}

export class CustomerOrder {
  static readonly rowID = 'OrderID';

  AggressorSide?: SideEnum;
  AmountCurrency!: string;
  AvgPx?: string;
  AvgPxAllIn?: string;
  ClOrdID!: string;
  Counterparty?: string;
  CumAmt!: string;

  CumQty!: string;
  /**
   * The currency of the quantity the order was placed with.
   * Note: a currency can be excluded from an order. For example if the order is placed in number of contracts.
   */
  Currency?: string;

  CustomerUser?: string;
  ExecID!: string;
  LeavesQty!: string;
  MarketAccount!: string;
  OrdStatus!: OrdStatusEnum;
  OrdType!: OrdTypeEnum;
  OrderID!: string;
  OrderQty!: string;
  Price?: string;
  PricingParameters?: PricingParameters;
  QuoteID?: string;
  RFQID?: string;
  Side!: SideEnum;
  SubmitTime!: string;
  Symbol!: string;
  TimeInForce?: TimeInForceEnum;
  Timestamp?: string;
  TransactTime?: string;
  User?: string;
  CumFee?: string;
  DecisionStatus!: DecisionStatusEnum;
  ExecType?: ExecTypeEnum;
  FeeCurrency?: string;
  Strategy?: string;
  Text?: string;
  StartTime?: string;
  EndTime?: string;
  OrdRejReason?: OrdRejReasonEnum;
  OrigClOrdID?: string;
  CxlRejReason?: CxlRejReasonEnum;
  Parameters?: Partial<IStrategyParameters>;
  summary?: CustomerDealSummary;
  SubAccount?: string;
  Comments?: string;
  Group?: string;
  ExpectedFillPrice?: string;
  ExpectedFillQty?: string;
  AdminFillPrice?: string;
  PricingMode?: PricingModeEnum;
  PricingReference?: string | undefined;

  FixingDetails?: {
    Index?: string;
    Fixing?: string;
  };

  TargetSpread?: ICustomerOrderSummary['TargetSpread'];
  ActualSpread?: ICustomerOrderSummary['ActualSpread'];
  PerCurrencyPnL?: ICustomerOrderSummary['PerCurrencyPnL'];
  PerCurrencyFees?: ICustomerOrderSummary['PerCurrencyFees'];
  SalesCommission?: ICustomerOrderSummary['SalesCommission'];
  ExecutionStrategy?: CustomerExecutionStrategyEnum;

  /**
   * Monitoring blotter displays CustomerOrder objects originating from CUSTOMER_ORDERS subscription, however
   * we would like the rows to contain customer summary specific properties which are extracted from CUSTOMER_ORDER_SUMMARY subscription
   */
  public enrichOrderWithCustomerSummary?(customerOrderSummary?: ICustomerOrderSummary): void {
    if (!customerOrderSummary) {
      return;
    }
    this.TargetSpread = customerOrderSummary.TargetSpread;
    this.ActualSpread = customerOrderSummary.ActualSpread;
    this.PerCurrencyPnL = customerOrderSummary.PerCurrencyPnL;
    this.PerCurrencyFees = customerOrderSummary.PerCurrencyFees;
    this.SalesCommission = customerOrderSummary.SalesCommission;
    this.summary = new CustomerDealSummary({
      Fees: customerOrderSummary.PerCurrencyFees,
      FilledSpread: customerOrderSummary.ActualSpread,
      PnL: customerOrderSummary.PerCurrencyPnL,
      SalesCommission: customerOrderSummary.SalesCommission,
      Spread: customerOrderSummary.TargetSpread,
    });
  }

  constructor(data: CustomerOrder) {
    Object.assign(this, data);
  }
}
