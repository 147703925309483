import type { GridState } from 'ag-grid-community';
import { createContext, useContext, useMemo } from 'react';
import type { BlotterTableFilter } from '.';

/** AgGrid-native Grid storage model used with BlotterTableNativeStorageContextProps  */
export type BlotterTableNativeState = {
  [blotterId: string]: {
    /** Ag-Grid native grid state model */
    gridState?: GridState;
    /** Talos' custom Blotter Filter model */
    blotterFilter?: BlotterTableFilter;
  };
};

/** BlotterTableNativeStorageContext needs to be created here in kyoko as useBlotterTableNative is managed here
 * - Providers for the context should be created in the consuming app
 */
export interface BlotterTableNativeStorageContextProps {
  blotterStateMap: { [blotterId: string]: BlotterTableNativeState[keyof BlotterTableNativeState] | undefined };
  setGridState: (blotterID: string, gridState: GridState) => void;
  setFilterState: (blotterID: string, filterState?: BlotterTableFilter) => void;
  resetGridState: (blotterID: string) => void;
}
export const BlotterTableNativeStorageContext = createContext<BlotterTableNativeStorageContextProps | undefined>(
  undefined
);
BlotterTableNativeStorageContext.displayName = 'BlotterTableNativeStorageContext';

export function useBlotterTableNativeStorage(blotterId: string) {
  const context = useContext(BlotterTableNativeStorageContext);
  if (context === undefined) {
    throw new Error(
      'Missing BlotterTableNativeStorageContext.Provider further up in the tree. Did you forget to add it?'
    );
  }
  return useMemo(() => {
    return {
      gridState: context.blotterStateMap[blotterId]?.gridState,
      blotterFilter: context.blotterStateMap[blotterId]?.blotterFilter,
      setGridState: (gridState: GridState) => context.setGridState(blotterId, gridState),
      setFilterState: (filterState?: BlotterTableFilter) => context.setFilterState(blotterId, filterState),
      resetGridState: () => context.resetGridState(blotterId),
    };
  }, [context, blotterId]);
}
