import { useCallback, useState } from 'react';
import { useGlobalToasts } from '../../providers/GlobalToastsProvider';
import { Button, ButtonVariants } from '../Button';
import { Box, VStack } from '../Core';
import type { EntityPageRecord } from '../EntityAdminPage';
import { TextArea } from '../Form/TextArea';
import { NotificationVariants } from '../Notification';
import { DrawerContent, DrawerFooter } from './styles';

type ModifyJsonDrawerProps<TRecord extends EntityPageRecord, TDrawerRecord extends TRecord> = {
  onSave: (modifiedEntity: TDrawerRecord) => void;
  onDelete: (selectedEntity: TRecord) => void;
  allowDeleteEntity?: boolean;
  selectedEntity?: TRecord;
};

export function ModifyJsonDrawer<TRecord extends EntityPageRecord, TDrawerRecord extends TRecord>({
  selectedEntity,
  allowDeleteEntity,
  onSave,
  onDelete,
}: ModifyJsonDrawerProps<TRecord, TDrawerRecord>) {
  const { add: addToast } = useGlobalToasts();

  const [modifiedEntityString, setModifiedEntityString] = useState<string>(JSON.stringify(selectedEntity, null, 4));

  const handleDelete = useCallback(() => {
    if (window.confirm('Are you sure you want to delete this entity?')) {
      onDelete(selectedEntity!);
    }
  }, [onDelete, selectedEntity]);

  const handleOnSave = useCallback(() => {
    try {
      const modifiedEntity = JSON.parse(modifiedEntityString) as TDrawerRecord;
      onSave(modifiedEntity);
    } catch {
      addToast({
        text: 'Failed to save entity. Please check the JSON format.',
        variant: NotificationVariants.Negative,
      });
    }
  }, [addToast, modifiedEntityString, onSave]);

  const showDeleteButton = allowDeleteEntity && selectedEntity != null;

  return (
    <VStack data-testid="entity-admin-page-json-drawer" h="100%">
      <DrawerContent overflow="overlay" w="100%">
        <Box>
          <TextArea value={modifiedEntityString} rows={15} onChange={e => setModifiedEntityString(e.target.value)} />
        </Box>
      </DrawerContent>
      <DrawerFooter w="100%">
        {showDeleteButton && (
          <Button
            variant={ButtonVariants.Negative}
            onClick={handleDelete}
            data-testid="modify-json-drawer-delete-button"
          >
            Delete
          </Button>
        )}
        <Button onClick={handleOnSave} variant={ButtonVariants.Primary} data-testid="modify-json-drawer-save-button">
          Save
        </Button>
      </DrawerFooter>
    </VStack>
  );
}
