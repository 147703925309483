import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

export interface LogContext {
  extra?: Record<string, any>;
  [key: string]: any;
}

/* The function definitions below may look a little strange.
 * The reason we do things this way is to ensure that calling `logger.error` etc.
 * in DEV mode will behave exactly like calling `console.error` etc.
 * The log source in the dev tools will be the *caller* of `logger.error`, not this file.
 * This is important for debugging.
 * The "magic" that does this, is the fact that we return a bound function from the IIFE.
 */

const error = (function error(): (error: Error, context?: LogContext) => void {
  if (import.meta.env.DEV) {
    return console.error.bind(console);
  }
  return function error(error: Error, context?: LogContext): void {
    datadogRum.addError(error, context);
    datadogLogs.logger.error(error.message, context);
  };
})();

const warn = (function warn(): (message: string, context?: LogContext) => void {
  if (import.meta.env.DEV) {
    return console.warn.bind(console);
  }
  return function warn(message: string, context?: LogContext): void {
    datadogLogs.logger.warn(message, context);
  };
})();

const info = (function info(): (message: string, context?: LogContext) => void {
  if (import.meta.env.DEV) {
    return console.info.bind(console);
  }
  return function info(message: string, context?: LogContext): void {
    datadogLogs.logger.info(message, context);
  };
})();

const debug = (function debug(): (message: string, context?: LogContext) => void {
  if (import.meta.env.DEV) {
    // eslint-disable-next-line no-console
    return console.debug.bind(console);
  }
  return function debug(message: string, context?: LogContext): void {
    datadogLogs.logger.debug(message, context);
  };
})();

function trackFeatureFlag(key: string, value: any) {
  if (import.meta.env.DEV) {
    return;
  }
  datadogRum.addFeatureFlagEvaluation(key, value);
}

function trackDuration(
  name: string,
  options: { startTime: number; duration: number; context?: Record<string, unknown>; description?: string }
) {
  if (import.meta.env.DEV) {
    return;
  }
  datadogRum.addDurationVital(name, options);
}

export const logger = {
  error,
  warn,
  info,
  debug,

  trackFeatureFlag,
  trackDuration,
};
