import {
  useCallback,
  useEffect,
  useRef,
  type FocusEvent,
  type KeyboardEvent,
  type MouseEvent,
  type RefObject,
} from 'react';
import { useDynamicCallback } from '../../../../hooks';
import { Box } from '../../../Core';
import { useDropdownPopper } from '../../../Form/Dropdown';
import { DEFAULT_FILTER_BUILDER_DROPDOWN_WIDTH } from '../tokens';
import type { FilterableSelectProperty, PropertyRefs, UseFilterBuilderRefsOutput } from '../types';
import { FilterCheckboxDropdown } from './FilterCheckboxDropdown';
import { SelectionListButtons } from './SelectionListButtons';
import { usePropertyMultiSelect } from './usePropertyMultiSelect';

type PropertyMultiSelectListProps = {
  property: FilterableSelectProperty;
  selections: string[];
  onSelectionsChange: (newSelections: string[]) => void;
  onDropdownTabOut?: (event: KeyboardEvent<HTMLElement>) => void;
  onRemove: () => void;
  refs: PropertyRefs;
} & Pick<UseFilterBuilderRefsOutput, 'updateSelectionRefs'>;

export const PropertyMultiSelectList = ({
  property,
  selections,
  onSelectionsChange,
  onRemove,
  refs,
  updateSelectionRefs,
  onDropdownTabOut,
}: PropertyMultiSelectListProps) => {
  const { getOptionLabel } = property;

  const inputRef = useRef<HTMLInputElement>(null);

  const {
    referenceElement,
    setReferenceElement,
    visibleSelections,
    isSelectionDisabled,
    tailLength,
    autocompleteOutput,
    multipleSelectionOutput,
  } = usePropertyMultiSelect({
    property,
    selections,
    refs,
    updateSelectionRefs,
    onSelectionsChange,
    inputRef,
  });

  const { isOpen, openMenu, closeMenu } = autocompleteOutput;

  const dropdownPopper = useDropdownPopper({
    isOpen,
    referenceElement,
    dropdownWidth: property.dropdownOptions?.dropdownWidth ?? DEFAULT_FILTER_BUILDER_DROPDOWN_WIDTH,
    dropdownPlacement: 'bottom-start',
  });

  const updatePopperPosition = dropdownPopper.popper.update;

  useEffect(() => {
    if (selections) {
      // every time the clauses change, we update the popper position
      updatePopperPosition && updatePopperPosition();
    }
  }, [selections, updatePopperPosition]);

  const handleOpenClick = useCallback(
    (e: MouseEvent<HTMLButtonElement> | FocusEvent<HTMLButtonElement>, ref: RefObject<HTMLButtonElement>) => {
      e.preventDefault();
      setReferenceElement(ref.current);
      updatePopperPosition && updatePopperPosition();
      openMenu();
      setTimeout(() => inputRef.current?.focus(), 0);
    },
    [openMenu, updatePopperPosition, setReferenceElement]
  );

  const handleRemoveSelectionClick = useCallback(
    (e: MouseEvent<HTMLElement>, selection: string) => {
      multipleSelectionOutput.removeSelectedItem(selection);
      e.stopPropagation();
    },
    [multipleSelectionOutput]
  );

  const handleClearAll = useCallback(() => {
    onSelectionsChange([]);
  }, [onSelectionsChange]);

  const handleSelectAll = useDynamicCallback(() => {
    onSelectionsChange(property.options);
  });

  const handleDropdownTabOut = useCallback(
    (event: KeyboardEvent<HTMLElement>) => {
      if (onDropdownTabOut) {
        event.stopPropagation();
        event.preventDefault();
        closeMenu();
        onDropdownTabOut && onDropdownTabOut(event);
      }
    },
    [closeMenu, onDropdownTabOut]
  );

  return (
    <Box position="relative">
      <div>
        <SelectionListButtons
          visibleSelections={visibleSelections}
          refs={refs}
          onOpenClick={handleOpenClick}
          onRemovePropertyClick={onRemove}
          onRemoveSelectionClick={handleRemoveSelectionClick}
          isSelectionDisabled={isSelectionDisabled}
          tailLength={tailLength}
          getOptionLabel={getOptionLabel}
          required={property.required}
        />
      </div>
      <FilterCheckboxDropdown
        {...autocompleteOutput}
        {...dropdownPopper}
        data-testid="selection-dropdown"
        property={property}
        selectedItems={multipleSelectionOutput.selectedItems}
        inputRef={inputRef}
        onSelectAll={handleSelectAll}
        onClearAll={handleClearAll}
        onTabOut={handleDropdownTabOut}
        getDropdownProps={multipleSelectionOutput.getDropdownProps}
        addSelectedItem={multipleSelectionOutput.addSelectedItem}
        removeSelectedItem={multipleSelectionOutput.removeSelectedItem}
        maxHeight={property.dropdownOptions?.maxHeight}
        groupMaxHeight={property.dropdownOptions?.groupMaxHeight}
      />
    </Box>
  );
};
